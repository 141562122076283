import videojs from 'video.js';

export const checkDomain = () => {
  // original check created by creators of plugin. We don't want it, but we still have to set the true properties

  //   if (window['location']['hostname']['toLowerCase']()['indexOf']('lumex.ink') > -1) {
  //     videojs['dispose'] = true;
  //     videojs['nuevo'] = true;
  //   }

  (videojs as any)['dispose'] = true;
  (videojs as any)['nuevo'] = true;
};
