import Player from 'video.js/dist/types/player';
import { sendContentViewStatsripAnalytics } from '@src/analytics';

type playerEventHandlersData = {
  kinopoiskId: number;
};

export const playerEventHandlers = (player: Player, data: playerEventHandlersData) => {
  let flagPrerollPlayed: 0 | 1 = 0;
  let hasSentAnalytics = false;

  const handleVastEvent = (_event: Event, data: { eventName: string }) => {
    const { eventName } = data || {};
    switch (eventName) {
      case 'impression':
        flagPrerollPlayed = 1;
        player.off('vastEvent', handleVastEvent);
        break;
    }
  };

  const handleTimeUpdate = () => {
    const currentTime = player.currentTime() || 0;
    if (currentTime > 60 && !hasSentAnalytics) {
      sendContentViewStatsripAnalytics(flagPrerollPlayed, data.kinopoiskId);
      hasSentAnalytics = true;
      player.off('timeupdate', handleTimeUpdate);
    }
  };

  player.on('vastEvent', handleVastEvent);
  player.on('timeupdate', handleTimeUpdate);
};
