import { API_BASE_URL } from '@src/constants';
import { checkIsInIframe } from './check-is-in-iframe';

export const getApi = (): string => {
  // TODO: come up with a proper solution
  if (window.location.host === 'player-frontend-develop.dev.lumex.me') {
    return API_BASE_URL;
  }

  if (checkIsInIframe()) {
    return `${window.location.protocol}//${window.location.host.replace('p.', 'api.')}`;
  }

  return API_BASE_URL;
};
