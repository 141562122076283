export type CamelizeString<ObjectProperty extends string> =
  ObjectProperty extends `${infer F}_${infer R}`
    ? `${F}${Capitalize<CamelizeString<R>>}`
    : ObjectProperty;

export type Camelize<T> = {
  [ObjectProperty in keyof T as CamelizeString<
    ObjectProperty & string
  >]: T[ObjectProperty] extends Array<infer ArrayItem>
    ? ArrayItem extends Record<string, unknown>
      ? Array<Camelize<ArrayItem>>
      : T[ObjectProperty]
    : T[ObjectProperty] extends Record<string, unknown>
      ? Camelize<T[ObjectProperty]>
      : T[ObjectProperty];
};

export const toCamelCase = <T extends object>(item: T): Camelize<T> => {
  if (Array.isArray(item)) {
    return item.map((el: T) => toCamelCase(el)) as Camelize<T>;
  } else if (typeof item === 'function' || item !== Object(item)) {
    return item as Camelize<T>;
  }

  return Object.fromEntries(
    Object.entries(item).map(([key, value]) => [
      key.replace(/([-_][a-z])/gi, (c) => c.toUpperCase().replace(/[-_]/g, '')),
      toCamelCase(value),
    ]),
  ) as Camelize<T>;
};
