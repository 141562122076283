const TOKEN_STORAGE_KEY = 'meta';

export const setToken = (token: string | undefined) => {
  if (!token) {
    return;
  }

  sessionStorage.setItem(TOKEN_STORAGE_KEY, token);
};

export const getToken = () => sessionStorage.getItem(TOKEN_STORAGE_KEY) ?? '';
