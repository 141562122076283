import React, { useCallback, useEffect, useRef, useState } from 'react';
import Player from 'video.js/dist/types/player';

interface RewindProps {
  player: Player | null;
}

export const Rewind: React.FC<RewindProps> = ({ player }) => {
  const rightRewindRef = useRef<HTMLDivElement | null>(null);
  const leftRewindRef = useRef<HTMLDivElement | null>(null);
  const [secondsToRewind, setSecondsToRewind] = useState<number>(0);

  const rewind = useCallback(
    (skipValue: number) => {
      if (!player) {
        return;
      }

      const currentTime = player.currentTime();

      currentTime && player.currentTime(Math.max(0, currentTime + skipValue));
    },
    [player],
  );

  const detectDoubleTapClosure = useCallback(
    (direction: 'forward' | 'back') => {
      let lastTap = 0;
      let rewindTimeout: NodeJS.Timeout | null = null;
      let hideControlsTimeout: NodeJS.Timeout | null = null;
      let isRewinding = false;
      let accumulatedSkipValue = 0;

      const handleRewind = (rewindElement: HTMLElement | null, skipValue: number) => {
        if (!rewindElement) {
          return;
        }

        const icon = rewindElement.querySelector('.circle');
        const loader = rewindElement.querySelector('.loader');
        if (!icon || !loader) {
          return;
        }

        icon.classList.add('animation');
        loader.classList.add('show');
        rewindElement.classList.add('back');
        isRewinding = true;
        accumulatedSkipValue += skipValue;

        if (rewindTimeout) {
          clearTimeout(rewindTimeout);
        }
        rewindTimeout = setTimeout(() => {
          if (isRewinding) {
            icon.classList.remove('animation');
            loader.classList.remove('show');
            rewindElement.classList.remove('back');
            isRewinding = false;

            rewind(accumulatedSkipValue);
            accumulatedSkipValue = 0;
            setSecondsToRewind(0);
          }
        }, 800);
      };

      return (event: Event) => {
        const currentTime = new Date().getTime();
        const tapLen = currentTime - lastTap;

        hideControlsTimeout && clearTimeout(hideControlsTimeout);

        if (tapLen < 500 && tapLen > 0) {
          event.preventDefault();

          player?.el_.classList.add('vjs-user-inactive', 'vjs-touch-inactive');
          player?.el_.classList.remove('vjs-user-active', 'vjs-touch-active');

          if (direction === 'back') {
            handleRewind(leftRewindRef.current, -10);
          } else {
            handleRewind(rightRewindRef.current, 10);
          }

          setSecondsToRewind((prevSeconds) => prevSeconds + 10);
        } else {
          [
            'vjs-user-inactive',
            'vjs-user-active',
            'vjs-touch-inactive',
            'vjs-touch-active',
          ].forEach((className) => {
            player?.el_.classList.toggle(className);
          });

          hideControlsTimeout = setTimeout(() => {
            player?.el_.classList.add('vjs-user-inactive', 'vjs-touch-inactive');
            player?.el_.classList.remove('vjs-user-active', 'vjs-touch-active');
          }, 8000);
        }
        lastTap = currentTime;
      };
    },
    [rewind, player],
  );

  useEffect(() => {
    const rightElement = rightRewindRef.current;
    const leftElement = leftRewindRef.current;

    const rightListener = detectDoubleTapClosure('forward');
    const leftListener = detectDoubleTapClosure('back');

    rightElement?.addEventListener('touchend', rightListener, { passive: false });
    leftElement?.addEventListener('touchend', leftListener, { passive: false });

    return () => {
      rightElement?.removeEventListener('touchend', rightListener);
      leftElement?.removeEventListener('touchend', leftListener);
    };
  }, [detectDoubleTapClosure]);

  return (
    <>
      <div className="rewind-overlay left-rewind" ref={leftRewindRef}>
        <div className="circle"></div>
        <div className="loader">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <g clipPath="url(#clip0_439_2140)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.3999 15.9074C23.3999 16.5564 22.6149 16.9295 22.0593 16.5445L17.1422 13.1372C16.6858 12.821 16.6858 12.1794 17.1422 11.8631L22.0593 8.45588C22.6148 8.07093 23.3999 8.44385 23.3999 9.09294L23.3999 15.9074Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 15.9074C15 16.5564 14.215 16.9295 13.6594 16.5445L8.74228 13.1372C8.28592 12.821 8.2859 12.1794 8.74226 11.8631L13.6594 8.45588C14.2149 8.07093 15 8.44385 15 9.09294L15 15.9074Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.6001 15.9074C6.6001 16.5564 5.81506 16.9295 5.25946 16.5445L0.342381 13.1372C-0.11398 12.821 -0.114003 12.1794 0.342358 11.8631L5.25946 8.45588C5.81501 8.07093 6.6001 8.44385 6.6001 9.09294L6.6001 15.9074Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_439_2140">
                <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
              </clipPath>
            </defs>
          </svg>
          <span>{secondsToRewind} сек.</span>
        </div>
      </div>
      <div className="rewind-overlay right-rewind" ref={rightRewindRef}>
        <div className="circle"></div>
        <div className="loader">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            transform="rotate(180)"
          >
            <g clipPath="url(#clip0_439_2140)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.3999 15.9074C23.3999 16.5564 22.6149 16.9295 22.0593 16.5445L17.1422 13.1372C16.6858 12.821 16.6858 12.1794 17.1422 11.8631L22.0593 8.45588C22.6148 8.07093 23.3999 8.44385 23.3999 9.09294L23.3999 15.9074Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 15.9074C15 16.5564 14.215 16.9295 13.6594 16.5445L8.74228 13.1372C8.28592 12.821 8.2859 12.1794 8.74226 11.8631L13.6594 8.45588C14.2149 8.07093 15 8.44385 15 9.09294L15 15.9074Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.6001 15.9074C6.6001 16.5564 5.81506 16.9295 5.25946 16.5445L0.342381 13.1372C-0.11398 12.821 -0.114003 12.1794 0.342358 11.8631L5.25946 8.45588C5.81501 8.07093 6.6001 8.44385 6.6001 9.09294L6.6001 15.9074Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_439_2140">
                <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
              </clipPath>
            </defs>
          </svg>
          <span>{secondsToRewind} сек.</span>
        </div>
      </div>
    </>
  );
};
