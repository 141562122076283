const e = {};
function t(e, t) {
  var i = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var r = Object.getOwnPropertySymbols(e);
    if (t) {
      r = r.filter(function (t) {
        return Object.getOwnPropertyDescriptor(e, t).enumerable;
      });
    }
    i.push.apply(i, r);
  }
  return i;
}
function i(e) {
  for (var i = 1; i < arguments.length; i++) {
    var r = arguments[i] ?? {};
    if (i % 2) {
      t(Object(r), true).forEach(function (t) {
        l(e, t, r[t]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(e, Object.getOwnPropertyDescriptors(r));
    } else {
      t(Object(r)).forEach(function (t) {
        Object.defineProperty(e, t, Object.getOwnPropertyDescriptor(r, t));
      });
    }
  }
  return e;
}
function r(e) {
  return (r =
    typeof Symbol == 'function' && typeof Symbol.iterator == 'symbol'
      ? function (e) {
          return typeof e;
        }
      : function (e) {
          if (
            e &&
            typeof Symbol == 'function' &&
            e.constructor === Symbol &&
            e !== Symbol.prototype
          ) {
            return 'symbol';
          } else {
            return typeof e;
          }
        })(e);
}
function n(e, t) {
  if (!(e instanceof t)) {
    throw new TypeError('Cannot call a class as a function');
  }
}
function a(e, t) {
  for (var i = 0; i < t.length; i++) {
    var r = t[i];
    r.enumerable = r.enumerable || false;
    r.configurable = true;
    if ('value' in r) {
      r.writable = true;
    }
    Object.defineProperty(e, m(r.key), r);
  }
}
function o(e, t, i) {
  if (t) {
    a(e.prototype, t);
  }
  if (i) {
    a(e, i);
  }
  Object.defineProperty(e, 'prototype', {
    writable: false,
  });
  return e;
}
function l(e, t, i) {
  if ((t = m(t)) in e) {
    Object.defineProperty(e, t, {
      value: i,
      enumerable: true,
      configurable: true,
      writable: true,
    });
  } else {
    e[t] = i;
  }
  return e;
}
function s(e, t) {
  if (typeof t != 'function' && t !== null) {
    throw new TypeError('Super expression must either be null or a function');
  }
  e.prototype = Object.create(t && t.prototype, {
    constructor: {
      value: e,
      writable: true,
      configurable: true,
    },
  });
  Object.defineProperty(e, 'prototype', {
    writable: false,
  });
  if (t) {
    u(e, t);
  }
}
function c(e) {
  return (c = Object.setPrototypeOf
    ? Object.getPrototypeOf.bind()
    : function (e) {
        return e.__proto__ || Object.getPrototypeOf(e);
      })(e);
}
function u(e, t) {
  return (u = Object.setPrototypeOf
    ? Object.setPrototypeOf.bind()
    : function (e, t) {
        e.__proto__ = t;
        return e;
      })(e, t);
}
function d(e, t) {
  if (t && (typeof t == 'object' || typeof t == 'function')) {
    return t;
  }
  if (t !== undefined) {
    throw new TypeError('Derived constructors may only return object or undefined');
  }
  return (function (e) {
    if (e === undefined) {
      throw new ReferenceError("this hasn't been initialized - super() hasn't been called");
    }
    return e;
  })(e);
}
function p(e) {
  var t = (function () {
    if (typeof Reflect == 'undefined' || !Reflect.construct) {
      return false;
    }
    if (Reflect.construct.sham) {
      return false;
    }
    if (typeof Proxy == 'function') {
      return true;
    }
    try {
      Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {}));
      return true;
    } catch (e) {
      return false;
    }
  })();
  return function () {
    var i;
    var r = c(e);
    if (t) {
      var n = c(this).constructor;
      i = Reflect.construct(r, arguments, n);
    } else {
      i = r.apply(this, arguments);
    }
    return d(this, i);
  };
}
function f(e) {
  return (
    (function (e) {
      if (Array.isArray(e)) {
        return h(e);
      }
    })(e) ||
    (function (e) {
      if ((typeof Symbol != 'undefined' && e[Symbol.iterator] != null) || e['@@iterator'] != null) {
        return Array.from(e);
      }
    })(e) ||
    (function (e, t) {
      if (e) {
        if (typeof e == 'string') {
          return h(e, t);
        }
        var i = Object.prototype.toString.call(e).slice(8, -1);
        if (i === 'Object' && e.constructor) {
          i = e.constructor.name;
        }
        if (i === 'Map' || i === 'Set') {
          return Array.from(e);
        }
        if (i === 'Arguments' || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(i)) {
          return h(e, t);
        } else {
          return;
        }
      }
    })(e) ||
    (function () {
      throw new TypeError(
        'Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.',
      );
    })()
  );
}
function h(e, t) {
  if (t == null || t > e.length) {
    t = e.length;
  }
  for (var i = 0, r = new Array(t); i < t; i++) {
    r[i] = e[i];
  }
  return r;
}
function m(e) {
  var t = (function (e, t) {
    if (typeof e != 'object' || e === null) {
      return e;
    }
    var i = e[Symbol.toPrimitive];
    if (i !== undefined) {
      var r = i.call(e, t || 'default');
      if (typeof r != 'object') {
        return r;
      }
      throw new TypeError('@@toPrimitive must return a primitive value.');
    }
    return (t === 'string' ? String : Number)(e);
  })(e, 'string');
  if (typeof t == 'symbol') {
    return t;
  } else {
    return String(t);
  }
}
function v(e = {}) {
  return {
    id: e.id || null,
    adId: e.adId || null,
    sequence: e.sequence || null,
    apiFramework: e.apiFramework || null,
    universalAdIds: [],
    creativeExtensions: [],
  };
}
var g = [
  'ADCATEGORIES',
  'ADCOUNT',
  'ADPLAYHEAD',
  'ADSERVINGID',
  'ADTYPE',
  'APIFRAMEWORKS',
  'APPBUNDLE',
  'ASSETURI',
  'BLOCKEDADCATEGORIES',
  'BREAKMAXADLENGTH',
  'BREAKMAXADS',
  'BREAKMAXDURATION',
  'BREAKMINADLENGTH',
  'BREAKMINDURATION',
  'BREAKPOSITION',
  'CLICKPOS',
  'CLICKTYPE',
  'CLIENTUA',
  'CONTENTID',
  'CONTENTPLAYHEAD',
  'CONTENTURI',
  'DEVICEIP',
  'DEVICEUA',
  'DOMAIN',
  'EXTENSIONS',
  'GDPRCONSENT',
  'IFA',
  'IFATYPE',
  'INVENTORYSTATE',
  'LATLONG',
  'LIMITADTRACKING',
  'MEDIAMIME',
  'MEDIAPLAYHEAD',
  'OMIDPARTNER',
  'PAGEURL',
  'PLACEMENTTYPE',
  'PLAYERCAPABILITIES',
  'PLAYERSIZE',
  'PLAYERSTATE',
  'PODSEQUENCE',
  'REGULATIONS',
  'SERVERSIDE',
  'SERVERUA',
  'TRANSACTIONID',
  'UNIVERSALADID',
  'VASTVERSIONS',
  'VERIFICATIONVENDORS',
];
function y(e, t = {}, i = {}) {
  var r = [];
  var n = b(e);
  if (!!t.ERRORCODE && !i.isCustomCode && !/^[0-9]{3}$/.test(t.ERRORCODE)) {
    t.ERRORCODE = 900;
  }
  t.CACHEBUSTING = C(Math.round(Math.random() * 100000000));
  t.TIMESTAMP = new Date().toISOString();
  t.RANDOM = t.random = t.CACHEBUSTING;
  for (var a in t) {
    t[a] = L(t[a]);
  }
  for (var o in n) {
    var l = n[o];
    if (typeof l == 'string') {
      r.push(k(l, t));
    }
  }
  return r;
}
function k(e, t) {
  var i = (e = T(e, t)).match(/[^[\]]+(?=])/g);
  if (!i) {
    return e;
  }
  var r = i.filter(function (e) {
    return g.indexOf(e) > -1;
  });
  if (r.length === 0) {
    return e;
  } else {
    return T(
      e,
      (r = r.reduce(function (e, t) {
        e[t] = -1;
        return e;
      }, {})),
    );
  }
}
function T(e, t) {
  var i = e;
  for (var r in t) {
    var n = t[r];
    i = i.replace(new RegExp(`(?:\\[|%%)(${r})(?:\\]|%%)`, 'g'), n);
  }
  return i;
}
function b(e) {
  if (Array.isArray(e)) {
    return e.map(function (e) {
      if (e && e.hasOwnProperty('url')) {
        return e.url;
      } else {
        return e;
      }
    });
  } else {
    return e;
  }
}
function A(e) {
  return /^(https?:\/\/|\/\/)/.test(e);
}
function R(e, t) {
  for (var i = 0; i < t.length; i++) {
    if (w(t[i], e)) {
      return true;
    }
  }
  return false;
}
function w(e, t) {
  if (e && t) {
    var i = Object.getOwnPropertyNames(e);
    var r = Object.getOwnPropertyNames(t);
    return i.length === r.length && e.id === t.id && e.url === t.url;
  }
  return false;
}
function L(e) {
  return encodeURIComponent(e).replace(/[!'()*]/g, function (e) {
    return `%${e.charCodeAt(0).toString(16)}`;
  });
}
function C(e, t = 8) {
  return e.toString().padStart(t, '0');
}
var x = {
  track: function (e, t, i) {
    y(e, t, i).forEach(function (e) {
      if (typeof window != 'undefined' && window !== null) {
        new Image().src = e;
      }
    });
  },
  resolveURLTemplates: y,
  extractURLsFromTemplates: b,
  filterValidUrlTemplates: function (e) {
    if (Array.isArray(e)) {
      return e.filter(function (e) {
        return A(e.hasOwnProperty('url') ? e.url : e);
      });
    } else {
      return A(e);
    }
  },
  containsTemplateObject: R,
  isTemplateObjectEqual: w,
  encodeURIComponentRFC3986: L,
  replaceUrlMacros: k,
  isNumeric: function (e) {
    return !isNaN(parseFloat(e)) && isFinite(e);
  },
  flatten: function e(t) {
    return t.reduce(function (t, i) {
      return t.concat(Array.isArray(i) ? e(i) : i);
    }, []);
  },
  joinArrayOfUniqueTemplateObjs: function (e = [], t = []) {
    var i = Array.isArray(e) ? e : [];
    var r = Array.isArray(t) ? t : [];
    return i.concat(r).reduce(function (e, t) {
      if (!R(t, e)) {
        e.push(t);
      }
      return e;
    }, []);
  },
  isValidTimeValue: function (e) {
    return Number.isFinite(e) && e >= -2;
  },
  addLeadingZeros: C,
};
function E(e) {
  return ['true', 'TRUE', 'True', '1'].indexOf(e) !== -1;
}
function I(e) {
  if (e == null) {
    return -1;
  }
  if (x.isNumeric(e)) {
    return parseInt(e);
  }
  var t = e.split(':');
  if (t.length !== 3) {
    return -1;
  }
  var i = t[2].split('.');
  var r = parseInt(i[0]);
  if (i.length === 2) {
    r += parseFloat(`0.${i[1]}`);
  }
  var n = parseInt(t[1] * 60);
  var a = parseInt(t[0] * 60 * 60);
  if (isNaN(a) || isNaN(n) || isNaN(r) || n > 3600 || r > 60) {
    return -1;
  } else {
    return a + n + r;
  }
}
var U = {
  childByName: function (e, t) {
    var i = e.childNodes;
    for (var r in i) {
      var n = i[r];
      if (n.nodeName === t) {
        return n;
      }
    }
  },
  childrenByName: function (e, t) {
    var i = [];
    var r = e.childNodes;
    for (var n in r) {
      var a = r[n];
      if (a.nodeName === t) {
        i.push(a);
      }
    }
    return i;
  },
  resolveVastAdTagURI: function (e, t) {
    if (!t) {
      return e;
    }
    if (e.indexOf('//') === 0) {
      var i = location.protocol;
      return `${i}${e}`;
    }
    if (e.indexOf('://') === -1) {
      var r = t.slice(0, t.lastIndexOf('/'));
      return `${r}/${e}`;
    }
    return e;
  },
  parseBoolean: E,
  parseNodeText: function (e) {
    return e && (e.textContent || e.text || '').trim();
  },
  copyNodeAttribute: function (e, t, i) {
    var r = t.getAttribute(e);
    if (r) {
      i.setAttribute(e, r);
    }
  },
  parseAttributes: function (e) {
    for (var t = e.attributes, i = {}, r = 0; r < t.length; r++) {
      i[t[r].nodeName] = t[r].nodeValue;
    }
    return i;
  },
  parseDuration: I,
  splitVAST: function (e) {
    var t = [];
    var i = null;
    e.forEach(function (r, n) {
      r.sequence &&= parseInt(r.sequence, 10);
      if (r.sequence > 1) {
        var a = e[n - 1];
        if (a && a.sequence === r.sequence - 1) {
          if (i) {
            i.push(r);
          }
          return;
        }
        delete r.sequence;
      }
      i = [r];
      t.push(i);
    });
    return t;
  },
  assignAttributes: function (e, t) {
    if (e) {
      for (var i in e) {
        var r = e[i];
        if (r.nodeName && r.nodeValue && t.hasOwnProperty(r.nodeName)) {
          var n = r.nodeValue;
          if (typeof t[r.nodeName] == 'boolean') {
            n = E(n);
          }
          t[r.nodeName] = n;
        }
      }
    }
  },
  mergeWrapperAdData: function (e, t) {
    var i;
    e.errorURLTemplates = t.errorURLTemplates.concat(e.errorURLTemplates);
    e.impressionURLTemplates = t.impressionURLTemplates.concat(e.impressionURLTemplates);
    e.extensions = t.extensions.concat(e.extensions);
    if (t.viewableImpression.length > 0) {
      e.viewableImpression = [].concat(f(e.viewableImpression), f(t.viewableImpression));
    }
    e.followAdditionalWrappers = t.followAdditionalWrappers;
    e.allowMultipleAds = t.allowMultipleAds;
    e.fallbackOnNoAd = t.fallbackOnNoAd;
    var r = (t.creatives || []).filter(function (e) {
      return e && e.type === 'companion';
    });
    var n = r.reduce(function (e, t) {
      (t.variations || []).forEach(function (t) {
        (t.companionClickTrackingURLTemplates || []).forEach(function (t) {
          if (!x.containsTemplateObject(t, e)) {
            e.push(t);
          }
        });
      });
      return e;
    }, []);
    e.creatives = r.concat(e.creatives);
    var a = t.videoClickTrackingURLTemplates && t.videoClickTrackingURLTemplates.length;
    var o = t.videoCustomClickURLTemplates && t.videoCustomClickURLTemplates.length;
    e.creatives.forEach(function (e) {
      if (t.trackingEvents && t.trackingEvents[e.type]) {
        for (var i in t.trackingEvents[e.type]) {
          var r = t.trackingEvents[e.type][i];
          if (!Array.isArray(e.trackingEvents[i])) {
            e.trackingEvents[i] = [];
          }
          e.trackingEvents[i] = e.trackingEvents[i].concat(r);
        }
      }
      if (e.type === 'linear') {
        if (a) {
          e.videoClickTrackingURLTemplates = e.videoClickTrackingURLTemplates.concat(
            t.videoClickTrackingURLTemplates,
          );
        }
        if (o) {
          e.videoCustomClickURLTemplates = e.videoCustomClickURLTemplates.concat(
            t.videoCustomClickURLTemplates,
          );
        }
        if (
          !!t.videoClickThroughURLTemplate &&
          (e.videoClickThroughURLTemplate === null || e.videoClickThroughURLTemplate === undefined)
        ) {
          e.videoClickThroughURLTemplate = t.videoClickThroughURLTemplate;
        }
      }
      if (e.type === 'companion' && n.length) {
        (e.variations || []).forEach(function (e) {
          e.companionClickTrackingURLTemplates = x.joinArrayOfUniqueTemplateObjs(
            e.companionClickTrackingURLTemplates,
            n,
          );
        });
      }
    });
    if (t.adVerifications) {
      e.adVerifications = e.adVerifications.concat(t.adVerifications);
    }
    if (t.blockedAdCategories) {
      e.blockedAdCategories = e.blockedAdCategories.concat(t.blockedAdCategories);
    }
    if ((i = t.creatives) !== null && i !== undefined && i.length) {
      var l = t.creatives.filter(function (e) {
        return e.icons?.length && !e.mediaFiles.length;
      });
      if (l.length) {
        e.creatives = e.creatives.concat(l);
      }
    }
  },
};
function N(e, t) {
  var i = (function () {
    var e = v(arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {});
    return {
      id: e.id,
      adId: e.adId,
      sequence: e.sequence,
      apiFramework: e.apiFramework,
      type: 'companion',
      required: null,
      variations: [],
    };
  })(t);
  i.required = e.getAttribute('required') || null;
  i.variations = U.childrenByName(e, 'Companion').map(function (e) {
    var t = (function (e = {}) {
      return {
        id: e.id || null,
        adType: 'companionAd',
        width: e.width || 0,
        height: e.height || 0,
        assetWidth: e.assetWidth || null,
        assetHeight: e.assetHeight || null,
        expandedWidth: e.expandedWidth || null,
        expandedHeight: e.expandedHeight || null,
        apiFramework: e.apiFramework || null,
        adSlotId: e.adSlotId || null,
        pxratio: e.pxratio || '1',
        renderingMode: e.renderingMode || 'default',
        staticResources: [],
        htmlResources: [],
        iframeResources: [],
        adParameters: null,
        altText: null,
        companionClickThroughURLTemplate: null,
        companionClickTrackingURLTemplates: [],
        trackingEvents: {},
      };
    })(U.parseAttributes(e));
    t.htmlResources = U.childrenByName(e, 'HTMLResource').reduce(function (e, t) {
      var i = U.parseNodeText(t);
      if (i) {
        return e.concat(i);
      } else {
        return e;
      }
    }, []);
    t.iframeResources = U.childrenByName(e, 'IFrameResource').reduce(function (e, t) {
      var i = U.parseNodeText(t);
      if (i) {
        return e.concat(i);
      } else {
        return e;
      }
    }, []);
    t.staticResources = U.childrenByName(e, 'StaticResource').reduce(function (e, t) {
      var i = U.parseNodeText(t);
      if (i) {
        return e.concat({
          url: i,
          creativeType: t.getAttribute('creativeType') || null,
        });
      } else {
        return e;
      }
    }, []);
    t.altText = U.parseNodeText(U.childByName(e, 'AltText')) || null;
    var i = U.childByName(e, 'TrackingEvents');
    if (i) {
      U.childrenByName(i, 'Tracking').forEach(function (e) {
        var i = e.getAttribute('event');
        var r = U.parseNodeText(e);
        if (i && r) {
          if (!Array.isArray(t.trackingEvents[i])) {
            t.trackingEvents[i] = [];
          }
          t.trackingEvents[i].push(r);
        }
      });
    }
    t.companionClickTrackingURLTemplates = U.childrenByName(e, 'CompanionClickTracking').map(
      function (e) {
        return {
          id: e.getAttribute('id') || null,
          url: U.parseNodeText(e),
        };
      },
    );
    t.companionClickThroughURLTemplate =
      U.parseNodeText(U.childByName(e, 'CompanionClickThrough')) || null;
    var r = U.childByName(e, 'AdParameters');
    if (r) {
      t.adParameters = {
        value: U.parseNodeText(r),
        xmlEncoded: r.getAttribute('xmlEncoded') || null,
      };
    }
    return t;
  });
  return i;
}
function S(e, t) {
  var i;
  var r = (function () {
    var e = v(arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {});
    return {
      id: e.id,
      adId: e.adId,
      sequence: e.sequence,
      apiFramework: e.apiFramework,
      type: 'linear',
      duration: 0,
      skipDelay: null,
      mediaFiles: [],
      mezzanine: null,
      interactiveCreativeFile: null,
      closedCaptionFiles: [],
      videoClickThroughURLTemplate: null,
      videoClickTrackingURLTemplates: [],
      videoCustomClickURLTemplates: [],
      adParameters: null,
      icons: [],
      trackingEvents: {},
    };
  })(t);
  r.duration = U.parseDuration(U.parseNodeText(U.childByName(e, 'Duration')));
  var n = e.getAttribute('skipoffset');
  if (n == null) {
    r.skipDelay = null;
  } else if (n.charAt(n.length - 1) === '%' && r.duration !== -1) {
    var a = parseInt(n, 10);
    r.skipDelay = r.duration * (a / 100);
  } else {
    r.skipDelay = U.parseDuration(n);
  }
  var o = U.childByName(e, 'VideoClicks');
  if (o) {
    var l = U.childByName(o, 'ClickThrough');
    r.videoClickThroughURLTemplate = l
      ? {
          id: l.getAttribute('id') || null,
          url: U.parseNodeText(l),
        }
      : null;
    U.childrenByName(o, 'ClickTracking').forEach(function (e) {
      r.videoClickTrackingURLTemplates.push({
        id: e.getAttribute('id') || null,
        url: U.parseNodeText(e),
      });
    });
    U.childrenByName(o, 'CustomClick').forEach(function (e) {
      r.videoCustomClickURLTemplates.push({
        id: e.getAttribute('id') || null,
        url: U.parseNodeText(e),
      });
    });
  }
  var s = U.childByName(e, 'AdParameters');
  if (s) {
    r.adParameters = {
      value: U.parseNodeText(s),
      xmlEncoded: s.getAttribute('xmlEncoded') || null,
    };
  }
  U.childrenByName(e, 'TrackingEvents').forEach(function (e) {
    U.childrenByName(e, 'Tracking').forEach(function (e) {
      var t = e.getAttribute('event');
      var n = U.parseNodeText(e);
      if (t && n) {
        if (t === 'progress') {
          if (!(i = e.getAttribute('offset'))) {
            return;
          }
          t =
            i.charAt(i.length - 1) === '%'
              ? `progress-${i}`
              : `progress-${Math.round(U.parseDuration(i))}`;
        }
        if (!Array.isArray(r.trackingEvents[t])) {
          r.trackingEvents[t] = [];
        }
        r.trackingEvents[t].push(n);
      }
    });
  });
  U.childrenByName(e, 'MediaFiles').forEach(function (e) {
    U.childrenByName(e, 'MediaFile').forEach(function (e) {
      r.mediaFiles.push(
        (function (e) {
          var t = {
            id: null,
            fileURL: null,
            fileSize: 0,
            deliveryType: 'progressive',
            mimeType: null,
            mediaType: null,
            codec: null,
            bitrate: 0,
            minBitrate: 0,
            maxBitrate: 0,
            width: 0,
            height: 0,
            apiFramework: null,
            scalable: null,
            maintainAspectRatio: null,
          };
          t.id = e.getAttribute('id');
          t.fileURL = U.parseNodeText(e);
          t.deliveryType = e.getAttribute('delivery');
          t.codec = e.getAttribute('codec');
          t.mimeType = e.getAttribute('type');
          t.mediaType = e.getAttribute('mediaType') || '2D';
          t.apiFramework = e.getAttribute('apiFramework');
          t.fileSize = parseInt(e.getAttribute('fileSize') || 0);
          t.bitrate = parseInt(e.getAttribute('bitrate') || 0);
          t.minBitrate = parseInt(e.getAttribute('minBitrate') || 0);
          t.maxBitrate = parseInt(e.getAttribute('maxBitrate') || 0);
          t.width = parseInt(e.getAttribute('width') || 0);
          t.height = parseInt(e.getAttribute('height') || 0);
          var i = e.getAttribute('scalable');
          if (i && typeof i == 'string') {
            t.scalable = U.parseBoolean(i);
          }
          var r = e.getAttribute('maintainAspectRatio');
          if (r && typeof r == 'string') {
            t.maintainAspectRatio = U.parseBoolean(r);
          }
          return t;
        })(e),
      );
    });
    var t = U.childByName(e, 'InteractiveCreativeFile');
    if (t) {
      r.interactiveCreativeFile = (function (e) {
        var t = (function (e = {}) {
          return {
            type: e.type || null,
            apiFramework: e.apiFramework || null,
            variableDuration: U.parseBoolean(e.variableDuration),
            fileURL: null,
          };
        })(U.parseAttributes(e));
        t.fileURL = U.parseNodeText(e);
        return t;
      })(t);
    }
    var i = U.childByName(e, 'ClosedCaptionFiles');
    if (i) {
      U.childrenByName(i, 'ClosedCaptionFile').forEach(function (e) {
        var t = (function (e = {}) {
          return {
            type: e.type || null,
            language: e.language || null,
            fileURL: null,
          };
        })(U.parseAttributes(e));
        t.fileURL = U.parseNodeText(e);
        r.closedCaptionFiles.push(t);
      });
    }
    var n;
    var a;
    var o;
    var l = U.childByName(e, 'Mezzanine');
    n = l;
    a = {};
    o = false;
    ['delivery', 'type', 'width', 'height'].forEach(function (e) {
      if (n && n.getAttribute(e)) {
        a[e] = n.getAttribute(e);
      } else {
        o = true;
      }
    });
    var s = o ? null : a;
    if (s) {
      var c = {
        id: null,
        fileURL: null,
        delivery: null,
        codec: null,
        type: null,
        width: 0,
        height: 0,
        fileSize: 0,
        mediaType: '2D',
      };
      c.id = l.getAttribute('id');
      c.fileURL = U.parseNodeText(l);
      c.delivery = s.delivery;
      c.codec = l.getAttribute('codec');
      c.type = s.type;
      c.width = parseInt(s.width, 10);
      c.height = parseInt(s.height, 10);
      c.fileSize = parseInt(l.getAttribute('fileSize'), 10);
      c.mediaType = l.getAttribute('mediaType') || '2D';
      r.mezzanine = c;
    }
  });
  var c = U.childByName(e, 'Icons');
  if (c) {
    U.childrenByName(c, 'Icon').forEach(function (e) {
      r.icons.push(
        (function (e) {
          var t = {
            program: null,
            height: 0,
            width: 0,
            xPosition: 0,
            yPosition: 0,
            apiFramework: null,
            offset: null,
            duration: 0,
            type: null,
            staticResource: null,
            htmlResource: null,
            iframeResource: null,
            pxratio: '1',
            iconClickThroughURLTemplate: null,
            iconClickTrackingURLTemplates: [],
            iconViewTrackingURLTemplate: null,
            iconClickFallbackImages: [],
          };
          t.program = e.getAttribute('program');
          t.height = parseInt(e.getAttribute('height') || 0);
          t.width = parseInt(e.getAttribute('width') || 0);
          t.xPosition = (function (e) {
            if (['left', 'right'].indexOf(e) !== -1) {
              return e;
            } else {
              return parseInt(e || 0);
            }
          })(e.getAttribute('xPosition'));
          t.yPosition = (function (e) {
            if (['top', 'bottom'].indexOf(e) !== -1) {
              return e;
            } else {
              return parseInt(e || 0);
            }
          })(e.getAttribute('yPosition'));
          t.apiFramework = e.getAttribute('apiFramework');
          t.pxratio = e.getAttribute('pxratio') || '1';
          t.offset = U.parseDuration(e.getAttribute('offset'));
          t.duration = U.parseDuration(e.getAttribute('duration'));
          U.childrenByName(e, 'HTMLResource').forEach(function (e) {
            t.type = e.getAttribute('creativeType') || 'text/html';
            t.htmlResource = U.parseNodeText(e);
          });
          U.childrenByName(e, 'IFrameResource').forEach(function (e) {
            t.type = e.getAttribute('creativeType') || 0;
            t.iframeResource = U.parseNodeText(e);
          });
          U.childrenByName(e, 'StaticResource').forEach(function (e) {
            t.type = e.getAttribute('creativeType') || 0;
            t.staticResource = U.parseNodeText(e);
          });
          var i = U.childByName(e, 'IconClicks');
          if (i) {
            t.iconClickThroughURLTemplate = U.parseNodeText(U.childByName(i, 'IconClickThrough'));
            U.childrenByName(i, 'IconClickTracking').forEach(function (e) {
              t.iconClickTrackingURLTemplates.push({
                id: e.getAttribute('id') || null,
                url: U.parseNodeText(e),
              });
            });
            var r = U.childByName(i, 'IconClickFallbackImages');
            if (r) {
              U.childrenByName(r, 'IconClickFallbackImage').forEach(function (e) {
                t.iconClickFallbackImages.push({
                  url: U.parseNodeText(e) || null,
                  width: e.getAttribute('width') || null,
                  height: e.getAttribute('height') || null,
                });
              });
            }
          }
          t.iconViewTrackingURLTemplate = U.parseNodeText(U.childByName(e, 'IconViewTracking'));
          return t;
        })(e),
      );
    });
  }
  return r;
}
function M(e, t) {
  var i = (function () {
    var e = v(arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {});
    return {
      id: e.id,
      adId: e.adId,
      sequence: e.sequence,
      apiFramework: e.apiFramework,
      type: 'nonlinear',
      variations: [],
      trackingEvents: {},
    };
  })(t);
  U.childrenByName(e, 'TrackingEvents').forEach(function (e) {
    var t;
    var r;
    U.childrenByName(e, 'Tracking').forEach(function (e) {
      t = e.getAttribute('event');
      r = U.parseNodeText(e);
      if (t && r) {
        if (!Array.isArray(i.trackingEvents[t])) {
          i.trackingEvents[t] = [];
        }
        i.trackingEvents[t].push(r);
      }
    });
  });
  U.childrenByName(e, 'NonLinear').forEach(function (e) {
    var t = {
      id: null,
      width: 0,
      height: 0,
      expandedWidth: 0,
      expandedHeight: 0,
      scalable: true,
      maintainAspectRatio: true,
      minSuggestedDuration: 0,
      apiFramework: 'static',
      adType: 'nonLinearAd',
      type: null,
      staticResource: null,
      htmlResource: null,
      iframeResource: null,
      nonlinearClickThroughURLTemplate: null,
      nonlinearClickTrackingURLTemplates: [],
      adParameters: null,
    };
    t.id = e.getAttribute('id') || null;
    t.width = e.getAttribute('width');
    t.height = e.getAttribute('height');
    t.expandedWidth = e.getAttribute('expandedWidth');
    t.expandedHeight = e.getAttribute('expandedHeight');
    t.scalable = U.parseBoolean(e.getAttribute('scalable'));
    t.maintainAspectRatio = U.parseBoolean(e.getAttribute('maintainAspectRatio'));
    t.minSuggestedDuration = U.parseDuration(e.getAttribute('minSuggestedDuration'));
    t.apiFramework = e.getAttribute('apiFramework');
    U.childrenByName(e, 'HTMLResource').forEach(function (e) {
      t.type = e.getAttribute('creativeType') || 'text/html';
      t.htmlResource = U.parseNodeText(e);
    });
    U.childrenByName(e, 'IFrameResource').forEach(function (e) {
      t.type = e.getAttribute('creativeType') || 0;
      t.iframeResource = U.parseNodeText(e);
    });
    U.childrenByName(e, 'StaticResource').forEach(function (e) {
      t.type = e.getAttribute('creativeType') || 0;
      t.staticResource = U.parseNodeText(e);
    });
    var r = U.childByName(e, 'AdParameters');
    if (r) {
      t.adParameters = {
        value: U.parseNodeText(r),
        xmlEncoded: r.getAttribute('xmlEncoded') || null,
      };
    }
    t.nonlinearClickThroughURLTemplate = U.parseNodeText(U.childByName(e, 'NonLinearClickThrough'));
    U.childrenByName(e, 'NonLinearClickTracking').forEach(function (e) {
      t.nonlinearClickTrackingURLTemplates.push({
        id: e.getAttribute('id') || null,
        url: U.parseNodeText(e),
      });
    });
    i.variations.push(t);
  });
  return i;
}
function O(e) {
  var t = [];
  e.forEach(function (e) {
    var i = P(e);
    if (i) {
      t.push(i);
    }
  });
  return t;
}
function P(e) {
  if (e.nodeName === '#comment') {
    return null;
  }
  var t;
  var i = {
    name: null,
    value: null,
    attributes: {},
    children: [],
  };
  var r = e.attributes;
  var n = e.childNodes;
  i.name = e.nodeName;
  if (e.attributes) {
    for (var a in r) {
      if (r.hasOwnProperty(a)) {
        var o = r[a];
        if (o.nodeName && o.nodeValue) {
          i.attributes[o.nodeName] = o.nodeValue;
        }
      }
    }
  }
  for (var l in n) {
    if (n.hasOwnProperty(l)) {
      var s = P(n[l]);
      if (s) {
        i.children.push(s);
      }
    }
  }
  if (
    i.children.length === 0 ||
    (i.children.length === 1 && ['#cdata-section', '#text'].indexOf(i.children[0].name) >= 0)
  ) {
    var c = U.parseNodeText(e);
    if (c !== '') {
      i.value = c;
    }
    i.children = [];
  }
  if ((t = i).value === null && Object.keys(t.attributes).length === 0 && t.children.length === 0) {
    return null;
  } else {
    return i;
  }
}
function D(e) {
  var t = [];
  e.forEach(function (e) {
    var i;
    var r = {
      id: e.getAttribute('id') || null,
      adId: B(e),
      sequence: e.getAttribute('sequence') || null,
      apiFramework: e.getAttribute('apiFramework') || null,
    };
    var n = [];
    U.childrenByName(e, 'UniversalAdId').forEach(function (e) {
      var t = {
        idRegistry: e.getAttribute('idRegistry') || 'unknown',
        value: U.parseNodeText(e),
      };
      n.push(t);
    });
    var a = U.childByName(e, 'CreativeExtensions');
    if (a) {
      i = O(U.childrenByName(a, 'CreativeExtension'));
    }
    for (var o in e.childNodes) {
      var l = e.childNodes[o];
      var s = undefined;
      switch (l.nodeName) {
        case 'Linear':
          s = S(l, r);
          break;
        case 'NonLinearAds':
          s = M(l, r);
          break;
        case 'CompanionAds':
          s = N(l, r);
      }
      if (s) {
        if (n) {
          s.universalAdIds = n;
        }
        if (i) {
          s.creativeExtensions = i;
        }
        t.push(s);
      }
    }
  });
  return t;
}
function B(e) {
  return e.getAttribute('AdID') || e.getAttribute('adID') || e.getAttribute('adId') || null;
}
var V = {
  Wrapper: {
    subElements: ['VASTAdTagURI', 'Impression'],
  },
  BlockedAdCategories: {
    attributes: ['authority'],
  },
  InLine: {
    subElements: ['AdSystem', 'AdTitle', 'Impression', 'AdServingId', 'Creatives'],
  },
  Category: {
    attributes: ['authority'],
  },
  Pricing: {
    attributes: ['model', 'currency'],
  },
  Verification: {
    oneOfinLineResources: ['JavaScriptResource', 'ExecutableResource'],
    attributes: ['vendor'],
  },
  UniversalAdId: {
    attributes: ['idRegistry'],
  },
  JavaScriptResource: {
    attributes: ['apiFramework', 'browserOptional'],
  },
  ExecutableResource: {
    attributes: ['apiFramework', 'type'],
  },
  Tracking: {
    attributes: ['event'],
  },
  Creatives: {
    subElements: ['Creative'],
  },
  Creative: {
    subElements: ['UniversalAdId'],
  },
  Linear: {
    subElements: ['MediaFiles', 'Duration'],
  },
  MediaFiles: {
    subElements: ['MediaFile'],
  },
  MediaFile: {
    attributes: ['delivery', 'type', 'width', 'height'],
  },
  Mezzanine: {
    attributes: ['delivery', 'type', 'width', 'height'],
  },
  NonLinear: {
    oneOfinLineResources: ['StaticResource', 'IFrameResource', 'HTMLResource'],
    attributes: ['width', 'height'],
  },
  Companion: {
    oneOfinLineResources: ['StaticResource', 'IFrameResource', 'HTMLResource'],
    attributes: ['width', 'height'],
  },
  StaticResource: {
    attributes: ['creativeType'],
  },
  Icons: {
    subElements: ['Icon'],
  },
  Icon: {
    oneOfinLineResources: ['StaticResource', 'IFrameResource', 'HTMLResource'],
  },
};
function j(e, t) {
  if (V[e.nodeName] && V[e.nodeName].attributes) {
    var i = V[e.nodeName].attributes.filter(function (t) {
      return !e.getAttribute(t);
    });
    if (i.length > 0) {
      q(
        {
          name: e.nodeName,
          parentName: e.parentNode.nodeName,
          attributes: i,
        },
        t,
      );
    }
  }
}
function F(e, t, i) {
  var r = V[e.nodeName];
  var n = !i && e.nodeName !== 'Wrapper';
  if (r && !n) {
    if (r.subElements) {
      var a = r.subElements.filter(function (t) {
        return !U.childByName(e, t);
      });
      if (a.length > 0) {
        q(
          {
            name: e.nodeName,
            parentName: e.parentNode.nodeName,
            subElements: a,
          },
          t,
        );
      }
    }
    if (i && r.oneOfinLineResources) {
      if (
        !r.oneOfinLineResources.some(function (t) {
          return U.childByName(e, t);
        })
      ) {
        q(
          {
            name: e.nodeName,
            parentName: e.parentNode.nodeName,
            oneOfResources: r.oneOfinLineResources,
          },
          t,
        );
      }
    }
  }
}
function H(e) {
  return e.children && e.children.length !== 0;
}
function q(e, t) {
  var i = e.name;
  var r = e.parentName;
  var n = e.attributes;
  var a = e.subElements;
  var o = e.oneOfResources;
  var l = `Element '${i}'`;
  t('VAST-warning', {
    message: (l += n
      ? ` missing required attribute(s) '${n.join(', ')}' `
      : a
        ? ` missing required sub element(s) '${a.join(', ')}' `
        : o
          ? ` must provide one of the following '${o.join(', ')}' `
          : ' is empty'),
    parentElement: r,
    specVersion: 4.1,
  });
}
var z = {
  verifyRequiredValues: function e(t, i, r) {
    if (t && t.nodeName) {
      if (t.nodeName === 'InLine') {
        r = true;
      }
      j(t, i);
      if (H(t)) {
        F(t, i, r);
        for (var n = 0; n < t.children.length; n++) {
          e(t.children[n], i, r);
        }
      } else if (U.parseNodeText(t).length === 0) {
        q(
          {
            name: t.nodeName,
            parentName: t.parentNode.nodeName,
          },
          i,
        );
      }
    }
  },
  hasSubElements: H,
  emitMissingValueWarning: q,
  verifyRequiredAttributes: j,
  verifyRequiredSubElements: F,
};
function W(e, t, i = {}) {
  var r = i.allowMultipleAds;
  var n = i.followAdditionalWrappers;
  var a = e.childNodes;
  for (var o in a) {
    var l = a[o];
    if (
      ['Wrapper', 'InLine'].indexOf(l.nodeName) !== -1 &&
      (l.nodeName !== 'Wrapper' || n !== false)
    ) {
      U.copyNodeAttribute('id', e, l);
      U.copyNodeAttribute('sequence', e, l);
      U.copyNodeAttribute('adType', e, l);
      if (l.nodeName === 'Wrapper') {
        return {
          ad: G(l, t),
          type: 'WRAPPER',
        };
      }
      if (l.nodeName === 'InLine') {
        return {
          ad: _(l, t, {
            allowMultipleAds: r,
          }),
          type: 'INLINE',
        };
      }
    }
  }
}
function _(e, t) {
  if (
    (arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {}).allowMultipleAds ===
      false &&
    e.getAttribute('sequence')
  ) {
    return null;
  } else {
    return X(e, t);
  }
}
function X(e, t) {
  var i = [];
  if (t) {
    z.verifyRequiredValues(e, t);
  }
  var r;
  var n;
  var a = e.childNodes;
  var o = (function (e = {}) {
    return {
      id: e.id || null,
      sequence: e.sequence || null,
      adType: e.adType || null,
      adServingId: null,
      categories: [],
      expires: null,
      viewableImpression: [],
      system: null,
      title: null,
      description: null,
      advertiser: null,
      pricing: null,
      survey: null,
      errorURLTemplates: [],
      impressionURLTemplates: [],
      creatives: [],
      extensions: [],
      adVerifications: [],
      blockedAdCategories: [],
      followAdditionalWrappers: true,
      allowMultipleAds: false,
      fallbackOnNoAd: null,
    };
  })(U.parseAttributes(e));
  for (var l in a) {
    var s = a[l];
    switch (s.nodeName) {
      case 'Error':
        o.errorURLTemplates.push(U.parseNodeText(s));
        break;
      case 'Impression':
        o.impressionURLTemplates.push({
          id: s.getAttribute('id') || null,
          url: U.parseNodeText(s),
        });
        break;
      case 'Creatives':
        o.creatives = D(U.childrenByName(s, 'Creative'));
        break;
      case 'Extensions':
        var c = U.childrenByName(s, 'Extension');
        o.extensions = O(c);
        if (!o.adVerifications.length) {
          i = Y(c);
        }
        break;
      case 'AdVerifications':
        o.adVerifications = Q(U.childrenByName(s, 'Verification'));
        break;
      case 'AdSystem':
        o.system = {
          value: U.parseNodeText(s),
          version: s.getAttribute('version') || null,
        };
        break;
      case 'AdTitle':
        o.title = U.parseNodeText(s);
        break;
      case 'AdServingId':
        o.adServingId = U.parseNodeText(s);
        break;
      case 'Category':
        o.categories.push({
          authority: s.getAttribute('authority') || null,
          value: U.parseNodeText(s),
        });
        break;
      case 'Expires':
        o.expires = parseInt(U.parseNodeText(s), 10);
        break;
      case 'ViewableImpression':
        o.viewableImpression.push(
          ((n = undefined),
          (n = function (e, t) {
            var i = U.parseNodeText(t);
            if (i) {
              e.push(i);
            }
            return e;
          }),
          {
            id: (r = s).getAttribute('id') || null,
            viewable: U.childrenByName(r, 'Viewable').reduce(n, []),
            notViewable: U.childrenByName(r, 'NotViewable').reduce(n, []),
            viewUndetermined: U.childrenByName(r, 'ViewUndetermined').reduce(n, []),
          }),
        );
        break;
      case 'Description':
        o.description = U.parseNodeText(s);
        break;
      case 'Advertiser':
        o.advertiser = {
          id: s.getAttribute('id') || null,
          value: U.parseNodeText(s),
        };
        break;
      case 'Pricing':
        o.pricing = {
          value: U.parseNodeText(s),
          model: s.getAttribute('model') || null,
          currency: s.getAttribute('currency') || null,
        };
        break;
      case 'Survey':
        o.survey = {
          value: U.parseNodeText(s),
          type: s.getAttribute('type') || null,
        };
        break;
      case 'BlockedAdCategories':
        o.blockedAdCategories.push({
          authority: s.getAttribute('authority') || null,
          value: U.parseNodeText(s),
        });
    }
  }
  if (i.length) {
    o.adVerifications = o.adVerifications.concat(i);
  }
  return o;
}
function G(e, t) {
  var i = X(e, t);
  var r = e.getAttribute('followAdditionalWrappers');
  var n = e.getAttribute('allowMultipleAds');
  var a = e.getAttribute('fallbackOnNoAd');
  i.followAdditionalWrappers = !r || U.parseBoolean(r);
  i.allowMultipleAds = !!n && U.parseBoolean(n);
  i.fallbackOnNoAd = a ? U.parseBoolean(a) : null;
  var o = U.childByName(e, 'VASTAdTagURI');
  if (o) {
    i.nextWrapperURL = U.parseNodeText(o);
  } else if ((o = U.childByName(e, 'VASTAdTagURL'))) {
    i.nextWrapperURL = U.parseNodeText(U.childByName(o, 'URL'));
  }
  i.creatives.forEach(function (e) {
    if (['linear', 'nonlinear'].indexOf(e.type) !== -1) {
      if (e.trackingEvents) {
        i.trackingEvents ||= {};
        i.trackingEvents[e.type] ||= {};
        function t(t) {
          var r = e.trackingEvents[t];
          if (!Array.isArray(i.trackingEvents[e.type][t])) {
            i.trackingEvents[e.type][t] = [];
          }
          r.forEach(function (r) {
            i.trackingEvents[e.type][t].push(r);
          });
        }
        for (var r in e.trackingEvents) {
          t(r);
        }
      }
      if (e.videoClickTrackingURLTemplates) {
        if (!Array.isArray(i.videoClickTrackingURLTemplates)) {
          i.videoClickTrackingURLTemplates = [];
        }
        e.videoClickTrackingURLTemplates.forEach(function (e) {
          i.videoClickTrackingURLTemplates.push(e);
        });
      }
      if (e.videoClickThroughURLTemplate) {
        i.videoClickThroughURLTemplate = e.videoClickThroughURLTemplate;
      }
      if (e.videoCustomClickURLTemplates) {
        if (!Array.isArray(i.videoCustomClickURLTemplates)) {
          i.videoCustomClickURLTemplates = [];
        }
        e.videoCustomClickURLTemplates.forEach(function (e) {
          i.videoCustomClickURLTemplates.push(e);
        });
      }
    }
  });
  if (i.nextWrapperURL) {
    return i;
  }
}
function Q(e) {
  var t = [];
  e.forEach(function (e) {
    var i = {
      resource: null,
      vendor: null,
      browserOptional: false,
      apiFramework: null,
      type: null,
      parameters: null,
      trackingEvents: {},
    };
    var r = e.childNodes;
    U.assignAttributes(e.attributes, i);
    for (var n in r) {
      var a = r[n];
      switch (a.nodeName) {
        case 'JavaScriptResource':
        case 'ExecutableResource':
          i.resource = U.parseNodeText(a);
          U.assignAttributes(a.attributes, i);
          break;
        case 'VerificationParameters':
          i.parameters = U.parseNodeText(a);
      }
    }
    var o = U.childByName(e, 'TrackingEvents');
    if (o) {
      U.childrenByName(o, 'Tracking').forEach(function (e) {
        var t = e.getAttribute('event');
        var r = U.parseNodeText(e);
        if (t && r) {
          if (!Array.isArray(i.trackingEvents[t])) {
            i.trackingEvents[t] = [];
          }
          i.trackingEvents[t].push(r);
        }
      });
    }
    t.push(i);
  });
  return t;
}
function Y(e) {
  var t = null;
  var i = [];
  e.some(function (e) {
    return (t = U.childByName(e, 'AdVerifications'));
  });
  if (t) {
    i = Q(U.childrenByName(t, 'Verification'));
  }
  return i;
}
var K = (function () {
  function e() {
    n(this, e);
    this._handlers = [];
  }
  o(e, [
    {
      key: 'on',
      value: function (e, t) {
        if (typeof t != 'function') {
          throw new TypeError(
            `The handler argument must be of type Function. Received type ${r(t)}`,
          );
        }
        if (!e) {
          throw new TypeError(`The event argument must be of type String. Received type ${r(e)}`);
        }
        this._handlers.push({
          event: e,
          handler: t,
        });
        return this;
      },
    },
    {
      key: 'once',
      value: function (e, t) {
        return this.on(
          e,
          (function (e, t, i) {
            var r = {
              fired: false,
              wrapFn: undefined,
            };
            function n() {
              if (!r.fired) {
                e.off(t, r.wrapFn);
                r.fired = true;
                i.bind(e).apply(undefined, arguments);
              }
            }
            r.wrapFn = n;
            return n;
          })(this, e, t),
        );
      },
    },
    {
      key: 'off',
      value: function (e, t) {
        this._handlers = this._handlers.filter(function (i) {
          return i.event !== e || i.handler !== t;
        });
        return this;
      },
    },
    {
      key: 'emit',
      value: function (e) {
        for (var t = arguments.length, i = new Array(t > 1 ? t - 1 : 0), r = 1; r < t; r++) {
          i[r - 1] = arguments[r];
        }
        var n = false;
        this._handlers.forEach(function (t) {
          if (t.event === '*') {
            n = true;
            t.handler.apply(t, [e].concat(i));
          }
          if (t.event === e) {
            n = true;
            t.handler.apply(t, i);
          }
        });
        return n;
      },
    },
    {
      key: 'removeAllListeners',
      value: function (e) {
        if (e) {
          this._handlers = this._handlers.filter(function (t) {
            return t.event !== e;
          });
          return this;
        } else {
          this._handlers = [];
          return this;
        }
      },
    },
    {
      key: 'listenerCount',
      value: function (e) {
        return this._handlers.filter(function (t) {
          return t.event === e;
        }).length;
      },
    },
    {
      key: 'listeners',
      value: function (e) {
        return this._handlers.reduce(function (t, i) {
          if (i.event === e) {
            t.push(i.handler);
          }
          return t;
        }, []);
      },
    },
    {
      key: 'eventNames',
      value: function () {
        return this._handlers.map(function (e) {
          return e.event;
        });
      },
    },
  ]);
  return e;
})();
var $ = {
  get: function (e, t, i) {
    i(new Error('Please bundle the library for node to use the node urlHandler'));
  },
};
var Z = 120000;
function J() {
  try {
    var e = new window.XMLHttpRequest();
    if ('withCredentials' in e) {
      return e;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}
function ee(e, t, i) {
  var r = i ? 408 : e.status;
  var n = i
    ? `XHRURLHandler: Request timed out after ${e.timeout} ms (${r})`
    : `XHRURLHandler: ${e.statusText} (${r})`;
  t(new Error(n), null, {
    statusCode: r,
  });
}
var te = {
  get: function (e, t, i) {
    if (window.location.protocol === 'https:' && e.indexOf('http://') === 0) {
      return i(new Error('XHRURLHandler: Cannot go from HTTPS to HTTP.'));
    }
    try {
      var r = J();
      r.open('GET', e);
      r.timeout = t.timeout || Z;
      r.withCredentials = t.withCredentials || false;
      if (r.overrideMimeType) {
        r.overrideMimeType('text/xml');
      }
      r.onload = function () {
        t = i;
        if ((e = r).status === 200) {
          t(null, e.responseXML, {
            byteLength: e.response.length,
            statusCode: e.status,
          });
        } else {
          ee(e, t, false);
        }
        return;
        var e;
        var t;
      };
      r.onerror = function () {
        return ee(r, i, false);
      };
      r.onabort = function () {
        return ee(r, i, false);
      };
      r.ontimeout = function () {
        return ee(r, i, true);
      };
      r.send();
    } catch (e) {
      i(new Error('XHRURLHandler: Unexpected error'));
    }
  },
  supported: function () {
    return !!J();
  },
};
var ie = {
  get: function (e, t, i) {
    if (!i) {
      if (typeof t == 'function') {
        i = t;
      }
      t = {};
    }
    if (typeof window == 'undefined' || window === null) {
      return $.get(e, t, i);
    } else if (te.supported()) {
      return te.get(e, t, i);
    } else {
      return i(
        new Error(
          'Current context is not supported by any of the default URLHandlers. Please provide a custom URLHandler',
        ),
      );
    }
  },
};
var re = 0;
var ne = 0;
function ae(e, t) {
  if (!!e && !!t && !(e <= 0) && !(t <= 0)) {
    ne = (ne * re + (e * 8) / t) / ++re;
  }
}
var oe = {
  ERRORCODE: 900,
  extensions: [],
};
var le = (function (e) {
  s(i, K);
  var t = p(i);
  function i() {
    var e;
    n(this, i);
    (e = t.call(this)).remainingAds = [];
    e.errorURLTemplates = [];
    e.rootErrorURLTemplates = [];
    e.maxWrapperDepth = null;
    e.URLTemplateFilters = [];
    e.fetchingOptions = {};
    e.parsingOptions = {};
    return e;
  }
  o(i, [
    {
      key: 'addURLTemplateFilter',
      value: function (e) {
        if (typeof e == 'function') {
          this.URLTemplateFilters.push(e);
        }
      },
    },
    {
      key: 'removeURLTemplateFilter',
      value: function () {
        this.URLTemplateFilters.pop();
      },
    },
    {
      key: 'countURLTemplateFilters',
      value: function () {
        return this.URLTemplateFilters.length;
      },
    },
    {
      key: 'clearURLTemplateFilters',
      value: function () {
        this.URLTemplateFilters = [];
      },
    },
    {
      key: 'trackVastError',
      value: function (e, t) {
        for (var i = arguments.length, r = new Array(i > 2 ? i - 2 : 0), n = 2; n < i; n++) {
          r[n - 2] = arguments[n];
        }
        this.emit('VAST-error', Object.assign.apply(Object, [{}, oe, t].concat(r)));
        x.track(e, t);
      },
    },
    {
      key: 'getErrorURLTemplates',
      value: function () {
        return this.rootErrorURLTemplates.concat(this.errorURLTemplates);
      },
    },
    {
      key: 'getEstimatedBitrate',
      value: function () {
        return ne;
      },
    },
    {
      key: 'fetchVAST',
      value: function (e) {
        var t = this;
        var i = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
        var r = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
        var n = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
        return new Promise(function (a, o) {
          t.URLTemplateFilters.forEach(function (t) {
            e = t(e);
          });
          var l = Date.now();
          t.emit('VAST-resolving', {
            url: e,
            previousUrl: r,
            wrapperDepth: i,
            maxWrapperDepth: t.maxWrapperDepth,
            timeout: t.fetchingOptions.timeout,
            wrapperAd: n,
          });
          t.urlHandler.get(e, t.fetchingOptions, function (n, s, c = {}) {
            var u = Math.round(Date.now() - l);
            var d = Object.assign(
              {
                url: e,
                previousUrl: r,
                wrapperDepth: i,
                error: n,
                duration: u,
              },
              c,
            );
            t.emit('VAST-resolved', d);
            ae(c.byteLength, u);
            if (n) {
              o(n);
            } else {
              a(s);
            }
          });
        });
      },
    },
    {
      key: 'initParsingStatus',
      value: function (e = {}) {
        this.errorURLTemplates = [];
        this.fetchingOptions = {
          timeout: e.timeout || Z,
          withCredentials: e.withCredentials,
        };
        this.maxWrapperDepth = e.wrapperLimit || 10;
        this.parsingOptions = {
          allowMultipleAds: e.allowMultipleAds,
        };
        this.remainingAds = [];
        this.rootErrorURLTemplates = [];
        this.rootURL = '';
        this.urlHandler = e.urlHandler || e.urlhandler || ie;
        this.vastVersion = null;
        ae(e.byteLength, e.requestDuration);
      },
    },
    {
      key: 'getRemainingAds',
      value: function (e) {
        var t = this;
        if (this.remainingAds.length === 0) {
          return Promise.reject(new Error('No more ads are available for the given VAST'));
        }
        var i = e ? x.flatten(this.remainingAds) : this.remainingAds.shift();
        this.errorURLTemplates = [];
        return this.resolveAds(i, {
          wrapperDepth: 0,
          url: this.rootURL,
        }).then(function (e) {
          return t.buildVASTResponse(e);
        });
      },
    },
    {
      key: 'getAndParseVAST',
      value: function (e) {
        var t = this;
        var i = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        this.initParsingStatus(i);
        this.URLTemplateFilters.forEach(function (t) {
          e = t(e);
        });
        this.rootURL = e;
        return this.fetchVAST(e).then(function (r) {
          i.previousUrl = e;
          i.isRootVAST = true;
          i.url = e;
          return t.parse(r, i).then(function (e) {
            return t.buildVASTResponse(e);
          });
        });
      },
    },
    {
      key: 'parseVAST',
      value: function (e) {
        var t = this;
        var i = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        this.initParsingStatus(i);
        i.isRootVAST = true;
        return this.parse(e, i).then(function (e) {
          return t.buildVASTResponse(e);
        });
      },
    },
    {
      key: 'buildVASTResponse',
      value: function (e) {
        var t;
        var i = {
          ads:
            (t = {
              ads: e,
              errorURLTemplates: this.getErrorURLTemplates(),
              version: this.vastVersion,
            }).ads || [],
          errorURLTemplates: t.errorURLTemplates || [],
          version: t.version || null,
        };
        this.completeWrapperResolving(i);
        return i;
      },
    },
    {
      key: 'parseVastXml',
      value: function (e, t) {
        var i = t.isRootVAST;
        var r = i !== undefined && i;
        var n = t.url;
        var a = n === undefined ? null : n;
        var o = t.wrapperDepth;
        var l = o === undefined ? 0 : o;
        var s = t.allowMultipleAds;
        var c = t.followAdditionalWrappers;
        if (!e || !e.documentElement || e.documentElement.nodeName !== 'VAST') {
          this.emit('VAST-ad-parsed', {
            type: 'ERROR',
            url: a,
            wrapperDepth: l,
          });
          throw new Error('Invalid VAST XMLDocument');
        }
        var u = [];
        var d = e.documentElement.childNodes;
        var p = e.documentElement.getAttribute('version');
        if (r && p) {
          this.vastVersion = p;
        }
        for (var f in d) {
          var h = d[f];
          if (h.nodeName === 'Error') {
            var m = U.parseNodeText(h);
            if (r) {
              this.rootErrorURLTemplates.push(m);
            } else {
              this.errorURLTemplates.push(m);
            }
          } else if (h.nodeName === 'Ad') {
            if (this.vastVersion && parseFloat(this.vastVersion) < 3) {
              s = true;
            } else if (s === false && u.length > 1) {
              break;
            }
            var v = W(h, this.emit.bind(this), {
              allowMultipleAds: s,
              followAdditionalWrappers: c,
            });
            if (v.ad) {
              u.push(v.ad);
              this.emit('VAST-ad-parsed', {
                type: v.type,
                url: a,
                wrapperDepth: l,
                adIndex: u.length - 1,
                vastVersion: p,
              });
            } else {
              this.trackVastError(this.getErrorURLTemplates(), {
                ERRORCODE: 101,
              });
            }
          }
        }
        return u;
      },
    },
    {
      key: 'parse',
      value: function (e, t = {}) {
        var i = t.url;
        var r = i === undefined ? null : i;
        var n = t.resolveAll;
        var a = n === undefined || n;
        var o = t.wrapperSequence;
        var l = o === undefined ? null : o;
        var s = t.previousUrl;
        var c = s === undefined ? null : s;
        var u = t.wrapperDepth;
        var d = u === undefined ? 0 : u;
        var p = t.isRootVAST;
        var f = p !== undefined && p;
        var h = t.followAdditionalWrappers;
        var m = t.allowMultipleAds;
        var v = [];
        if (this.vastVersion && parseFloat(this.vastVersion) < 3 && f) {
          m = true;
        }
        try {
          v = this.parseVastXml(e, {
            isRootVAST: f,
            url: r,
            wrapperDepth: d,
            allowMultipleAds: m,
            followAdditionalWrappers: h,
          });
        } catch (e) {
          return Promise.reject(e);
        }
        if (v.length === 1 && l != null) {
          v[0].sequence = l;
        }
        if (a === false) {
          this.remainingAds = U.splitVAST(v);
          v = this.remainingAds.shift();
        }
        return this.resolveAds(v, {
          wrapperDepth: d,
          previousUrl: c,
          url: r,
        });
      },
    },
    {
      key: 'resolveAds',
      value: function () {
        var e = this;
        var t = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
        var i = arguments.length > 1 ? arguments[1] : undefined;
        var r = i.wrapperDepth;
        var n = i.previousUrl;
        var a = i.url;
        var o = [];
        n = a;
        t.forEach(function (t) {
          var i = e.resolveWrappers(t, r, n);
          o.push(i);
        });
        return Promise.all(o).then(function (t) {
          var i = x.flatten(t);
          if (!i && e.remainingAds.length > 0) {
            var o = e.remainingAds.shift();
            return e.resolveAds(o, {
              wrapperDepth: r,
              previousUrl: n,
              url: a,
            });
          }
          return i;
        });
      },
    },
    {
      key: 'resolveWrappers',
      value: function (e, t, i) {
        var r = this;
        return new Promise(function (n) {
          t++;
          if (!e.nextWrapperURL) {
            delete e.nextWrapperURL;
            return n(e);
          }
          if (t >= r.maxWrapperDepth) {
            e.errorCode = 302;
            delete e.nextWrapperURL;
            return n(e);
          }
          e.nextWrapperURL = U.resolveVastAdTagURI(e.nextWrapperURL, i);
          r.URLTemplateFilters.forEach(function (t) {
            e.nextWrapperURL = t(e.nextWrapperURL);
          });
          var o = r.parsingOptions.allowMultipleAds ?? e.allowMultipleAds;
          var l = e.sequence;
          r.fetchVAST(e.nextWrapperURL, t, i, e)
            .then(function (a) {
              return r
                .parse(a, {
                  url: e.nextWrapperURL,
                  previousUrl: i,
                  wrapperSequence: l,
                  wrapperDepth: t,
                  followAdditionalWrappers: e.followAdditionalWrappers,
                  allowMultipleAds: o,
                })
                .then(function (t) {
                  delete e.nextWrapperURL;
                  if (t.length === 0) {
                    e.creatives = [];
                    return n(e);
                  }
                  t.forEach(function (t) {
                    if (t) {
                      U.mergeWrapperAdData(t, e);
                    }
                  });
                  n(t);
                });
            })
            .catch(function (t) {
              e.errorCode = 301;
              e.errorMessage = t.message;
              n(e);
            });
        });
      },
    },
    {
      key: 'completeWrapperResolving',
      value: function (e) {
        if (e.ads.length === 0) {
          this.trackVastError(e.errorURLTemplates, {
            ERRORCODE: 303,
          });
        } else {
          for (var t = e.ads.length - 1; t >= 0; t--) {
            var i = e.ads[t];
            if (i.errorCode || i.creatives.length === 0) {
              this.trackVastError(
                i.errorURLTemplates.concat(e.errorURLTemplates),
                {
                  ERRORCODE: i.errorCode || 303,
                },
                {
                  ERRORMESSAGE: i.errorMessage || '',
                },
                {
                  extensions: i.extensions,
                },
                {
                  system: i.system,
                },
              );
              e.ads.splice(t, 1);
            }
          }
        }
      },
    },
  ]);
  return i;
})();
var se = null;
var ce = {
  data: {},
  length: 0,
  getItem: function (e) {
    return this.data[e];
  },
  setItem: function (e, t) {
    this.data[e] = t;
    this.length = Object.keys(this.data).length;
  },
  removeItem: function (e) {
    delete this.data[e];
    this.length = Object.keys(this.data).length;
  },
  clear: function () {
    this.data = {};
    this.length = 0;
  },
};
var ue = (function () {
  function e() {
    n(this, e);
    this.storage = this.initStorage();
  }
  o(e, [
    {
      key: 'initStorage',
      value: function () {
        if (se) {
          return se;
        }
        try {
          se =
            typeof window != 'undefined' && window !== null
              ? window.localStorage || window.sessionStorage
              : null;
        } catch (e) {
          se = null;
        }
        if (!se || !!this.isStorageDisabled(se)) {
          (se = ce).clear();
        }
        return se;
      },
    },
    {
      key: 'isStorageDisabled',
      value: function (e) {
        var t = '__VASTStorage__';
        try {
          e.setItem(t, t);
          if (e.getItem(t) !== t) {
            e.removeItem(t);
            return true;
          }
        } catch (e) {
          return true;
        }
        e.removeItem(t);
        return false;
      },
    },
    {
      key: 'getItem',
      value: function (e) {
        return this.storage.getItem(e);
      },
    },
    {
      key: 'setItem',
      value: function (e, t) {
        return this.storage.setItem(e, t);
      },
    },
    {
      key: 'removeItem',
      value: function (e) {
        return this.storage.removeItem(e);
      },
    },
    {
      key: 'clear',
      value: function () {
        return this.storage.clear();
      },
    },
  ]);
  return e;
})();
var de = (function () {
  function e(t, i, r) {
    n(this, e);
    this.cappingFreeLunch = t || 0;
    this.cappingMinimumTimeInterval = i || 0;
    this.defaultOptions = {
      withCredentials: false,
      timeout: 0,
    };
    this.vastParser = new le();
    this.storage = r || new ue();
    if (this.lastSuccessfulAd === undefined) {
      this.lastSuccessfulAd = 0;
    }
    if (this.totalCalls === undefined) {
      this.totalCalls = 0;
    }
    if (this.totalCallsTimeout === undefined) {
      this.totalCallsTimeout = 0;
    }
  }
  o(e, [
    {
      key: 'getParser',
      value: function () {
        return this.vastParser;
      },
    },
    {
      key: 'lastSuccessfulAd',
      get: function () {
        return this.storage.getItem('vast-client-last-successful-ad');
      },
      set: function (e) {
        this.storage.setItem('vast-client-last-successful-ad', e);
      },
    },
    {
      key: 'totalCalls',
      get: function () {
        return this.storage.getItem('vast-client-total-calls');
      },
      set: function (e) {
        this.storage.setItem('vast-client-total-calls', e);
      },
    },
    {
      key: 'totalCallsTimeout',
      get: function () {
        return this.storage.getItem('vast-client-total-calls-timeout');
      },
      set: function (e) {
        this.storage.setItem('vast-client-total-calls-timeout', e);
      },
    },
    {
      key: 'hasRemainingAds',
      value: function () {
        return this.vastParser.remainingAds.length > 0;
      },
    },
    {
      key: 'getNextAds',
      value: function (e) {
        return this.vastParser.getRemainingAds(e);
      },
    },
    {
      key: 'get',
      value: function (e) {
        var t = this;
        var i = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        var r = Date.now();
        if (!(i = Object.assign({}, this.defaultOptions, i)).hasOwnProperty('resolveAll')) {
          i.resolveAll = false;
        }
        if (this.totalCallsTimeout < r) {
          this.totalCalls = 1;
          this.totalCallsTimeout = r + 3600000;
        } else {
          this.totalCalls++;
        }
        return new Promise(function (n, a) {
          if (t.cappingFreeLunch >= t.totalCalls) {
            return a(
              new Error(
                `VAST call canceled – FreeLunch capping not reached yet ${t.totalCalls}/${t.cappingFreeLunch}`,
              ),
            );
          }
          var o = r - t.lastSuccessfulAd;
          if (o < 0) {
            t.lastSuccessfulAd = 0;
          } else if (o < t.cappingMinimumTimeInterval) {
            return a(
              new Error(
                `VAST call canceled – (${t.cappingMinimumTimeInterval})ms minimum interval reached`,
              ),
            );
          }
          t.vastParser
            .getAndParseVAST(e, i)
            .then(function (e) {
              return n(e);
            })
            .catch(function (e) {
              return a(e);
            });
        });
      },
    },
  ]);
  return e;
})();
var pe = (function (e) {
  s(a, K);
  var t = p(a);
  function a(e, i, r) {
    var o;
    var l = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
    var s = arguments.length > 4 && arguments[4] !== undefined && arguments[4];
    n(this, a);
    (o = t.call(this)).ad = i;
    o.creative = r;
    o.variation = l;
    o.muted = s;
    o.impressed = false;
    o.skippable = false;
    o.trackingEvents = {};
    o.lastPercentage = 0;
    o._alreadyTriggeredQuartiles = {};
    o.emitAlwaysEvents = [
      'creativeView',
      'start',
      'firstQuartile',
      'midpoint',
      'thirdQuartile',
      'complete',
      'resume',
      'pause',
      'rewind',
      'skip',
      'closeLinear',
      'close',
    ];
    for (var c in o.creative.trackingEvents) {
      var u = o.creative.trackingEvents[c];
      o.trackingEvents[c] = u.slice(0);
    }
    if (o.creative.type !== 'linear') {
      o._initVariationTracking();
    } else {
      o._initLinearTracking();
    }
    if (e) {
      o.on('start', function () {
        e.lastSuccessfulAd = Date.now();
      });
    }
    return o;
  }
  o(a, [
    {
      key: '_initLinearTracking',
      value: function () {
        this.linear = true;
        this.skipDelay = this.creative.skipDelay;
        this.setDuration(this.creative.duration);
        this.clickThroughURLTemplate = this.creative.videoClickThroughURLTemplate;
        this.clickTrackingURLTemplates = this.creative.videoClickTrackingURLTemplates;
      },
    },
    {
      key: '_initVariationTracking',
      value: function () {
        this.linear = false;
        this.skipDelay = -1;
        if (this.variation) {
          for (var e in this.variation.trackingEvents) {
            var t = this.variation.trackingEvents[e];
            if (this.trackingEvents[e]) {
              this.trackingEvents[e] = this.trackingEvents[e].concat(t.slice(0));
            } else {
              this.trackingEvents[e] = t.slice(0);
            }
          }
          if (this.variation.adType === 'nonLinearAd') {
            this.clickThroughURLTemplate = this.variation.nonlinearClickThroughURLTemplate;
            this.clickTrackingURLTemplates = this.variation.nonlinearClickTrackingURLTemplates;
            this.setDuration(this.variation.minSuggestedDuration);
          } else if (
            (function (e) {
              return e.adType === 'companionAd';
            })(this.variation)
          ) {
            this.clickThroughURLTemplate = this.variation.companionClickThroughURLTemplate;
            this.clickTrackingURLTemplates = this.variation.companionClickTrackingURLTemplates;
          }
        }
      },
    },
    {
      key: 'setDuration',
      value: function (e) {
        if (x.isValidTimeValue(e)) {
          this.assetDuration = e;
          this.quartiles = {
            firstQuartile: Math.round(this.assetDuration * 25) / 100,
            midpoint: Math.round(this.assetDuration * 50) / 100,
            thirdQuartile: Math.round(this.assetDuration * 75) / 100,
          };
        }
      },
    },
    {
      key: 'setProgress',
      value: function (e) {
        var t = this;
        var i = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        if (x.isValidTimeValue(e) && r(i) === 'object') {
          var n = this.skipDelay || -1;
          if (n !== -1 && !this.skippable) {
            if (n > e) {
              this.emit('skip-countdown', n - e);
            } else {
              this.skippable = true;
              this.emit('skip-countdown', 0);
            }
          }
          if (this.assetDuration > 0) {
            var a = Math.round((e / this.assetDuration) * 100);
            var o = [];
            if (e > 0) {
              o.push('start');
              for (var l = this.lastPercentage; l < a; l++) {
                o.push(`progress-${l + 1}%`);
              }
              o.push(`progress-${Math.round(e)}`);
              for (var s in this.quartiles) {
                if (this.isQuartileReached(s, this.quartiles[s], e)) {
                  o.push(s);
                  this._alreadyTriggeredQuartiles[s] = true;
                }
              }
              this.lastPercentage = a;
            }
            o.forEach(function (e) {
              t.track(e, {
                macros: i,
                once: true,
              });
            });
            if (e < this.progress) {
              this.track('rewind', {
                macros: i,
              });
            }
          }
          this.progress = e;
        }
      },
    },
    {
      key: 'isQuartileReached',
      value: function (e, t, i) {
        var r = false;
        if (t <= i && !this._alreadyTriggeredQuartiles[e]) {
          r = true;
        }
        return r;
      },
    },
    {
      key: 'setMuted',
      value: function (e, t = {}) {
        if (typeof e == 'boolean' && r(t) === 'object') {
          if (this.muted !== e) {
            this.track(e ? 'mute' : 'unmute', {
              macros: t,
            });
          }
          this.muted = e;
        }
      },
    },
    {
      key: 'setPaused',
      value: function (e, t = {}) {
        if (typeof e == 'boolean' && r(t) === 'object') {
          if (this.paused !== e) {
            this.track(e ? 'pause' : 'resume', {
              macros: t,
            });
          }
          this.paused = e;
        }
      },
    },
    {
      key: 'setFullscreen',
      value: function (e, t = {}) {
        if (typeof e == 'boolean' && r(t) === 'object') {
          if (this.fullscreen !== e) {
            this.track(e ? 'fullscreen' : 'exitFullscreen', {
              macros: t,
            });
          }
          this.fullscreen = e;
        }
      },
    },
    {
      key: 'setExpand',
      value: function (e, t = {}) {
        if (typeof e == 'boolean' && r(t) === 'object') {
          if (this.expanded !== e) {
            this.track(e ? 'expand' : 'collapse', {
              macros: t,
            });
            this.track(e ? 'playerExpand' : 'playerCollapse', {
              macros: t,
            });
          }
          this.expanded = e;
        }
      },
    },
    {
      key: 'setSkipDelay',
      value: function (e) {
        if (x.isValidTimeValue(e)) {
          this.skipDelay = e;
        }
      },
    },
    {
      key: 'trackImpression',
      value: function (e = {}) {
        if (r(e) === 'object') {
          if (!this.impressed) {
            this.impressed = true;
            this.trackURLs(this.ad.impressionURLTemplates, e);
            this.track('creativeView', {
              macros: e,
            });
          }
        }
      },
    },
    {
      key: 'trackViewableImpression',
      value: function () {
        var e = this;
        var t = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        if (r(t) === 'object') {
          this.ad.viewableImpression.forEach(function (i) {
            e.trackURLs(i.viewable, t);
          });
        }
      },
    },
    {
      key: 'trackNotViewableImpression',
      value: function () {
        var e = this;
        var t = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        if (r(t) === 'object') {
          this.ad.viewableImpression.forEach(function (i) {
            e.trackURLs(i.notViewable, t);
          });
        }
      },
    },
    {
      key: 'trackUndeterminedImpression',
      value: function () {
        var e = this;
        var t = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        if (r(t) === 'object') {
          this.ad.viewableImpression.forEach(function (i) {
            e.trackURLs(i.viewUndetermined, t);
          });
        }
      },
    },
    {
      key: 'error',
      value: function (e = {}, t = false) {
        if (r(e) === 'object' && typeof t == 'boolean') {
          this.trackURLs(this.ad.errorURLTemplates, e, {
            isCustomCode: t,
          });
        }
      },
    },
    {
      key: 'errorWithCode',
      value: function (e, t = false) {
        if (typeof e == 'string' && typeof t == 'boolean') {
          this.error(
            {
              ERRORCODE: e,
            },
            t,
          );
          console.log(
            'The method errorWithCode is deprecated, please use vast tracker error method instead',
          );
        }
      },
    },
    {
      key: 'complete',
      value: function (e = {}) {
        if (r(e) === 'object') {
          this.track('complete', {
            macros: e,
          });
        }
      },
    },
    {
      key: 'notUsed',
      value: function (e = {}) {
        if (r(e) === 'object') {
          this.track('notUsed', {
            macros: e,
          });
          this.trackingEvents = [];
        }
      },
    },
    {
      key: 'otherAdInteraction',
      value: function (e = {}) {
        if (r(e) === 'object') {
          this.track('otherAdInteraction', {
            macros: e,
          });
        }
      },
    },
    {
      key: 'acceptInvitation',
      value: function (e = {}) {
        if (r(e) === 'object') {
          this.track('acceptInvitation', {
            macros: e,
          });
        }
      },
    },
    {
      key: 'adExpand',
      value: function (e = {}) {
        if (r(e) === 'object') {
          this.track('adExpand', {
            macros: e,
          });
        }
      },
    },
    {
      key: 'adCollapse',
      value: function (e = {}) {
        if (r(e) === 'object') {
          this.track('adCollapse', {
            macros: e,
          });
        }
      },
    },
    {
      key: 'minimize',
      value: function (e = {}) {
        if (r(e) === 'object') {
          this.track('minimize', {
            macros: e,
          });
        }
      },
    },
    {
      key: 'verificationNotExecuted',
      value: function (e, t = {}) {
        if (typeof e == 'string' && r(t) === 'object') {
          if (!this.ad || !this.ad.adVerifications || !this.ad.adVerifications.length) {
            throw new Error('No adVerifications provided');
          }
          if (!e) {
            throw new Error(
              'No vendor provided, unable to find associated verificationNotExecuted',
            );
          }
          var i = this.ad.adVerifications.find(function (t) {
            return t.vendor === e;
          });
          if (!i) {
            throw new Error(`No associated verification element found for vendor: ${e}`);
          }
          var n = i.trackingEvents;
          if (n && n.verificationNotExecuted) {
            var a = n.verificationNotExecuted;
            this.trackURLs(a, t);
            this.emit('verificationNotExecuted', {
              trackingURLTemplates: a,
            });
          }
        }
      },
    },
    {
      key: 'overlayViewDuration',
      value: function (e, t = {}) {
        if (typeof e == 'string' && r(t) === 'object') {
          t.ADPLAYHEAD = e;
          this.track('overlayViewDuration', {
            macros: t,
          });
        }
      },
    },
    {
      key: 'close',
      value: function (e = {}) {
        if (r(e) === 'object') {
          this.track(this.linear ? 'closeLinear' : 'close', {
            macros: e,
          });
        }
      },
    },
    {
      key: 'skip',
      value: function (e = {}) {
        if (r(e) === 'object') {
          this.track('skip', {
            macros: e,
          });
        }
      },
    },
    {
      key: 'load',
      value: function (e = {}) {
        if (r(e) === 'object') {
          this.track('loaded', {
            macros: e,
          });
        }
      },
    },
    {
      key: 'click',
      value: function (e = null, t = {}) {
        if ((e === null || typeof e == 'string') && r(t) === 'object') {
          if (this.clickTrackingURLTemplates && this.clickTrackingURLTemplates.length) {
            this.trackURLs(this.clickTrackingURLTemplates, t);
          }
          var n = this.clickThroughURLTemplate || e;
          var a = i({}, t);
          if (n) {
            if (this.progress) {
              a.ADPLAYHEAD = this.progressFormatted();
            }
            var o = x.resolveURLTemplates([n], a)[0];
            this.emit('clickthrough', o);
          }
        }
      },
    },
    {
      key: 'track',
      value: function (e, t = {}) {
        var i = t.macros;
        var n = i === undefined ? {} : i;
        var a = t.once;
        var o = a !== undefined && a;
        if (r(n) === 'object') {
          if (e === 'closeLinear' && !this.trackingEvents[e] && this.trackingEvents.close) {
            e = 'close';
          }
          var l = this.trackingEvents[e];
          var s = this.emitAlwaysEvents.indexOf(e) > -1;
          if (l) {
            this.emit(e, {
              trackingURLTemplates: l,
            });
            this.trackURLs(l, n);
          } else if (s) {
            this.emit(e, null);
          }
          if (o) {
            delete this.trackingEvents[e];
            if (s) {
              this.emitAlwaysEvents.splice(this.emitAlwaysEvents.indexOf(e), 1);
            }
          }
        }
      },
    },
    {
      key: 'trackURLs',
      value: function (e) {
        var t;
        var r = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        var n = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
        var a = x.filterValidUrlTemplates(e);
        var o = i({}, r);
        if (this.linear) {
          if (
            this.creative &&
            this.creative.mediaFiles &&
            this.creative.mediaFiles[0] &&
            this.creative.mediaFiles[0].fileURL
          ) {
            o.ASSETURI = this.creative.mediaFiles[0].fileURL;
          }
          if (this.progress) {
            o.ADPLAYHEAD = this.progressFormatted();
          }
        }
        if (
          (t = this.creative) !== null &&
          t !== undefined &&
          (t = t.universalAdIds) !== null &&
          t !== undefined &&
          t.length
        ) {
          o.UNIVERSALADID = this.creative.universalAdIds
            .map(function (e) {
              return e.idRegistry.concat(' ', e.value);
            })
            .join(',');
        }
        if (this.ad) {
          if (this.ad.sequence) {
            o.PODSEQUENCE = this.ad.sequence;
          }
          if (this.ad.adType) {
            o.ADTYPE = this.ad.adType;
          }
          if (this.ad.adServingId) {
            o.ADSERVINGID = this.ad.adServingId;
          }
          if (this.ad.categories && this.ad.categories.length) {
            o.ADCATEGORIES = this.ad.categories
              .map(function (e) {
                return e.value;
              })
              .join(',');
          }
          if (this.ad.blockedAdCategories && this.ad.blockedAdCategories.length) {
            o.BLOCKEDADCATEGORIES = this.ad.blockedAdCategories;
          }
        }
        x.track(a, o, n);
      },
    },
    {
      key: 'convertToTimecode',
      value: function (e) {
        if (!x.isValidTimeValue(e)) {
          return '';
        }
        var t = e * 1000;
        var i = Math.floor(t / 3600000);
        var r = Math.floor((t / 60000) % 60);
        var n = Math.floor((t / 1000) % 60);
        var a = Math.floor(t % 1000);
        return `${x.addLeadingZeros(i, 2)}:${x.addLeadingZeros(r, 2)}:${x.addLeadingZeros(n, 2)}.${x.addLeadingZeros(a, 3)}`;
      },
    },
    {
      key: 'progressFormatted',
      value: function () {
        return this.convertToTimecode(this.progress);
      },
    },
  ]);
  return a;
})();
e.VASTClient = de;
e.VASTParser = le;
e.VASTTracker = pe;
e.parseDuration = I;
Object.defineProperty(e, '__esModule', {
  value: true,
});

export default e;
