const AVAILABLE_SKINS = [
  'nuevo',
  'treso',
  'chrome',
  'flow',
  'jwlike',
  'mockup',
  'party',
  'pinko',
  'roundal',
  'shaka',
  'slategrey',
];

export const guessSkin = (element: HTMLDivElement) => {
  const skinName = getComputedStyle(element, ':before')
    .getPropertyValue('content')
    .replace(/^["'](.+(?=["']$))["']$/, '$1');

  if (AVAILABLE_SKINS.includes(skinName)) {
    return skinName;
  } else {
    return '';
  }
};
