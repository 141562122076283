import { forwardRef, ReactElement, useEffect, useImperativeHandle, useRef, useState } from 'react';
import videojs from 'video.js';
import clsx from 'clsx';
import Player from 'video.js/dist/types/player';
import '../../themes/main.css';
import '../../nuevo/plugins/hlsjs';
import '../../nuevo/nuevo';
import '../../nuevo/plugins/vastpro';
import '../../nuevo/plugins/chromecast.min';
import '../../nuevo/plugins/airplay';
import '../../nuevo/plugins/videojs.hotkeys';

import { addRuLanguage } from '../../nuevo/lang/add-ru-language';
import { PauseBanner } from '../pause-banner';
import { AdTag } from '../ad-tag';
import { CamelizedAdsResponseType } from '@src/types/request-data';
import { usePlayerIsPlaying } from '@src/hooks/use-player-is-playing';
import { useIsMobile } from '@src/hooks/use-is-mobile';
import { Rewind } from '../ui/Rewind/Rewind';
import { playerEventHandlers } from './player-event-handlers';
import { csrfTokenStorage } from '@src/utils';

const videoJsOptions = {
  controls: true,
  preload: true,
  playsinline: true,
  fluid: false,
  aspectRatio: '16:9',
  responsive: true,
  playbackRates: [0.5, 1.0, 1.5, 2.0],
  language: 'ru',
  controlBar: {
    volumePanel: {
      inline: true,
    },
  },
  html5: {
    hlsjsConfig: {
      maxBufferLength: 6,
      enableWorker: true,
      lowLatencyMode: true,
      backBufferLength: 90,
      startLevel: -1,
      progressive: true,
    },
  },
};

const nuevoOptions = {
  contextMenu: false,
  buttonRewind: false,
  shareMenu: false,
  zoomMenu: false,
  pipButton: true,
  tooltips: false,
  touchRewindForward: false,
  qualityMenu: true,
  singlePlay: true,
  resOnly: true,
  minhd: 720,
  resume: true,
  controls: true,
};

type Props = {
  poster: string | undefined;
  ads: CamelizedAdsResponseType;
  kinopoiskId: number;
  source: { src: string; type: 'application/x-mpegURL'; video_id?: string };
  dropdowns: ReactElement;
  options?: any;
};

export const BasePlayer = forwardRef((props: Props, ref) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const playerRef = useRef<any | null>(null);
  const [player, setPlayer] = useState<Player | null>(null);
  const isPlaying = usePlayerIsPlaying(player);
  const isMobile = useIsMobile();

  useImperativeHandle(ref, () => {
    return {
      restartVast() {
        const vastPlugin = playerRef.current?.vast({
          ads: props.ads,
        });
        if (vastPlugin) {
          vastPlugin.restart();
        }
      },
    };
  }, [props.ads]);

  useEffect(() => {
    const startVast = () => {
      (player as any)?.vast({
        ads: props.ads,
      });
      setTimeout(() => {
        player?.play();
      }, 500);
    };

    player?.one('play', startVast);
  }, [player, props.ads]);

  useEffect(() => {
    if (!videoRef.current) return;

    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      addRuLanguage(videojs);

      const player = videojs(
        videoRef.current,
        { ...videoJsOptions, ...props.options },
        function onPlayerReady() {
          console.log('Player Ready!');
          setPlayer(player);
          playerEventHandlers(player, { kinopoiskId: props.kinopoiskId });
        },
      );

      playerRef.current = player;
    }

    const player = playerRef.current;
    player.poster(props.poster);
    player.nuevo(nuevoOptions);
    player.airplay();
    player.chromecast();
    player.hotkeys();
  }, [props]);

  useEffect(() => {
    const player = playerRef.current;
    if (!player) return;

    if (player.src() === props.source.src) {
      return;
    }

    const abortController = new AbortController();
    const prevTime = player.currentTime();
    const csrfToken = csrfTokenStorage.getToken();

    fetch(props.source.src, {
      credentials: 'include',
      signal: abortController.signal,
      headers: { 'x-csrf-token': csrfToken },
      method: 'POST',
    })
      .then((res) => res.json())
      .then(({ url }) => {
        player.src({ ...props.source, src: url });
        player.currentTime(prevTime);
        player.load();
      });

    return () => {
      abortController.abort();
    };
  }, [props.source]);

  return (
    <div className="relative">
      <div data-vjs-player>
        <video ref={videoRef} className="video-js vjs-show-big-play-button-on-pause relative" />

        {isMobile && <Rewind player={playerRef.current} />}

        <PauseBanner player={playerRef.current} />
      </div>

      <div
        id="playlist-params"
        className={clsx('absolute inset-0 top-0 left-0 w-full pointer-events-none z-[8888]', {
          hidden: isPlaying,
        })}
      >
        <div className="relative top-0 left-0 w-full px-2 overflow-hidden h-full">
          <div className="h-20 bg-backdrop-header w-full absolute inset-0 left-0 top-0"></div>
          <div className="flex absolute left-0 top-2 gap-1 w-full px-2 sm:px-4 sm:top-4 sm:gap-2">
            {props.dropdowns}
          </div>
        </div>
      </div>

      <AdTag player={playerRef.current} />
    </div>
  );
});
