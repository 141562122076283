export const addRuLanguage = (videojs: any) => {
  videojs.addLanguage('ru', {
    'Audio Player': 'Аудиоплеер',
    'Video Player': 'Видеоплеер',
    Auto: 'Авто',
    Play: 'Воспроизвести',
    Pause: 'Приостановить',
    Replay: 'Воспроизвести снова',
    'Current Time': 'Текущее время',
    Duration: 'Продолжительность',
    'Remaining Time': 'Оставшееся время',
    'Stream Type': 'Тип потока',
    LIVE: 'ПРЯМОЙ ЭФИР',
    'Seek to live, currently behind live':
      'Переход к прямому эфиру (воспроизведение идёт с отставанием)',
    'Seek to live, currently playing live':
      'Переход к прямому эфиру (воспроизведение идёт без отставания)',
    Loaded: 'Загрузка',
    Progress: 'Прогресс',
    'Progress Bar': 'Индикатор загрузки',
    'progress bar timing: currentTime={1} duration={2}': '{1} из {2}',
    Fullscreen: 'Полноэкранный режим',
    'Exit Fullscreen': 'Неполноэкранный режим',
    Mute: 'Без звука',
    Unmute: 'Со звуком',
    'Playback Rate': 'Скорость воспроизведения',
    Subtitles: 'Субтитры',
    'subtitles off': 'Субтитры выкл.',
    Captions: 'Подписи',
    'captions off': 'Подписи выкл.',
    Chapters: 'Главы',
    Descriptions: 'Описания',
    'descriptions off': 'Отключить описания',
    'Audio Track': 'Звуковая дорожка',
    'Volume Level': 'Уровень громкости',
    'You aborted the media playback': 'Вы прервали воспроизведение видео',
    'A network error caused the media download to fail part-way.':
      'Ошибка сети вызвала сбой во время загрузки.',
    'The media could not be loaded, either because the server or network failed or because the format is not supported.':
      'Не удалось загрузить видео из-за сетевого или серверного сбоя либо неподдерживаемого формата видео.',
    'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.':
      'Воспроизведение прервано из-за повреждения либо в связи с тем, что видео использует функции, неподдерживаемые вашим браузером.',
    'No compatible source was found for this media.':
      'Совместимые источники для этого видео отсутствуют.',
    'The media is encrypted and we do not have the keys to decrypt it.':
      'Видео зашифровано, а у нас нет ключей для его расшифровки.',
    'Play Video': 'Воспроизвести видео',
    Close: 'Закрыть',
    'Close Modal Dialog': 'Закрыть модальное окно',
    'Modal Window': 'Модальное окно',
    'This is a modal window': 'Это модальное окно',
    'This modal can be closed by pressing the Escape key or activating the close button.':
      'Модальное окно можно закрыть нажав Esc или кнопку закрытия окна.',
    ', opens captions settings dialog': ', откроется диалог настройки подписей',
    ', opens subtitles settings dialog': ', откроется диалог настройки субтитров',
    ', opens descriptions settings dialog': ', откроется диалог настройки описаний',
    ', selected': ', выбрано',
    'captions settings': 'настройки подписей',
    'subtitles settings': 'настройки субтитров',
    'descriptions settings': 'настройки описаний',
    Text: 'Текст',
    White: 'Белый',
    Black: 'Черный',
    Red: 'Красный',
    Green: 'Зеленый',
    Blue: 'Синий',
    Yellow: 'Желтый',
    Magenta: 'Пурпурный',
    Cyan: 'Голубой',
    Background: 'Фон',
    Window: 'Окно',
    Transparent: 'Прозрачный',
    'Semi-Transparent': 'Полупрозрачный',
    Opaque: 'Прозрачность',
    'Font Size': 'Размер шрифта',
    'Text Edge Style': 'Стиль края текста',
    None: 'Ничего',
    Raised: 'Поднятый',
    Depressed: 'Пониженный',
    Uniform: 'Одинаковый',
    Dropshadow: 'Тень',
    'Font Family': 'Шрифт',
    'Proportional Sans-Serif': 'Пропорциональный без засечек',
    'Monospace Sans-Serif': 'Моноширинный без засечек',
    'Proportional Serif': 'Пропорциональный с засечками',
    'Monospace Serif': 'Моноширинный с засечками',
    Casual: 'Казуальный',
    Script: 'Рукописный',
    'Small Caps': 'Капитель',
    Reset: 'Сбросить',
    'restore all settings to the default values': 'сбросить все настройки на значения по умолчанию',
    Done: 'Готово',
    'Caption Settings Dialog': 'Диалог настроек подписи',
    'Beginning of dialog window. Escape will cancel and close the window.':
      'Начало диалогового окна. Нажмите Escape для отмены и закрытия окна',
    'End of dialog window.': 'Конец диалогового окна.',
    '{1} is loading.': '{1} загружается.',
    'Exit Picture-in-Picture': 'Закрыть картинку в картинке',
    'Picture-in-Picture': 'Картинка в картинке',
    'Skip forward {1} seconds': 'На {1} секунд вперед',
    'Skip backward {1} seconds': 'На {1} секунд назад',

    Quality: 'Качество',
    Settings: 'Настройки',
    Related: 'Похожие',
    Share: 'Поделиться',
    Speed: 'Скорость',
    Normal: 'Обычный',
    Zoom: 'Зум',
    Link: 'Ссылка',
    Embed: 'Встроить',
    Social: 'Социальная сеть',
    'Copy video URL': 'Скопируйте URL-адрес видео',
    'Check out this cool video on': 'Посмотрите это классное видео на',
    RESET: 'СБРОС',
    'ZOOM HELP': 'ПОМОЩЬ ПРИ УВЕЛИЧЕНИИ',
    'Use ZOOM slider or mouse wheel to ZOOM in video.':
      'Используйте ползунок ZOOM или колесико мыши для масштабирования видео.',
    'Drag zoomed area using your mouse.':
      'Перетащите увеличенную область с помощью мыши или пальца.',
    Forward: 'Вперед',
    Rewind: 'Перемотка',
    'Mirror view': 'Зеркальный вид',
    'Theater mode': 'Режим кинотеатра',
    'Exit Theater mode': 'Выйти из режима кинотеатра',
    Advertisement: 'Реклама',
    'Skip Ad in': 'Пропустить через',
    'Video Starts Soon': 'Видео скоро начнется',
    'Skip Now!': 'Пропустить сейчас!',
    'Watch full video on': 'Смотрите полное видео на',
    'Open Chromecast menu': 'Открыть меню Chromecast',
    'Disconnect Chromecast': 'Отключить Chromecast',
    'Casting to': 'Casting to',
    'Skip Ad in %%TIME%%': 'Пропустить через %%TIME%%',
    'Skip Ad': 'Пропустить рекламу',
    'Ad %%NUM%% of %%TOTAL%%': 'Ad %%NUM%% of %%TOTAL%%',
    Snapshot: 'Снимок',
    'This Ad will end in': 'Эта реклама закончится в',
    'Ad Playing': 'Воспроизведение рекламы',
    Filters: 'Фильтры',
    'More videos': 'Другие видео',
    'Up Next': 'Далее',
    'Start AirPlay': 'Запуск AirPlay',
  });
};
