import { Camelize } from '@src/utils/to-camel-case';

export type MovieContentType = 'movie' | 'anime';
export type SeriesContentType = 'tv-series' | 'show-tv-series' | 'anime-tv-series';
export type ContentType = MovieContentType | SeriesContentType;

export type MediaResponseType = {
  playlist: string;
  translation_id: number;
  translation_name: string;
};

export type EpisodeResponseType = {
  episode_id: number;
  name: string;
  poster: string;
  media: MediaResponseType[];
};

export type SeasonResponseType = {
  season_id: number;
  season_name: string;
  episodes: EpisodeResponseType[];
};

type CommonPlayerResponseType = {
  kinopoisk_id?: number;
  content_id: number;
  poster: string;
};

export type MoviePlayerResponseType = {
  content_type: MovieContentType;
  media: MediaResponseType[];
} & CommonPlayerResponseType;

export type SeriesPlayerResponseType = {
  content_type: SeriesContentType;
  media: SeasonResponseType[];
} & CommonPlayerResponseType;

export type AdResponseType = {
  tag_url: string;
  time_offset?: string;
};

export type MovieResponseType = {
  meta: string;
  ads: AdResponseType[];
  player: MoviePlayerResponseType;
};
export type SeriesResponseType = {
  meta: string;
  ads: AdResponseType[];
  player: SeriesPlayerResponseType;
};

export type ResponseType = MovieResponseType | SeriesResponseType;
export type CamelizedMovieResponseType = Camelize<MovieResponseType>;
export type CamelizedSeriesResponseType = Camelize<SeriesResponseType>;
export type CamelizedResponseType = Camelize<ResponseType>;
export type CamelizedAdsResponseType = Camelize<AdResponseType>[];

export const isMovieResponse = (
  response: CamelizedResponseType,
): response is CamelizedMovieResponseType => {
  return ['movie', 'anime'].includes(response.player.contentType);
};

export const isSeriesResponse = (
  response: CamelizedResponseType,
): response is CamelizedSeriesResponseType => {
  return ['tv-series', 'show-tv-series', 'anime-tv-series'].includes(response.player.contentType);
};
