import videojs from 'video.js';

import { guessSkin } from './guess-skin';
import { setSkin } from './set-skin';

export const applySkin = () => {
  if (typeof window === 'undefined') {
    return;
  }

  if (!document.body) {
    return;
  }

  const skin_el = document.createElement('div');
  const doc = document.body;
  doc.appendChild(skin_el);
  skin_el.className = 'vjs-skin';
  let skn = guessSkin(skin_el);

  if (skn !== '') {
    setSkin(((videojs as any).skin = skn));
    doc.removeChild(skin_el);
  } else {
    setTimeout(function () {
      if ((skn = guessSkin(skin_el)) !== '') {
        setSkin(((videojs as any).skin = skn));
        doc.removeChild(skin_el);
      }
    }, 50);
  }
};
