export const API_BASE_URL = 'https://player-api-develop.dev.lumex.me'; // this dev api
// export const API_BASE_URL = 'https://api.lumex.bar'; // this stage api
// export const API_BASE_URL = 'https://api.lumex.wiki'; // this prod api
export const STATS_RIP_URL = 'https://svcdn.stats.rip';

export enum ERROR_CONTENT {
  NOT_FOUND = 'Not Found',
  NOT_AVAILABLE = 'Данный контент недоступен',
  GENERAL_ERROR = 'Something went wrong',
}
export enum STATUS_CODE {
  NOT_FOUND = 404,
  NOT_AVAILABLE = 451,
}
