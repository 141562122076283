import { MoviePreparedDataType, VoiceoverType } from '@src/types/prepared-data';
import { BasePlayer } from '../base-player';
import { Dropdown } from '../ui/Dropdown/Dropdown';
import { useCallback, useState } from 'react';

type Props = {
  data: MoviePreparedDataType;
};

type VoiceoverOption = {
  value: string;
  text: string;
  playlist: string;
};

export const MoviePlayer = ({ data }: Props) => {
  const defaultSource = {
    src: data.voiceovers[0].playlist,
    type: 'application/x-mpegURL' as const,
    // TODO: build correct video id
    // video_id: data.player.contentId,
  };
  const [source, setSource] = useState(defaultSource);

  const voiceoverOptions = data.voiceovers.map((voiceover: VoiceoverType) => ({
    value: voiceover.id,
    text: voiceover.name,
    playlist: voiceover.playlist,
  }));

  const handleVoiceoverChange = useCallback((newVoiceover: VoiceoverOption) => {
    setSource({
      src: newVoiceover.playlist,
      type: 'application/x-mpegURL' as const,
    });
  }, []);

  return (
    <BasePlayer
      source={source}
      poster={data.poster}
      ads={data.ads}
      kinopoiskId={data.kinopoiskId}
      dropdowns={
        <Dropdown<VoiceoverOption>
          options={voiceoverOptions}
          placeholder={voiceoverOptions[0].text}
          onSelect={handleVoiceoverChange}
          disabled={voiceoverOptions.length === 0}
          className="flex-grow flex-shrink basis-auto min-w-[90px] max-w-max pointer-events-auto"
        />
      }
    />
  );
};
