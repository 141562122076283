import { STATS_RIP_URL } from '@src/constants';
import { getParentOrigin } from '@src/utils/get-parent-origin';

export const sendContentViewStatsripAnalytics = (pre: 0 | 1, kinopoiskId = 0) => {
  const host = getParentOrigin();

  const url = `${STATS_RIP_URL}/?player=VCDN&service=player&host=${host}&id=${kinopoiskId}&pre=${pre}`;

  const sendAnalyticsStatsrip = (requestUrl: string) => {
    try {
      new Image().src = requestUrl;
    } catch (error) {
      console.log(error);
    }
  };

  sendAnalyticsStatsrip(url);
};
