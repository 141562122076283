import { useState } from 'react';
import clsx from 'clsx';
import posterPlaceholderImg from '@src/assets/img/placeholder_poster.jpg';
import { Spinner } from '@src/components/ui/Spinner/Spinner';

export const LoadingScreen = () => {
  const [imageLoaded, setImageLoaded] = useState(false);
  return (
    <div className="relative w-full h-full aspect-video">
      <img
        className={clsx(
          'object-cover w-full h-full opacity-0 transition-opacity duration-500',
          imageLoaded && 'opacity-100',
        )}
        src={posterPlaceholderImg}
        onLoad={() => setImageLoaded(true)}
        alt="Loading"
      />

      <div className="absolute inset-0 w-full h-full bg-black/55">
        <div className="w-full h-full flex justify-center items-end -translate-y-[20%]">
          <Spinner className="!w-8 !h-8 animate-spin" />
        </div>
      </div>
    </div>
  );
};
