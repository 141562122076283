export const DEFAULT_OPTIONS = {
  nonlinearRecall: false,
  withCredentials: false,
  timeout: 6000,
  resolveAll: true,
  wrapperLimit: 10,
  allowMultipleAds: true,
  clickthroughMethod: 'player',
  closeNonlinearButton: true,
  closeNonlinearTheme: 'light',
  autoClose: false,
  outstreamClose: false,
  brandText: '',
  brandUrl: '',
  outstreamCloseTheme: 'dark',
  skipTimer: false,
  autoplay: true,
};
