import ERROR_POSTER from '@src/assets/img/error_poster.jpg';

type Props = {
  errorMessage: string;
};

export const ErrorPoster = ({ errorMessage }: Props) => {
  return (
    <div className="relative">
      <img src={ERROR_POSTER} alt="Error message background" />
      <p
        aria-label="error text"
        className="absolute bottom-[25%] text-white ml-auto mr-auto left-0 right-0 w-[80%] text-center"
      >
        {errorMessage}
      </p>
    </div>
  );
};
