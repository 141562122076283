import { SeriesPreparedDataType } from '@src/types/prepared-data';
import { BasePlayer } from '../base-player';
import { useCallback, useRef, useState } from 'react';
import { SeriesDropdowns, type VoiceoverOption } from './series-dropdowns';

type Props = {
  data: SeriesPreparedDataType;
};

export const SeriesPlayer = ({ data }: Props) => {
  const defaultSource = {
    src: data.seasons[0].episodes[0].voiceovers[0].playlist,
    type: 'application/x-mpegURL' as const,
    // TODO: build correct video id
    // video_id: data.player.contentId,
  };
  const basePlayerRef = useRef<{ restartVast: () => void } | null>(null);

  const [source, setSource] = useState(defaultSource);

  const handleVoiceoverChange = useCallback(
    (newVoiceover: Pick<VoiceoverOption, 'playlist'>, shouldRestartVast = false) => {
      setSource({
        src: newVoiceover.playlist,
        type: 'application/x-mpegURL' as const,
      });

      if (shouldRestartVast) {
        basePlayerRef.current?.restartVast();
      }
    },
    [],
  );

  return (
    <BasePlayer
      source={source}
      poster={data.poster}
      ads={data.ads}
      kinopoiskId={data.kinopoiskId}
      dropdowns={<SeriesDropdowns data={data} onVoiceoverChange={handleVoiceoverChange} />}
      ref={basePlayerRef}
    />
  );
};
