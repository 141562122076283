import { useEffect, useState } from 'react';
import { prepareRequestData } from './prepare-request-data';
import { PreparedDataType } from '@src/types/prepared-data';
import { buildRequestUrl } from './build-request-url';
import { ERROR_CONTENT, STATUS_CODE } from '@src/constants';

export const useContentRequest = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<PreparedDataType | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const requestUrl = buildRequestUrl();

  useEffect(() => {
    const abortController = new AbortController();

    const fetchContent = async () => {
      setLoading(true);

      try {
        const response = await fetch(requestUrl, {
          signal: abortController.signal,
          credentials: 'include',
        });

        if (response.ok) {
          const responseJson = await response.json();
          const preparedData = await prepareRequestData(responseJson);
          setData(preparedData);
        } else {
          switch (response.status) {
            case STATUS_CODE.NOT_FOUND:
              setError(new Error(ERROR_CONTENT.NOT_FOUND));
              break;
            case STATUS_CODE.NOT_AVAILABLE:
              setError(new Error(ERROR_CONTENT.NOT_AVAILABLE));
              break;
            default:
              setError(new Error(ERROR_CONTENT.GENERAL_ERROR));
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          if (error.name !== 'AbortError') {
            setError(error);
          }
        } else {
          setError(new Error(ERROR_CONTENT.GENERAL_ERROR));
        }
      } finally {
        setLoading(false);
      }
    };

    fetchContent();

    return () => {
      abortController.abort();
    };
  }, [requestUrl]);

  return { loading, data, error };
};
