import { preparePoster } from './prepare-poster';
import {
  CamelizedMovieResponseType,
  CamelizedSeriesResponseType,
  isMovieResponse,
  isSeriesResponse,
  ResponseType,
} from '@src/types/request-data';
import { toCamelCase, getApi, csrfTokenStorage } from '@src/utils';
import { MoviePreparedDataType, SeriesPreparedDataType } from '@src/types/prepared-data';

const api = getApi();

const prepareMovieData = async (
  response: CamelizedMovieResponseType,
): Promise<MoviePreparedDataType> => {
  return {
    kind: 'movie',
    ads: response.ads,
    kinopoiskId: response.player.kinopoiskId || 0,
    poster: await preparePoster(response.player.poster),
    voiceovers: response.player.media.map((mediaItem) => ({
      id: String(mediaItem.translationId),
      name: mediaItem.translationName,
      playlist: `${api}${mediaItem.playlist}`,
    })),
  };
};

const prepareSeriesData = async (
  response: CamelizedSeriesResponseType,
): Promise<SeriesPreparedDataType> => {
  return {
    kind: 'series',
    ads: response.ads,
    kinopoiskId: response.player.kinopoiskId || 0,
    poster: await preparePoster(response.player.poster),
    seasons: response.player.media.map((season) => ({
      id: String(season.seasonId),
      name: season.seasonName,

      episodes: season.episodes.map((episode) => ({
        id: String(episode.episodeId),
        name: episode.name,

        voiceovers: episode.media.map((mediaItem) => ({
          id: String(mediaItem.translationId),
          name: mediaItem.translationName,
          playlist: `${api}${mediaItem.playlist}`,
        })),
      })),
    })),
  };
};

export const prepareRequestData = async (data: ResponseType | null) => {
  if (!data) {
    return null;
  }

  csrfTokenStorage.setToken(data.meta);

  const camelCasedData = toCamelCase(data);

  if (isMovieResponse(camelCasedData)) {
    return prepareMovieData(camelCasedData);
  }

  if (isSeriesResponse(camelCasedData)) {
    return prepareSeriesData(camelCasedData);
  }

  return null;
};
