import videojs from 'video.js';

export const setSkin = (skinName: string) => {
  const i = 'progressControl';
  const t = 'playToggle';
  const s = 'liveDisplay';
  const n = 'seekToLive';
  const a = 'currentTimeDisplay';
  const o = 'timeDivider';
  const l = 'durationDisplay';
  const r = 'customControlSpacer';
  const d = 'volumePanel';
  const v = 'chaptersButton';
  const f = 'descriptionsButton';
  const c = 'subsCapsButton';
  const u = 'audioTrackButton';
  const p = 'pictureInPictureToggle';
  const h = 'fullscreenToggle';
  let m = null;

  if (skinName === 'treso') {
    m = {
      children: [i, t, s, n, a, l, d, r, v, f, c, u, p, h],
    };
  } else if (skinName === 'chrome') {
    m = {
      children: [t, s, n, a, o, l, r, i, d, v, f, c, u, p, h],
    };
  } else if (skinName === 'party') {
    m = {
      children: [t, s, n, i, a, o, l, r, d, v, f, c, u, p, h],
    };
  } else if (skinName === 'roundal' || skinName === 'pinko') {
    m = {
      volumePanel: {
        inline: false,
        vertical: true,
      },
      children: [t, s, n, a, i, o, l, r, d, v, f, c, u, p, h],
    };
  } else if (skinName === 'shaka' || skinName === 'slategrey') {
    m = {
      volumePanel: {
        inline: false,
        vertical: true,
      },
      children: [t, s, n, a, o, l, i, r, d, v, f, c, u, p, h],
    };
  } else if (skinName === 'flow') {
    m = {
      children: [t, s, n, a, o, l, i, r, d, v, f, c, u, p, h],
    };
  } else if (skinName === 'jwlike') {
    m = {
      volumePanel: {
        inline: false,
        vertical: true,
      },
      children: [i, t, s, n, d, a, o, l, r, v, f, c, u, p, h],
    };
  } else if (skinName === 'mockup') {
    m = {
      children: [i, t, s, n, d, a, o, l, r, v, f, c, u, p, h],
    };
  } else if (skinName === 'nuevo') {
    m = {
      volumePanel: {
        inline: false,
        vertical: true,
      },
      children: [t, s, n, a, o, i, l, r, d, v, f, c, u, p, h],
    };
  }
  if (m !== null) {
    videojs.options.controlBar = m;
  }
};
