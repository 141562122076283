import DEFAULT_POSTER from '@src/assets/img/placeholder_poster.jpg';

export const preparePoster = async (posterUrl: string | undefined) => {
  if (!posterUrl) {
    return DEFAULT_POSTER;
  }

  try {
    const response = await fetch(posterUrl, { method: 'HEAD' });
    if (response.ok) {
      return posterUrl;
    }

    return DEFAULT_POSTER;
  } catch {
    return DEFAULT_POSTER;
  }
};
