// import { useDataForm } from './hooks/use-data-form';
// import { VideoPlayer } from './components/video-player';
// import { SeriesData } from './types/processed-serial-data';
// import { processMediaData } from './utils/process-media-data';
// import { MediaData } from './types/media-data';
// import { useSerialData } from './hooks/use-serial-data/use-serial-data';
import { useContentRequest } from './hooks/use-content-request';
import { ErrorPoster } from './components/error-poster';
import { MoviePlayer } from './components/movie-player';
import { SeriesPlayer } from './components/series-player/series-player';
import { LoadingScreen } from './components/loading-screen';

const App = () => {
  // TODO: remove
  // const parameters = useDataForm();
  // const processedData: MediaData = processMediaData(parameters);

  // const selectedVoiceover = processedData.translations;

  // const { isSerial, selectedSeason, selectedEpisode } = useSerialData(processedData);

  const { loading, data, error } = useContentRequest();

  if (error) {
    return <ErrorPoster errorMessage={error.message} />;
  }

  if (loading || !data) {
    return <LoadingScreen />;
  }

  if (data.kind === 'movie') {
    return <MoviePlayer data={data} />;
  }

  if (data.kind === 'series') {
    return <SeriesPlayer data={data} />;
  }

  return null;

  // const selectedSource = data.player.media[0].playlist;

  // return (
  //   <VideoPlayer
  //     options={{
  //       sources: [
  //         { src: selectedSource, type: 'application/x-mpegURL', video_id: data.player.contentId },
  //       ],
  //     }}
  //     poster={data.player.poster}
  //     ads={data.ads}
  //     contentId={data.player.contentId}
  //     voiceOver={selectedVoiceover}
  //     filmsData={!isSerial ? processedData.playlist : undefined}
  //     seriesData={isSerial ? (processedData.playlist as SeriesData) : undefined}
  //     selectedSeason={selectedSeason}
  //     selectedEpisode={selectedEpisode}
  //   />
  // );
};

export default App;
