import { useCallback, useEffect, useRef, useState } from 'react';
import { clsx } from 'clsx';
import Player from 'video.js/dist/types/player';

import { useLoadScript } from '@src/hooks/use-load-script';
import { checkIsTimeToDisplayAd } from '@src/utils';
import './styles.css';
import { getUrlParamsObject } from '@src/hooks/use-content-request/build-request-url';

type Props = {
  player: Player | null;
};

const AD_TAG_TYPE = 4;

const urlParams = getUrlParamsObject();

const DISPLAY_TIME_OFFSET = '25%';
const SHOW_CLOSE_BUTTON_DELAY = 30_000; //ms
const AUTO_CLOSE_DELAY = 60_000; //ms

export const AdTag = ({ player }: Props) => {
  const [isAdTagVisible, setAdTagVisible] = useState(false);
  const [isCloseButtonVisible, setCloseButtonVisible] = useState(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleTimeUpdate = useCallback(() => {
    if (!player) {
      return;
    }

    const isTimeToDisplayAd = checkIsTimeToDisplayAd(DISPLAY_TIME_OFFSET, player);
    if (isTimeToDisplayAd) {
      setAdTagVisible(true);
      player.off('timeupdate', handleTimeUpdate);
    }
  }, [player]);

  useEffect(() => {
    if (!isAdTagVisible) {
      return;
    }

    timeoutRef.current = setTimeout(() => {
      setAdTagVisible(false);
    }, AUTO_CLOSE_DELAY);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isAdTagVisible]);

  useEffect(() => {
    if (!isAdTagVisible) {
      return;
    }

    const closeButtonTimeout = setTimeout(() => {
      setCloseButtonVisible(true);
    }, SHOW_CLOSE_BUTTON_DELAY);

    return () => {
      clearTimeout(closeButtonTimeout);
    };
  }, [isAdTagVisible]);

  const handleClose = useCallback(() => {
    setAdTagVisible(false);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  useEffect(() => {
    if (!player) {
      return;
    }

    player.on('timeupdate', handleTimeUpdate);

    return () => {
      player.off('timeupdate', handleTimeUpdate);
    };
  }, [handleTimeUpdate, player]);

  useLoadScript('https://cdn77.aj1907.online/63c0d7d8.js');

  return (
    <div className={clsx('absolute top-[10%] -translate-y-[10%]', { hidden: !isAdTagVisible })}>
      <ins
        className="604c7625"
        data-key="05f790708c959460e28a775fcb13d00c"
        // @ts-expect-error TODO: refactor later for typesafety
        data-cp-host={`${urlParams.clientId}|${AD_TAG_TYPE}|${urlParams.domain}`}
      ></ins>
      <button
        aria-label="close ad tag"
        onClick={handleClose}
        className={clsx('ad-tag-close-button', { hidden: !isCloseButtonVisible })}
      />
    </div>
  );
};
