export const createElement = (tagName: string, className?: string, innerHTML?: string) => {
  const tag = document.createElement(tagName);
  if (className !== undefined) {
    if (className !== '') {
      tag.className = className;
    }
  }
  if (innerHTML !== undefined) {
    if (innerHTML !== '') {
      tag.innerHTML = innerHTML;
    }
  }
  return tag;
};
