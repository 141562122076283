// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable */
import videojs from 'video.js';
import Player from 'video.js/dist/types/player';

import './polyfills';
import { DEFAULT_OPTIONS } from './default-options';
import { applySkin } from './skin';
import { sortByKey, createElement, checkDomain } from './utils';
import { assertNotNullable } from '../../utils';

applySkin();

function initPlugin(player: Player, userOptions: Partial<typeof DEFAULT_OPTIONS>) {
  let options = {};

  try {
    options = videojs.obj.merge(DEFAULT_OPTIONS, userOptions);
  } catch (e) {
    options = videojs.mergeOptions(DEFAULT_OPTIONS, userOptions);
  }

  var playerEl = player.el();
  var playerTechEl = player.$('.vjs-tech') as HTMLVideoElement | null;
  assertNotNullable(playerTechEl);
  var isDesktop = false;
  var isDisposed = false;
  var hiddenClassname = 'vjs-hidden';
  const bigPlayButtonEl = playerEl.querySelector('.vjs-big-play-button');
  assertNotNullable(bigPlayButtonEl);
  var progressHolderEl = playerEl.querySelector('.vjs-progress-holder');
  var loadingSpinnerEl = playerEl.querySelector('.vjs-loading-spinner');
  var controlBarEl = playerEl.querySelector('.vjs-control-bar');
  var posterEl = playerEl.querySelector('.vjs-poster');

  checkDomain();

  var U = 'vjs-touch-active';
  var K = 'vjs-touch-inactive';
  if (!playerTechEl.autoplay) {
    bigPlayButtonEl.classList.add('vjs-bp-block');
  }
  var i = playerEl.querySelector('.vjs-play-control .vjs-control-text');
  if (i) {
    i.ariaLive = 'off';
  }
  i = document.createElement('div');
  if (!options.fullscreenButton) {
    player.controlBar.getChild('fullscreenToggle').el_.classList.add('fs-hidden');
    playerEl.addEventListener(
      'dblclick',
      function (e) {
        e.preventDefault();
        e.stopPropagation();
      },
      true,
    );
  }
  i.className = 'vjs-' + videojs.skin + '-icons';
  document.body.appendChild(i);
  if (!videojs.nuevo && !options.mapp) {
    playerEl.innerHTML = '';
    playerEl.classList.add('vjs-lcn');
  } else {
    var a = '';
    if (1) {
      a = window.getComputedStyle(i, '::before').getPropertyValue('content');
      i.parentNode.removeChild(i);
      if (a.length > 10) {
        a = (a = a.replaceAll('\\', '')).replace(/(^"|"$)/g, '');
        const lt = new window.DOMParser();
        const rt = lt.parseFromString(a, 'image/svg+xml');
        const dt = rt.querySelector('parsererror');
        if (dt) {
          console.log('SVG parsing Error');
        } else {
          const vt = rt.documentElement;
          vt.style.display = 'none';
          playerEl.insertBefore(vt, playerTechEl.nextSibling);
          videojs.svgIcons = true;
        }
      }
    }
    options.skin = '';
    if (videojs.skin) {
      options.skin = videojs.skin;
    }
    var i = createElement('div', 'vjs-skin-' + options.skin);
    playerEl.appendChild(i);
    function I(e) {
      return player.localize(e);
    }
    if (options.log === false) {
      videojs.log.level('off');
    }
    var X;
    var u;
    var p;
    var Y;
    var j = 0;
    var Q = 0;
    var V = 0;
    var Z = 0;
    var d = 1;
    player.shadowSlide = options.ghostThumb;
    let e = player.el_.querySelector('.vjs-b-p-b');
    if (e);
    else {
      document.addEventListener('pointermove', fi);
      player.on('dispose', function () {
        window.removeEventListener('touchstart', ci);
        document.removeEventListener('pointermove', fi);
        player.$('.vjs-tech').removeEventListener('touchstart', yi);
      });
      window.addEventListener('touchstart', ci, {
        passive: true,
      });
      var sharingOverlayElement;
      var M;
      function J() {
        if (isDesktop) {
          return true;
        }
        if (matchMedia('(pointer:fine)').matches) {
          ui(true);
          return true;
        }
        return false;
      }
      J();
      var v = [];
      var ee = false;
      var ie = false;
      if (!videojs.dispose && !options.mapp) {
        playerEl.innerHTML = '';
      } else {
        player.qualities = [];
        player.qualityIndex = -1;
        if (
          options.iosFullscreen === 'pseudo' &&
          videojs.browser.IS_IOS &&
          videojs.browser.IOS_VERSION > 9 &&
          !player.el_.ownerDocument.querySelector('.bc-iframe')
        ) {
          player.tech_.el_.setAttribute('playsinline', 'playsinline');
          player.tech_.supportsFullScreen = function () {
            return false;
          };
        }
        player.on('fullscreenchange', function () {
          if (videojs.browser.IS_ANDROID && options.androidLock) {
            if (typeof window != 'undefined') {
              if (player.isFullscreen()) {
                window.screen.orientation.lock('landscape-primary');
              } else {
                window.screen.orientation.lock('any');
              }
            }
          }
        });
        if (options.touchControls) {
          var t = createElement('div', 'vjs-touch-nav');
          playerEl.insertBefore(t, bigPlayButtonEl);
          var n = player.addChild('button', {
            el: videojs.dom.createEl(
              'button',
              {
                className: 'vjs-big-button vjs-b-p-b',
              },
              {
                type: 'button',
                'aria-label': I('Play'),
                'aria-disabled': 'false',
                tabindex: '0',
              },
            ),
          });
          playerEl.insertBefore(t, bigPlayButtonEl);
          n.el_.innerHTML = B('play', I('Play'));
          t.appendChild(n.el_);
          n.el_.ontouchend = function (e) {
            e.stopImmediatePropagation();
            bi();
            if (player.paused()) {
              player.play();
            } else {
              player.pause();
            }
          };
          bigPlayButtonEl.ontouchend = posterEl.ontouchend = function (e) {
            e.stopImmediatePropagation();
            if (ee !== true) {
              videojs.dom.addClass(playerEl, K);
            }
          };
        }
        if (options.touchRewindForward && options.touchControls) {
          a = player.addChild('button', {
            el: videojs.dom.createEl(
              'button',
              {
                className: 'vjs-big-button vjs-b-r-b vjs-b-r-b-' + options.rewindforward,
              },
              {
                type: 'button',
                title: I('Rewind'),
                'aria-disabled': 'false',
              },
            ),
          });
          a.el_.innerHTML = B('rewind' + options.rewindforward, I('Rewnid'));
          t.insertBefore(a.el_, n.el_);
          i = player.addChild('button', {
            el: videojs.dom.createEl(
              'button',
              {
                className: 'vjs-big-button vjs-b-f-b vjs-b-f-b-' + options.rewindforward,
              },
              {
                type: 'button',
                title: I('Forward'),
                'aria-disabled': 'false',
              },
            ),
          });
          i.el_.innerHTML = B('forward' + options.rewindforward, I('Forward'));
          t.appendChild(i.el_);
          i.el_.ontouchend = function (e) {
            e.stopImmediatePropagation();
            bi();
            player.forward();
          };
          a.el_.ontouchend = function (e) {
            e.stopImmediatePropagation();
            bi();
            player.rewind();
          };
        }
        var te = player.options_.inactivityTimeout;
        // CHANGES: start
        // change inactivityTouchTimeout
        var se = 8000;
        // CHANGES: end
        if (player.options_.inactivityTouchTimeout) {
          se = player.options_.inactivityTouchTimeout;
        }
        controlBarEl.addEventListener('touchstart', bi, {
          passive: true,
        });
        controlBarEl.addEventListener('touchmove', bi, {
          passive: true,
        });
        player.on('dispose', function () {
          controlBarEl.removeEventListener('touchstart', bi);
          controlBarEl.removeEventListener('touchmove', bi);
        });
        player.resetTech = function () {
          player.$('.vjs-tech').addEventListener('touchstart', yi, {
            passive: true,
          });
        };
        player.on('chromecastConnected', function () {
          player.$('.vjs-tech').removeEventListener('touchstart', yi);
          setTimeout(function () {
            player.$('.vjs-tech-chromecast').addEventListener('touchstart', yi, {
              passive: true,
            });
          }, 200);
        });
        player.on('chromecastDisconnected', function () {
          setTimeout(function () {
            player.$('.vjs-tech').addEventListener('touchstart', yi, {
              passive: true,
            });
          }, 200);
        });
        player.$('.vjs-tech').addEventListener('enterpictureinpicture', () => {
          gi();
        });
        player.on('dispose', function () {
          player.$('.vjs-tech').removeEventListener('touchstart', yi);
          player.$('.vjs-tech').removeEventListener('enterpictureinpicture', gi);
        });
        player.resetTech();
        player.setQuality = function (e, i) {
          if (player.qualities.length) {
            if (typeof i == 'boolean') {
              player.qualities[e].enabled = i;
              if (player.tech_.vhs) {
                player.tech_.vhs.representations()[e].enabled(i);
              }
            }
          }
        };
        if (options.controlbar !== true) {
          videojs.dom.addClass(playerEl, 'vjs-controls-none');
        }
        player.video_id = function () {
          for (var e = player.currentSources(), i = 0; i < e.length; i++) {
            if (e[i].video_id !== undefined) {
              options.video_id = e[i].video_id;
              break;
            }
          }
          return options.video_id || '';
        };
        player.video_title = function () {
          for (var e = player.currentSources(), i = 0; i < e.length; i++) {
            if (e[i].title !== undefined) {
              options.title = e[i].title;
              break;
            }
          }
          return options.title || '';
        };
        player.textTracksStyle = function (e) {
          if (player.textTrackSettings) {
            var i;
            var t;
            var s;
            var n = player.textTrackSettings;
            var a = n.getValues();
            var o = player.captionsSettings;
            n.setDefaults();
            var l = [];
            for ([i] of Object.entries(a)) {
              if (e[i]) {
                l[i] = e[i];
              } else {
                l[i] = a[i];
              }
            }
            for ([t] of Object.entries(o)) {
              if (e[t]) {
                l[t] = e[t];
              }
            }
            for ([s] of Object.entries(e)) {
              l[s] = e[s];
            }
            player.captionsSettings = l;
            n.setValues(player.captionsSettings);
            n.updateDisplay();
          }
        };
        player.captionsSettings = {
          backgroundOpacity: '0',
          edgeStyle: 'raised',
        };
        player.textTracksStyle(player.captionsSettings);
        if (options.ccButton !== true) {
          n = controlBarEl.querySelector('.vjs-subs-caps-button');
          if (n) {
            videojs.dom.addClass(n, 'vjs-abs-hidden');
          }
        }
        player.forward = function () {
          var e = player.duration();
          var i = player.currentTime();
          if (e > 0) {
            var t = player.currentTime() + options.rewindforward;
            if (e < t) {
              player.currentTime(e);
            } else {
              player.currentTime(t);
            }
          }
          player.trigger('forward', {
            oldTime: i,
            newTime: t,
          });
        };
        player.rewind = function () {
          if (player.duration() > 0) {
            var e = player.currentTime();
            var i = e - options.rewindforward;
            if (i < 0) {
              i = 0;
            }
            player.currentTime(i);
            player.trigger('rewind', {
              oldTime: e,
              newTime: i,
            });
          }
        };
        player.related = function () {
          if (options.settingsButton && options.related.length > 1 && options.relatedMenu) {
            setVisibility(sharingOverlayElement, false);
            N('');
            setVisibility(relatedMenuElement, true);
            Hi();
            player.trigger('related');
            player.pause();
          }
        };
        player.snapshot = function () {
          var e = playerEl.querySelector('video');
          var i = e.videoWidth;
          var t = e.videoHeight;
          var s = t / i;
          var n = document.getElementById('snap');
          if (n) {
            n.parentNode.removeChild(n);
          }
          n = createElement('canvas');
          n.id = 'snap';
          n.style.position = 'absolute';
          n.style.left = '-10000px';
          document.body.appendChild(n);
          var a = n.getContext('2d');
          if (
            videojs.browser.IS_ANDROID ||
            videojs.browser.IS_IPAD ||
            videojs.browser.IS_IPHONE ||
            videojs.browser.IS_IPOD
          ) {
            if (i > 640) {
              t = parseInt(s * 640, 10);
              i = 640;
            }
          }
          n.width = i;
          n.height = t;
          a.fillRect(0, 0, i, t);
          a.drawImage(e, 0, 0, i, t);
          if (options.snapshotWatermark !== '') {
            if (options.snapshotWatermark.length > 2) {
              s = options.snapshotWatermark;
              if (i < 641) {
                a.font = '16px verdana';
              } else {
                a.font = '24px verdana';
              }
              e = a.measureText(s).width;
              a.globalAlpha = 0.5;
              a.fillStyle = 'white';
              a.fillText(s, i - e - 10, t - 20);
              a.fillStyle = 'black';
              a.fillText(s, i - e - 10 + 2, t - 20 + 2);
            }
          }
          a = 'snapshot.jpg';
          s = n.toDataURL('image/jpeg', 0.9);
          if (options.snapshotType === 'png') {
            a = 'snapshot.png';
            s = n.toDataURL('image/png');
          }
          var o = document.createElement('a');
          o.href = s;
          o.download = a;
          document.body.appendChild(o);
          setTimeout(function () {
            o.click();
          }, 200);
        };
        player.on('filters', function () {
          setVisibility(sharingOverlayElement, false);
          setVisibility(relatedMenuElement, false);
          gi();
        });
        player.share = function () {
          if (!options.settingsButton || !options.shareMenu || !options.shareUrl) {
            return;
          }

          setVisibility(relatedMenuElement, false);
          N('');
          player.trigger('share');
          var e = options.shareUrl || 'N/A';
          if (options.embed) {
            playerEl.querySelector('.embed-code').value = options.embed;
          }
          playerEl.querySelector('.perma').value = e;
          setVisibility(sharingOverlayElement, true);
          player.pause();
        };
        if (options.contextMenu !== true && options.contextMenu !== 'default') {
          playerEl.oncontextmenu = function (e) {
            e.preventDefault();
          };
        }
        t = playerEl.querySelector('.vjs-custom-control-spacer');
        if (t) {
          t.innerHTML = '';
        }
        var ne = player.addChild('button', {
          el: videojs.dom.createEl(
            'button',
            {
              className: 'vjs-replay-button',
            },
            {
              type: 'button',
              title: I('Replay'),
              'aria-disabled': 'false',
            },
          ),
        });
        playerEl.insertBefore(ne.el_, bigPlayButtonEl);
        ne.el_.innerHTML = B('big-replay', I('Replay'));
        ne.el_.onclick = ne.el_.ontouchend = function (e) {
          e.preventDefault();
          e.stopImmediatePropagation();
          player.currentTime(0);
          player.play();
          player.trigger('replay');
        };
        i = playerEl.querySelector('.vjs-picture-in-picture-control');
        if (options.pipButton !== true) {
          playerTechEl.disablePictureInPicture = true;
        }
        a = player.controlBar.el().lastChild;
        if ((document.pictureInPictureEnabled && options.pipButton) || i) {
          a = player.controlBar.getChild('pictureInPictureToggle').el_;
        }
        n = player.controlBar.addChild('button', {
          el: videojs.dom.createEl(
            'div',
            {
              className: 'vjs-quality-button vjs-menu-button vjs-control vjs-button vjs-hidden',
            },
            {
              tabindex: 0,
              role: 'button',
              type: 'button',
              'aria-live': 'off',
              'aria-haspopup': 'true',
              'aria-disabled': 'false',
              'aria-expanded': 'false',
              'aria-label': I('Quality menu'),
            },
          ),
        });
        t = player.controlBar.addChild('button', {
          el: videojs.dom.createEl(
            'button',
            {
              className: 'vjs-control vjs-button vjs-cog-button vjs-cog-menu-button vjs-hidden',
            },
            {
              tabindex: 0,
              role: 'button',
              type: 'button',
              'aria-haspopup': 'true',
              'aria-live': 'off',
              'aria-disabled': 'false',
              'aria-expanded': 'false',
              'aria-label': I('Settings menu'),
            },
          ),
        });
        controlBarEl.insertBefore(n.el_, a);
        controlBarEl.insertBefore(t.el_, a);
        M = t.el_;
        if (options.snapshot) {
          i = player.controlBar.addChild('button', {
            el: videojs.dom.createEl(
              'button',
              {
                className: 'vjs-snap-control vjs-control vjs-button',
              },
              {
                type: 'button',
              },
            ),
          });
          i.el_.innerHTML = B('snapshot', I('Sanpshot'));
          player.controlBar.el_.insertBefore(i.el_, t.el_);
          i.el_.onclick = i.el_.ontouchend = function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            player.snapshot();
          };
        }
        if (options.controlbar) {
          a = videojs.dom.createEl('div', {
            className: 'vjs-bg-bar',
          });
          playerEl.insertBefore(a, controlBarEl);
        }
        var S = n.el_;
        var i =
          '<span class="quality-span vjs-no-pointer"></span><span class="vjs-control-text" aria-live="polite">' +
          I('Quality') +
          '</span><div class="vjs-menu"><ul class="vjs-menu-content vjs-qlist" role="menu"></ul></div>';
        S.innerHTML = i;
        if (options.buttonForward) {
          if (options.buttonRewind) {
            _i(true);
          }
          var ae = 'vjs-forward-control';
          var a = player.controlBar.addChild('button', {
            el: videojs.dom.createEl(
              'button',
              {
                className: ae + ' vjs-control vjs-button vjs-forward-' + options.rewindforward,
              },
              {
                title: I('Forward'),
                type: 'button',
                'aria-disabled': 'false',
              },
            ),
          });
          a.el_.innerHTML = B('forward' + options.rewindforward, I('Forward'));
          if (options.skin === 'party' && options.buttonRewind) {
            player.controlBar.el_.insertBefore(
              a.el_,
              playerEl.querySelector('.vjs-rewind-control').nextSibling,
            );
          } else {
            player.controlBar.el_.insertBefore(
              a.el_,
              player.controlBar.getChild('playToggle').el_.nextSibling,
            );
          }
          a.el_.onclick = a.el_.ontouchend = function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            player.forward();
          };
        } else if (options.buttonRewind) {
          _i();
        }
        n = videojs.dom.createEl('div', {
          className: 'vjs-vast-label',
        });
        n.innerHTML = I('Advertisement');
        i = 'playToggle';
        if (options.skin === 'party') {
          i = 'progressControl';
        }
        if (options.skin === 'treso') {
          i = 'volumePanel';
        }
        controlBarEl.insertBefore(n, player.controlBar.getChild(i).el_.nextSibling);
        if (options.theaterButton) {
          var oe = player.controlBar.addChild('button', {
            el: videojs.dom.createEl(
              'button',
              {
                className: 'vjs-control vjs-button vjs-control-button vjs-mode-control',
              },
              {
                type: 'button',
                'aria-live': 'polite',
                'aria-disabled': 'false',
              },
            ),
          });
          var le = I('Theater mode');
          var re = I('Exit theater mode');
          var a = createElement('span');
          a.ariaHidden = 'true';
          a.className = 'vjs-icon-placeholder';
          a.setAttribute('data-id', 'expand');
          var de = createElement('span');
          de.ariaLive = 'polite';
          de.className = 'vjs-control-text';
          oe.el_.appendChild(a);
          oe.el_.appendChild(de);
          de.innerHTML = le;
          de.setAttribute('data-id', 'expand-back');
          controlBarEl.insertBefore(oe.el_, player.controlBar.getChild('fullscreenToggle').el_);
          var ve = oe.el_;
          if (options.theaterButton === 'large') {
            videojs.dom.addClass(ve, 'vjs-mode');
            de.innerHTML = re;
          }
          ve.onclick = function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            if (videojs.dom.hasClass(ve, 'vjs-mode')) {
              videojs.dom.removeClass(ve, 'vjs-mode');
              de.innerHTML = le;
              H(oe.el_, 'expand');
              player.trigger('mode', 'normal');
            } else {
              videojs.dom.addClass(ve, 'vjs-mode');
              player.trigger('mode', 'large');
              de.innerHTML = re;
              H(oe.el_, 'expand-back');
            }
          };
        }
        var c = createElement('div', 'vjs-menu-settings');
        var g = createElement('div', 'vjs-menu-div vjs-settings-div');
        c.appendChild(g);
        var b = createElement('div', 'vjs-submenu vjs-settings-home');
        g.appendChild(b);
        b.innerHTML = '<div class="vjs-menu-title">' + I('Settings') + '</div>';
        n = createElement('ul', 'vjs-menu-content vjs-settings-list');
        b.appendChild(n);
        n.role = 'menu';
        M.innerHTML =
          '<span aria-hidden="true" class="vjs-icon-placeholder" data-id="settings"></span><span class="vjs-hd vjs-hidden">HD</span><span class="vjs-control-text" aria-live="polite">' +
          I('Settings') +
          '</span>';
        M.appendChild(c);
        if (options.downloadButton) {
          i = player.controlBar.addChild('button', {
            el: videojs.dom.createEl(
              'button',
              {
                className: 'vjs-download-control vjs-control vjs-button',
                title: I('Download'),
              },
              {
                type: 'button',
                'aria-disabled': 'false',
              },
            ),
          });
          controlBarEl.insertBefore(i.el_, player.controlBar.getChild('fullscreenToggle').el_);
          i.el_.innerHTML = B('download', I('Download'));
          i.el_.onclick = function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            e = player.video_id();
            player.trigger('downloadVideo', {
              source: player.currentSrc(),
              id: e,
            });
          };
        }
        player.on('durationchange', function () {
          if (player.duration() === Infinity || player.duration() >= 4294967296) {
            if (player.seekable().length > 0) {
              let e = player.seekable().end(0) - player.seekable().start(0);
              if (options.dvrMin !== 'none') {
                if (e > options.dvrMin) {
                  player.el_.classList.add('vjs-liveui');
                }
              }
            }
          }
        });
        ki();
        playerEl.querySelectorAll('.vjs-control').forEach((e) => {
          wi(e);
        });
        controlBarEl.querySelectorAll('.vjs-button').forEach((e) => {
          wi(e);
        });
        player.on('playerresize', function () {
          xi();
        });
        player.on('dispose', function () {
          window.removeEventListener('click', Ti);
          window.removeEventListener('touchstart', Ei);
          if (window.parent) {
            window.parent.removeEventListener('click', Ti);
            window.parent.removeEventListener('touchstart', Ei);
          }
          isDisposed = true;
        });
        window.addEventListener('click', Ti);
        window.addEventListener('touchstart', Ei);
        if (window.parent) {
          try {
            window.parent.addEventListener('click', Ti);
            window.parent.addEventListener('touchstart', Ei);
          } catch (e) {}
        }
        progressHolderEl.addEventListener('touchend', function (e) {
          gi(e);
        });
        a = playerEl.querySelector('.vjs-volume-panel-vertical');
        if (a) {
          let t = playerEl.querySelector('.vjs-volume-vertical');
          let s = a.querySelector('.vjs-mute-control');
          let n = t.querySelector('.vjs-slider-vertical');
          if (t && s) {
            s.onkeydown = function (e) {
              if (e.which == 9) {
                t.classList.add('vjs-hover');
              }
            };
            n.onblur = function () {
              t.classList.remove('vjs-hover');
            };
            playerEl.onkeydown = function (e) {
              let i = document.activeElement;
              if (i !== s && i !== n && e.which === 9) {
                t.classList.remove('vjs-hover');
              }
            };
          }
        }
        if (options.title === '') {
          options.title = options.metatitle = document.title;
        }
        if (options.infoText === '') {
          options.infoText = options.metatitle;
        }
        n = playerEl.querySelector('.vjs-play-progress .vjs-time-tooltip');
        if (n) {
          videojs.dom.addClass(n, 'vjs-abs-hidden');
        }
        try {
          if (!options.mousedisplay) {
            videojs.dom.addClass(playerEl.querySelector('.vjs-mouse-display'), 'vjs-abs-hidden');
          }
        } catch (e) {}
        if (options.logocontrolbar) {
          var fe = createElement('img');
          fe.src = options.logocontrolbar;
          fe.onload = function () {
            if (this.height > 0) {
              var e = createElement('div', 'vjs-logo-bar');
              if (options.logourl !== '') {
                var i = createElement('a');
                i.href = options.logourl;
                i.target = options.target;
                if (options.logotitle) {
                  i.title = options.logotitle;
                }
                i.appendChild(fe);
                e.appendChild(i);
              } else {
                e.appendChild(fe);
              }
              controlBarEl.insertBefore(e, player.controlBar.getChild('fullscreenToggle').el_);
            }
          };
        }
        if (options.contextMenu && options.contextMenu !== 'default') {
          var ce = createElement('div', 'vjs-context-menu vjs-hidden');
          var i = createElement('ul');
          var ue = createElement('li', 'cplay', I('Play'));
          var pe = createElement('li', 'cmute', I('Mute'));
          var he = createElement('li', 'cfull', I('Fullscreen'));
          var me = createElement('li', 'curi vjs-hidden', I('Copy video URL'));
          i.append(ue, pe, he, me);
          var a = null;
          if (options.contextUrl !== undefined && options.contextText !== undefined) {
            if (options.contextIcon) {
              a = createElement(
                'li',
                'link',
                '<a target="' +
                  options.target +
                  '" href="' +
                  options.contextUrl +
                  '"><img src="' +
                  options.contextIcon +
                  '">' +
                  options.contextText +
                  '</a>',
              );
            } else {
              a = createElement(
                'li',
                'link',
                '<a target="' +
                  options.target +
                  '" href="' +
                  options.contextUrl +
                  '">' +
                  options.contextText +
                  '</a>',
              );
            }
          } else if (options.contextLink) {
            a = createElement(
              'li',
              'link',
              '<a target="_blank" href="//www.nuevodevel.com/nuevo/">&copy; Nuevo v.12.0.0</a>',
            );
          }
          if (a) {
            i.appendChild(a);
          }
          ce.appendChild(i);
          playerEl.appendChild(ce);
          ue.onclick = function () {
            if (player.paused()) {
              player.play();
            } else {
              player.pause();
            }
          };
          pe.onclick = function () {
            if (player.muted()) {
              player.muted(false);
            } else {
              player.muted(true);
            }
          };
          he.onclick = function () {
            if (player.isFullscreen()) {
              player.exitFullscreen();
            } else {
              player.requestFullscreen();
            }
          };
          me.onclick = function () {
            if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
              navigator.clipboard.writeText(options.url);
            }
          };
          function je() {
            videojs.dom.addClass(ce, hiddenClassname);
            window.removeEventListener('scroll', je);
            window.removeEventListener('mouseup', je);
          }
          player.on('dispose', function () {
            window.removeEventListener('scroll', je);
            window.removeEventListener('mouseup', je);
            window.removeEventListener('mousedown', je);
          });
          playerEl.oncontextmenu = function (e) {
            e.preventDefault();
            if (me && options.url === '') {
              videojs.dom.addClass(me, hiddenClassname);
            } else {
              videojs.dom.removeClass(me, hiddenClassname);
            }
            if (player.paused()) {
              ue.innerHTML = 'Play';
            } else {
              ue.innerHTML = 'Pause';
            }
            if (player.muted()) {
              pe.innerHTML = 'Unmute';
            } else {
              pe.innerHTML = 'Mute';
            }
            if (player.isFullscreen()) {
              he.innerHTML = 'Exit Fullscreen';
            } else {
              he.innerHTML = 'Fullscreen';
            }
            videojs.dom.removeClass(ce, hiddenClassname);
            var i = ce.offsetWidth;
            var t = ce.offsetHeight;
            var s = null;
            var n = null;
            if (e.clientY) {
              s = e.clientY;
            }
            if (e.clientX) {
              n = e.clientX;
            }
            if (s !== null && n !== null) {
              e = playerEl.getBoundingClientRect();
              s = s - e.top;
              n = n - e.left;
              if (s + t > playerEl.offsetHeight) {
                s = playerEl.offsetTop + playerEl.offsetHeight - t;
              }
              if (n + i > playerEl.offsetWidth) {
                n = playerEl.offsetWidth - i;
              }
              ce.style.top = s + 'px';
              ce.style.left = n + 'px';
              window.addEventListener('scroll', je);
              window.addEventListener('mouseup', je);
            }
          };
        }
        if (options.logo) {
          var ge = createElement('img');
          ge.src = options.logo;
          ge.onload = function () {
            if (this.height > 0) {
              var e = createElement('div', 'vjs-logo');
              if (options.logomin) {
                e.className = 'vjs-logo vjs-logo-min';
              }
              playerEl.appendChild(e);
              var i = options.logooffsetX;
              var t = options.logooffsetY;
              if (options.logoposition === 'RT') {
                e.style.right = i + 'px';
                e.style.top = t + 'px';
              } else if (options.logoposition === 'LB') {
                e.style.left = i + 'px';
                e.className += ' vjs-logo-bottom';
              } else {
                e.style.left = i + 'px';
                e.style.top = t + 'px';
              }
              if (options.logourl !== '') {
                i = createElement('a');
                i.href = options.logourl;
                i.target = options.target;
                if (options.logotitle) {
                  i.title = options.logotitle;
                }
                i.appendChild(ge);
                e.appendChild(i);
              } else {
                e.appendChild(ge);
              }
            }
          };
        }
        if (options.skin === 'treso') {
          n = playerEl.querySelector('.vjs-current-time');
          a = playerEl.querySelector('.vjs-duration');
          controlBarEl.removeChild(n);
          i = playerEl.querySelector('.vjs-progress-control');
          i.insertBefore(n, i.childNodes[0]);
          controlBarEl.removeChild(a);
          i.appendChild(a);
        }
        player.resetNuevo = function () {
          var e;
          var i;
          var t = [];
          var s = [];
          Li();
          Bi();
          if (player.options_.sources.length > 0) {
            s = t = player.options_.sources;
          }
          S.querySelector('.vjs-menu .vjs-menu-content').innerHTML = '';
          videojs.dom.addClass(S, hiddenClassname);
          if (t.length < 2) {
            return t[0];
          }
          if (t.length > 1) {
            var n = 0;
            var a = [];
            var o = '';
            var l = 0;
            var r = 0;
            var d = 0;
            var v = false;
            for (l = 0; l < s.length; l++) {
              if (t[n].res || t[n].label) {
                if (v !== true) {
                  v = true;
                  i = {
                    res: s[l].res,
                    type: s[l].type,
                    src: s[l].src,
                    label: s[l].label,
                  };
                }
                a[r] = s[l];
                r++;
              }
            }
            var f = [];
            if (a < 2);
            else {
              var f = (f = a).sort(Ni);
              var c = '';
              var u = ' vjs-checked';
              var c = ' class="vjs-menu-item item-quality"';
              var u = ' class="vjs-menu-item item-quality vjs-checked"';
              for (l = 0; l < f.length; l++) {
                var p = f[l].res;
                var p = parseInt(p, 10);
                var h = '';
                if (options.hdicon && p > options.minhd - 1) {
                  var m = 'HD';
                  if (p > 1079 && options.minhd < 1080) {
                    m = 'FHD';
                  }
                  if (p > 2159) {
                    m = '4K';
                  }
                  h = '<i class="vjs-hd-icon">' + m + '</i>';
                }
                p =
                  'tabindex="0" role="menuitemradio" aria-live="off" aria-disabled="false" aria-label="' +
                  I('Set quality to ' + f[l].label) +
                  '" ';
                if (f[l].default) {
                  e = f[l];
                  o += '<li ' + p + u + ' data-height="' + l + '">' + f[l].label + h + '</li>';
                } else {
                  o += '<li ' + p + c + ' data-height="' + l + '">' + f[l].label + h + '</li>';
                }
                d++;
              }
              if (d > 1) {
                Z = d;
                if (options.qualityMenu) {
                  Zi();
                  (j = playerEl.querySelector('.vjs-menu-quality .vjs-menu-content')).innerHTML =
                    j.innerHTML + o;
                  videojs.dom.removeClass(
                    playerEl.querySelector('.vjs-extend-quality'),
                    hiddenClassname,
                  );
                  videojs.dom.removeClass(M, hiddenClassname);
                } else {
                  var j = S.querySelector('.vjs-menu');
                  var g = j.querySelector('.vjs-menu-title');
                  if (g) {
                    j.removeChild(g);
                  }
                  g = createElement('div', 'vjs-menu-title', I('Quality'));
                  j.prepend(g);
                  S.querySelector('.vjs-menu .vjs-menu-content').innerHTML = o;
                  videojs.dom.removeClass(S, hiddenClassname);
                }
                Bi();
                j.addEventListener('keydown', Ai);
                for (
                  var b = playerEl.querySelectorAll('.item-quality'),
                    y =
                      (Bi(),
                      function (e) {
                        var i;
                        for (var t = 0; t < b.length; t++) {
                          videojs.dom.removeClass(b[t], 'vjs-checked');
                          if (b[t] === e.target) {
                            i = t;
                          }
                        }
                        videojs.dom.addClass(b[i], 'vjs-checked');
                        var s = e.target.getAttribute('data-height');
                        qi(f[s].res, f[s].label);
                        var n = player.video_id();
                        player.trigger('resolutionchange', {
                          id: n,
                          res: f[s].res,
                        });
                        var n = f[s];
                        zi();
                        var s = player.remoteTextTracks ? player.remoteTextTracks() : [];
                        if (s && Array.isArray(s.tracks_)) {
                          s = s.tracks_;
                        }
                        var a = [];
                        s.forEach(function (e) {
                          if (e.kind && e.src) {
                            var i = {
                              kind: e.kind,
                              src: e.src,
                              mode: e.mode,
                            };
                            if (e.srclang) {
                              i.srclang = e.srclang;
                            }
                            if (e.language) {
                              i.srclang = e.language;
                            }
                            if (e.label) {
                              i.label = e.label;
                            }
                            a.push(i);
                          }
                        });
                        $i();
                        w(n);
                        options.chapters = false;
                        player.one('loadeddata', function () {
                          if (a.length > 0) {
                            player.loadTracks(a);
                          }
                        });
                      }),
                    l = 0;
                  l < b.length;
                  l++
                ) {
                  var _ = b[l];
                  var C = !(_.onclick = function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    y(e);
                    S.focus();
                  });
                  _.addEventListener(
                    'touchstart',
                    function () {
                      C = false;
                    },
                    {
                      passive: true,
                    },
                  );
                  _.addEventListener(
                    'touchmove',
                    function () {
                      C = true;
                    },
                    {
                      passive: true,
                    },
                  );
                  _.ontouchend = function (e) {
                    e.stopPropagation();
                    if (C);
                    else {
                      y(e);
                    }
                  };
                }
                Si();
                if (e !== undefined || i !== undefined) {
                  if (e !== undefined && e !== i) {
                    qi(e.res, e.label);
                    player.src(e);
                    return e;
                  } else if (i !== undefined) {
                    qi(i.res, i.label);
                    return i;
                  }
                }
                function w(e) {
                  ie = true;
                  var i = player.currentTime();
                  var t = player.paused();
                  player.src(e);
                  player.load();
                  if (i > 0) {
                    player.currentTime(i);
                  }
                  if (t) {
                    player.pause();
                  } else {
                    player.play();
                  }
                  if (options.rate !== 1) {
                    player.setRate(options.rate);
                  }
                }
              }
            }
          }
        };
        player.on('loadeddata', function () {
          if (
            videojs.dom.hasClass(playerEl, 'vjs-ad-playing') ||
            videojs.dom.hasClass(playerEl, 'vjs-dai')
          ) {
            return false;
          }
          Fi();
          if (ie) {
            ie = false;
          } else {
            if (
              options.startTime ||
              player.options_.startTime ||
              player.$('.vjs-tech').getAttribute('startTime')
            ) {
              var e = player.$('.vjs-tech').getAttribute('startTime') || 0;
              if (options.startTime) {
                e = options.startTime;
              }
              if (player.options_.startTime) {
                e = player.options_.startTime;
              }
              if ((options.startTime = e) > 5 && e < player.duration() - 5) {
                loadingSpinnerEl.style.opacity = 0;
                player.currentTime(e);
              }
            } else {
              Ri();
            }
            Fi();
            if (player.isAudio()) {
              videojs.dom.addClass(playerEl, 'vjs-audio');
              options.is_audio = true;
            }
            var i = playerEl.querySelectorAll('.vjs-menu-button-popup');
            for (C = 0; C < i.length; C++) {
              i[C].onclick = i[C].ontouchend = function (e) {
                t(e);
              };
              i[C].onmousemove = function (e) {
                var i =
                  e.target.querySelector('.vjs-menu') ||
                  e.target.parentNode.querySelector('.vjs-menu');
                if (i) {
                  if (videojs.dom.hasClass(i, 'vjs-lock-showing'));
                  else {
                    i =
                      e.target.querySelector('.vjs-control-text') ||
                      e.target.parentNode.querySelector('.vjs-control-text');
                    if (i) {
                      i.removeAttribute('style');
                    }
                  }
                }
              };
              function t(e) {
                playerEl.querySelectorAll('.vjs-control-text').forEach((e) => {
                  e.removeAttribute('style');
                });
                var i = e.target.querySelector('.vjs-control-text');
                if (e.target.getAttribute('aria-expanded') === 'true') {
                  i.style.opacity = '0';
                } else {
                  i.removeAttribute('style');
                }
                i =
                  e.target.querySelector('.vjs-menu') ||
                  e.target.parentNode.querySelector('.vjs-menu');
                if (i) {
                  if (!videojs.dom.hasClass(i, 'vjs-lock-showing')) {
                    N(e.target);
                  }
                }
                var t =
                  e.target.querySelector('.vjs-menu-content') ||
                  e.target.parentNode.querySelector('.vjs-menu-content');
                var i = i.getBoundingClientRect();
                var s = playerEl.getBoundingClientRect();
                let n = playerEl.offsetHeight - (s.bottom - i.bottom);
                s = e.target.parentNode.querySelector('.vjs-menu-title');
                if (s) {
                  let e = getComputedStyle(s, 'display');
                  if (e !== 'none') {
                    n += 30;
                  }
                }
                if (videojs.dom.hasClass(playerEl, 'vjs-cast-fixed') && t) {
                  t.style.maxHeight = 'none';
                } else {
                  t.style.maxHeight = n + 'px';
                }
              }
            }
            Ki();
            Z = 0;
            player.qualityIndex = -1;
            player.off('mediachange');
            player.qualities = [];
            player.one('playing', a);
            player.one('levelsLoaded', a);
            player.on('dashlevelChange', function () {
              player.trigger('mediachange');
            });
          }
          function s() {
            if (player.tech_.vhs) {
              player.qualities = [];
              var e = player.tech_.vhs.representations();
              if (e.length > 0) {
                for (C = 0; C < e.length; C++) {
                  var i = e[C];
                  var t = 'vjs_' + C;
                  var t = {
                    id: t,
                    index: t,
                    label: t,
                    width: i.width,
                    height: i.height,
                    bandwidth: i.bandwidth,
                    bitrate: i.bandwidth,
                    enabled: false,
                  };
                  t.enabled = n.bind(this, C);
                  player.qualities.push(t);
                }
              }
            }
          }
          function n(e, i) {
            if (player.qualities) {
              var t = player.tech_.vhs.representations();
              if (typeof i == 'boolean') {
                player.qualities[e].enabled = i;
                t[e].enabled(i);
              }
              return player.qualities[e].enabled;
            }
            return false;
          }
          function a() {
            s();
            player.on('mediachange', function () {
              if (player.tech_.vhs) {
                var e = player.tech_.vhs;
                var i = e.representations();
                var e = e.playlists.media();
                if (e) {
                  var t = e.attributes.BANDWIDTH;
                  var s = e.attributes.HEIGHT;
                  for (var n = 0; n < i.length; n++) {
                    if (t === i[n].bandwidth) {
                      player.qualityIndex = n;
                      break;
                    }
                    if (s === i[n].height) {
                      player.qualityIndex = n;
                      break;
                    }
                  }
                  player.trigger('qualityChange');
                }
              }
            });
            if (player.qualities.length > 0) {
              Zi();
              Z = player.qualities.length;
              setTimeout(function () {
                player.trigger('mediachange');
              }, 500);
              Pi(player.qualities);
            }
          }
        });
        n = playerEl.querySelector('.vjs-info');
        if (n) {
          playerEl.removeChild(n);
        }
        player.audioInfo = function () {
          if (options.audioInfo && (options.audioInfo.artist || options.audioInfo.title)) {
            return options.audioInfo;
          } else {
            return false;
          }
        };
        Wi();
        Oi();
        if (player.options_.sources.length > 0) {
          player.resetNuevo(false);
          Fi();
          Ki();
        }
        player.on('ratechange', function () {
          var t = player.playbackRate();
          playerEl.querySelectorAll('.vjs-speed').forEach((e) => {
            var i = Number(e.innerHTML.replace('x', ''));
            videojs.dom.removeClass(e, 'vjs-checked');
            if (t === i) {
              videojs.dom.addClass(e, 'vjs-checked');
            }
          });
          if (t === '1') {
            t = I('Normal');
          }
          playerEl.querySelector('.vjs-extend-speed span').innerHTML = t + 'x';
        });
        player.setRate = function (e) {
          if (parseFloat(e) > 0) {
            player.playbackRate(e);
            options.rate = e;
          }
        };
        player.setSource = function (e) {
          player.changeSource(e);
        };
        player.changeSource = function (e) {
          var i = 1;
          if (player.paused()) {
            i = 2;
          }
          $i();
          if (
            videojs.dom.hasClass(playerEl, 'vjs-ad-playing') ||
            videojs.dom.hasClass(playerEl, 'vjs-dai')
          );
          else {
            player.item = e;
            options.chapters = false;
            if (!e);
            else {
              if (typeof e == 'string') {
                e = {
                  sources: [
                    {
                      src: e,
                    },
                  ],
                };
              }
              if (e.src) {
                e = {
                  sources: [e],
                };
              }
              if (e.source) {
                e.sources = [];
                e.sources.push(e.source);
              }
              if (!e.sources);
              else {
                player.pause();
                player.currentTime(0);
                player.changeSrc(e);
                if (i === 1) {
                  player.play();
                }
                player.trigger('sourceChanged');
              }
            }
          }
        };
        player.changeSrc = function (i) {
          if (
            videojs.dom.hasClass(playerEl, 'vjs-ad-playing') ||
            videojs.dom.hasClass(playerEl, 'vjs-dai')
          );
          else {
            player.item = i;
            options.url =
              options.video_id =
              options.infoTitle =
              options.infoDescription =
              options.metatitle =
              options.metasubtitle =
              options.audioInfo =
              options.infoUrl =
              options.video_id =
                null;
            options.title = options.url = options.description = options.slideImage = '';
            if (!i);
            else {
              if (typeof i == 'string') {
                i = {
                  sources: [
                    {
                      src: i,
                    },
                  ],
                };
              }
              if (i.src) {
                i = {
                  sources: [i],
                };
              }
              if (i.source) {
                i.sources = [];
                i.sources.push(i.source);
              }
              if (!i.sources);
              else {
                options.rate = 1;
                player.setRate(1);
                player.trigger('medialoaded');
                if (i.video_id) {
                  options.video_id = i.video_id;
                }
                if (i.audioInfo) {
                  options.audioInfo = i.audioInfo;
                }
                if (i.slideImage) {
                  options.slideImage = i.slideImage;
                }
                if (i.slideWidth) {
                  options.slideWidth = i.slideWidth;
                }
                if (i.slideHeight) {
                  options.slideHeight = i.slideHeight;
                }
                if (i.title) {
                  options.metatitle = options.title = i.title;
                }
                if (i.description) {
                  options.description = i.infoDescription = i.description;
                }
                if (i.embed) {
                  options.embed = i.embed;
                }
                if (i.infoTitle) {
                  options.infoTitle = i.infoTitle;
                }
                if (i.infoDescription) {
                  options.infoDescription = i.infoDescription;
                }
                if (i.infoUrl) {
                  options.infoUrl = i.infoUrl;
                }
                if (i.infoIcon) {
                  options.infoIcon = i.infoIcon;
                }
                if (i.url) {
                  options.url = i.url;
                }
                i.sources.forEach((e) => {
                  if (i.title) {
                    e.title = i.title;
                    e.metaTitle = i.title;
                  }
                  if (i.metatitle) {
                    e.metaTitle = i.metatitle;
                  }
                  if (i.metaTitle) {
                    e.metaTitle = i.metaTitle;
                  }
                  if (i.metasubtitle) {
                    e.metaSubtitle = i.metasubtitle;
                  }
                  if (i.metaSubtitle) {
                    e.metaSubtitle = i.metaSubtitle;
                  }
                  if (i.metaThumbnail) {
                    e.metaThumbnail = i.metaThumbnail;
                  }
                });
                player.captions = null;
                if (i.tracks !== undefined) {
                  var e = i.tracks;
                  e.forEach((e) => {
                    if (e.src !== undefined && e.kind === 'captions') {
                      if (e.default) {
                        e.mode = 'showing';
                      }
                      e.language = e.srclang;
                    }
                  });
                  if (e.length > 0) {
                    player.captions = e;
                  }
                }
                if (i.poster) {
                  player.poster(i.poster);
                }
                setTimeout(function () {
                  options.firstplay = true;
                }, 200);
                options.currentSlide = '';
                player.options_.sources = i.sources;
                e = player.resetNuevo(true, i);
                player.src(e);
                player.trigger('newSource');
                Oi();
                Wi();
                Qi();
                if (i.track !== undefined) {
                  i.tracks = [];
                  i.tracks.push(i.track);
                }
                if (i.tracks !== undefined) {
                  if (i.tracks.length > 0) {
                    player.one('loadeddata', function () {
                      $i();
                      var e = [];
                      for (var i = player.item.tracks, t = 0; t < i.length; t++) {
                        if (i[t].src !== undefined) {
                          if (
                            i[t].default &&
                            (i[t].kind === 'captions' || i[t].kind === 'subtitles')
                          ) {
                            player.currentTrack = i[t].src;
                          }
                          if (i[t].src) {
                            if (i[t].kind === 'metadata') {
                              player.trigger('medialoaded', {
                                xml: i[t].src,
                              });
                            } else {
                              e[t] = player.addRemoteTextTrack(i[t], false);
                              e[t].addEventListener('load', function () {
                                if (this.kind === 'chapters') {
                                  Ki();
                                }
                              });
                            }
                          }
                        }
                      }
                    });
                  }
                }
              }
            }
          }
        };
        player.loadTracks = function (e) {
          $i();
          var i;
          var t = [];
          if (!Array.isArray(e)) {
            (i = [])[0] = e;
          } else {
            i = e;
          }
          for (var s = 0; s < i.length; s++) {
            var n = i[s];
            if (n.kind && n.src) {
              if (n.kind === 'metadata') {
                player.trigger('medialoaded', {
                  xml: n.src,
                });
              } else {
                t[s] = player.addRemoteTextTrack(n, false);
                t[s].addEventListener('load', function () {
                  if (this.kind === 'captions' || this.kind === 'subtitles') {
                    if (this.default) {
                      this.track.mode = 'showing';
                    }
                  }
                  if (this.kind === 'chapters') {
                    Ki();
                  }
                });
              }
            }
          }
        };
        player.newPlaylist = function (e) {
          var i = playerEl.className;
          var t = player.playlistParent.querySelector('.vjs-vlist ul');
          for (var s = t.querySelectorAll('.vjs-item'), n = 0; n < s.length; n++) {
            s[n].onclick = null;
            s[n].ontouchend = null;
            t.removeChild(s[n]);
          }
          t.innerHTML = '';
          for (var a = 0; a < e.length; a++) {
            var o = Xi(e[a], a);
            t.appendChild(o);
          }
          player.playlist.currentItem(0);
          player.pause();
          player.changeSource(e[0]);
          if (i.indexOf('vjs-has-started') > -1) {
            player.one('canplay', function () {
              player.play();
            });
          }
        };
        player.removeFromPlaylist = function (e) {
          if (options.playlist && options.playlistUI) {
            var i = player.playlistParent.querySelector('.vjs-vlist ul');
            var e = i.childNodes[e];
            i.removeChild(e);
            for (var t = 0; t < i.childNodes.length; t++) {
              i.childNodes[t].setAttribute('data-id', t);
            }
          }
        };
        player.addToPlaylist = function (e, i, t) {
          if (options.playlist && options.playlistUI) {
            var s;
            var n = player.playlistParent.querySelector('.vjs-vlist ul');
            var a = n.childNodes.length;
            if (typeof t == 'number' && typeof i == 'string') {
              if (i === 'after') {
                s = Xi(e, t + 1);
                n.insertBefore(s, n.childNodes[t].nextSibling);
              } else if (i === 'before') {
                s = Xi(e, t);
                n.insertBefore(s, n.childNodes[t]);
              }
              for (var o = 0; o < n.childNodes.length; o++) {
                n.childNodes[o].setAttribute('data-id', o);
              }
            } else {
              if (a > 0) {
                s = Xi(e, a - 1);
              } else {
                s = Xi(e, 0);
              }
              if (a > 1 && player.playlistParent === playerEl) {
                n.insertBefore(s, n.childNodes[a - 2].nextSibling);
              } else {
                n.appendChild(s);
              }
            }
          }
        };
        player.on('playlistready', function () {
          if (options.playlistRepeat) {
            player.playlist.repeat(true);
          }
          Yi();
        });
        var be = progressHolderEl.querySelector('.vjs-play-progress');
        progressHolderEl.addEventListener('mousedown', Gi, false);
        progressHolderEl.addEventListener('touchstart', Gi, {
          passive: true,
        });
        if (localStorage && localStorage.volume && player.muted() !== true) {
          if (localStorage.volume > 0) {
            player.volume(localStorage.volume);
          }
        }
        i = false;
        if (
          options.settingsButton &&
          (options.relatedMenu ||
            options.shareMenu ||
            options.rateMenu ||
            options.zoomMenu ||
            options.filtersMenu)
        ) {
          i = true;
          options.menus = true;
          videojs.dom.removeClass(playerEl.querySelector('.vjs-cog-menu-button'), hiddenClassname);
        } else {
          options.menus = false;
          videojs.dom.addClass(playerEl.querySelector('.vjs-cog-menu-button'), 'vjs-abs-hidden');
        }
        if (i) {
          var a = '';
          var ye = false;
          var r = false;
          if (options.shareMenu) {
            a +=
              '<li class="vjs-settings-item vjs-share-button" tabindex="0" aria-label="' +
              I('Open sharing container') +
              '" role="menuitem" aria-disabled="false">' +
              I('Share') +
              '<span><span data-id="share" class="vjs-icon-placeholder vjs-share-icon"></span></span></li>';
          }
          if (options.relatedMenu && options.related.length > 1) {
            a +=
              '<li class="vjs-settings-item vjs-related-button" tabindex="0" aria-label="' +
              I('Open related container') +
              '" role="menuitem" aria-disabled="false">' +
              I('Related') +
              '<span><span data-id="related" class="vjs-icon-placeholder vjs-related-icon"></span></span></li>';
          }
          if (options.zoomMenu) {
            a =
              a +
              '<li class="vjs-settings-item vjs-extend-zoom vjs-menu-forward" tabindex="0" aria-label="' +
              I('Open zoom container') +
              '" role="menuitem" aria-disabled="false">' +
              I('Zoom') +
              '<span class="zoom-label">100%</span></li>';
            r = createElement(
              'div',
              'vjs-submenu vjs-zoom-menu vjs-sub-menu vjs-hidden',
              '<div class="vjs-settings-back vjs-return">' +
                I('Zoom') +
                '</div><div tabindex="0" aria-label="Zoom" class="vjs-zoom-slider" role="slider" aria-live="polite" aria-valuemin="0" aria-valuemax="500" aria-valuetext="0%"><div class="vjs-zoom-back"></div><div class="vjs-zoom-level"></div></div><div role="button" tabindex="0" aria-disabled="false" aria-label="' +
                I('Zoom reset') +
                '" class="vjs-zoom-reset">' +
                I('Reset') +
                '</div>',
            );
          }
          if (options.rateMenu) {
            var a =
              a +
              '<li aria-disabled="false" class="vjs-settings-item vjs-extend-speed vjs-menu-forward" tabindex="0" aria-label="' +
              I('Open media speed menu') +
              '" role="menuitem" aria-disabled="false">' +
              I('Speed') +
              '<span>' +
              I('Normal') +
              '</span></li>';
            var ye = createElement('div', 'vjs-submenu vjs-menu-speed vjs-hidden');
            var _e =
              '<div class="vjs-settings-back">' +
              I('Speed') +
              '</div><ul class="vjs-menu-content vjs-sub-menu">';
            var n = [0.5, 1, 1.25, 1.5, 2];
            if (player.playbackRates().length > 0) {
              n = player.playbackRates();
            }
            player.playbackRates(n);
            n.forEach((e) => {
              var i = 'vjs-speed';
              if (e === 1) {
                i = 'vjs-speed vjs-checked';
              }
              _e +=
                '<li aria-label="' +
                I('Set Speed ') +
                e +
                '" tabindex="0" role="menuitemradio" class="vjs-menu-item ' +
                i +
                '">' +
                e +
                'x</li>';
            });
            ye = createElement('div', 'vjs-submenu vjs-menu-speed vjs-hidden', (_e += '</ul>'));
          }
          var Ce = playerEl.querySelector('.vjs-settings-list');
          if (a !== '') {
            Ce.innerHTML = a + Ce.innerHTML;
            if (ye) {
              g.appendChild(ye);
            }
            if (r) {
              g.appendChild(r);
            }
            videojs.dom.removeClass(M, hiddenClassname);
            function we(e) {
              e = (e = e.target.innerHTML).replace('x', '');
              player.setRate(e);
              N('');
              setTimeout(function () {
                playerEl.querySelector('.vjs-cog-menu-button').focus();
              }, 100);
            }
            setTimeout(function () {
              playerEl.querySelectorAll('.vjs-speed').forEach((e) => {
                var i = false;
                e.addEventListener(
                  'touchstart',
                  function () {
                    i = false;
                  },
                  {
                    passive: true,
                  },
                );
                e.onclick = function (e) {
                  e.preventDefault();
                  e.stopImmediatePropagation();
                  if (i);
                  else {
                    we(e);
                  }
                };
                e.ontouchend = function (e) {
                  e.preventDefault();
                  e.stopImmediatePropagation();
                  if (i);
                  else {
                    we(e);
                  }
                };
                e.addEventListener(
                  'touchmove',
                  function () {
                    i = true;
                  },
                  {
                    passive: true,
                  },
                );
              });
            }, 200);
          }
          if (options.related.length > 1 && options.relatedMenu) {
            var ke = options.related.length;
            var relatedMenuElement = createElement('div');
            console.log('o', relatedMenuElement);
            var i = createElement('p');
            i.innerHTML = I('Related');
            relatedMenuElement.appendChild(i);
            var n =
              '<div role="button" aria-label="Previous related" aria-disabled="false" class="vjs-arrow vjs-arrow-prev vjs-disabled"><div class="vjs-prev">&lang;</div></div><div role="button" aria-label="Next related" aria-disabled="false" class="vjs-arrow vjs-arrow-next"><div class="vjs-next">&rang;</div></div>';
            var xe = createElement('div', 'vjs-related');
            relatedMenuElement.innerHTML = relatedMenuElement.innerHTML + n;
            relatedMenuElement.className = 'vjs-grid vjs-hidden';
            relatedMenuElement.ariaLabel = 'Related dialog';
            relatedMenuElement.ariaHidden = 'true';
            var l = createElement('div', 'vjs-close-btn');
            l.role = 'button';
            l.tabIndex = '0';
            l.ariaDisabled = 'false';
            l.ariaLabel = I('Close related');
            l.innerHTML =
              '<span class="vjs-icon-placeholder" aria-hidden="true" data-id="close"></span>';
            xe.tabindex = '-1';
            relatedMenuElement.onkeydown = function (i) {
              var e = i.which;
              if (e === 9) {
                let e = relatedMenuElement.querySelectorAll('a');
                if (e[e.length - 1] == document.activeElement) {
                  l.focus();
                  i.preventDefault();
                  i.stopPropagation();
                }
              }
              if (e === 27) {
                relatedMenuElement.click();
                playerEl.querySelector('.vjs-cog-menu-button').focus();
                i.preventDefault();
                i.stopPropagation();
              }
            };
            a = playerEl.offsetWidth;
            i = a * 0.8;
            if (J() !== true) {
              i = a * 0.9;
            }
            relatedMenuElement.appendChild(l);
            relatedMenuElement.appendChild(xe);
            playerEl.appendChild(relatedMenuElement);
            var y = relatedMenuElement.querySelector('.vjs-arrow-prev');
            var _ = relatedMenuElement.querySelector('.vjs-arrow-next');
            y.onkeydown =
              _.onkeydown =
              l.onkeydown =
                function (e) {
                  if (e.which === 13) {
                    e.target.click();
                  }
                };
            var n = parseInt(playerEl.querySelector('.vjs-prev').offsetWidth, 10) + 5;
            if (y) {
              y.style.left = parseInt(xe.style.left, 10) - n + 'px';
            }
            if (_) {
              _.style.left = i + parseInt(xe.style.left, 10) + 'px';
            }
            var Te = createElement('div', 'rel-block rel-anim');
            xe.appendChild(Te);
            var Ee = options.related;
            var j = 1;
            for (var C = 0; C < ke; C++) {
              var Le = createElement('div', 'rel-parent');
              var Ie = createElement('div', 'rel-item');
              Le.appendChild(Ie);
              Te.appendChild(Le);
              Ie.innerHTML =
                '<a class="rel-url" target="' +
                options.target +
                '" href="' +
                Ee[C].url +
                '" alt="' +
                Ee[C].title +
                '"><span class="rel-bg" style="background-image:url(' +
                Ee[C].thumb +
                ');"></span><span class="rel-label">' +
                Ee[C].title +
                '</span><i>' +
                Ee[C].duration +
                '</i></a>';
            }
            if (ke < 7 && J()) {
              if (y) {
                videojs.dom.addClass(y, hiddenClassname);
              }
              if (_) {
                videojs.dom.addClass(_, hiddenClassname);
              }
            }
            function Me(e) {
              e.stopPropagation();
              if (!videojs.dom.hasClass(_, 'vjs-disabled')) {
                e = xe.offsetWidth;
                j++;
                videojs.dom.removeClass(_, 'vjs-disabled');
                e = (j - 1) * e;
                Te.style.left = '-' + e + 'px';
                if (j === V) {
                  videojs.dom.addClass(_, 'vjs-disabled');
                }
                videojs.dom.removeClass(y, 'vjs-disabled');
              }
            }
            if (_) {
              _.onclick = function (e) {
                Me(e);
              };
            }
            function Se(e) {
              e.stopPropagation();
              if (!videojs.dom.hasClass(y, 'vjs-disabled')) {
                e = xe.offsetWidth;
                e = (--j - 1) * e;
                playerEl.querySelector('.rel-block').style.left = '-' + e + 'px';
                if (y && _) {
                  if (j === 1) {
                    videojs.dom.addClass(y, 'vjs-disabled');
                  }
                  videojs.dom.removeClass(_, 'vjs-disabled');
                }
              }
            }
            if (y) {
              y.onclick = function (e) {
                Se(e);
              };
            }
            a = playerEl.querySelector('.vjs-related-button');
            a.onclick = a.ontouchend = function (e) {
              e.preventDefault();
              player.related();
              relatedMenuElement.querySelector('.vjs-close-btn').focus();
            };
            l.onkeydown = function (e) {
              if (e.which === 13) {
                relatedMenuElement.click();
                playerEl.querySelector('.vjs-cog-menu-button').focus();
                e.preventDefault();
              }
            };
            relatedMenuElement.onclick = function () {
              videojs.dom.addClass(relatedMenuElement, hiddenClassname);
              player.play();
            };
          }
          if (options.shareMenu) {
            (sharingOverlayElement = createElement(
              'div',
              'vjs-sharing-overlay vjs-hidden',
            )).ariaLabel = 'Sharing Dialog';
            sharingOverlayElement.ariaHidden = 'true';
            n = createElement('div', 'vjs-sharing-container');
            i = createElement('div', 'vjs-sharing-body');
            a = createElement('div', 'vjs-close-btn vjs-share-close');
            a.tabIndex = '0';
            a.ariaLive = 'polite';
            a.ariaDisabled = 'false';
            a.ariaLabel = 'Close share container';
            a.innerHTML =
              '<span class="vjs-icon-placeholder" aria-hidden="true" data-id="close"></span>';
            a.onkeydown = function (e) {
              if (e.which === 13) {
                e.target.click();
              }
            };
            var He = createElement('p');
            He.innerHTML = I('Share');
            He.ariaLive = 'polite';
            He.ariaLabel = 'Share section';
            He.onclick = function (e) {
              e.preventDefault();
              e.stopPropagation();
            };
            sharingOverlayElement.onkeydown = function (e) {
              var i = e.which;
              if (i == 9) {
                var t = sharingOverlayElement.querySelector('.vjs-share-close');
                if (
                  sharingOverlayElement.querySelector('.vjs-linkedin-square') ==
                  document.activeElement
                ) {
                  t.focus();
                  e.preventDefault();
                }
              }
              if (i === 27) {
                sharingOverlayElement.click();
                playerEl.querySelector('.vjs-cog-menu-button').focus();
                e.preventDefault();
              }
            };
            var Be =
              '<div class="vjs-inputs-body"><h2>' +
              I('Link') +
              '</h2><input name="permalink" aria-live="polite" aria-label="Copy video link" type="text" readonly class="perma"></div>';

            if (options.embed) {
              Be +=
                '<div class="vjs-inputs-body"><h2 class="embd">' +
                I('Embed') +
                '</h2><input name="embed code" aria-label="Copy video embed code" aria-live="polite" class="embed-code embd" readonly type="text"></div>';
            }
            Be += '<div class="vjs-inputs-body"><h2>' + I('Social') + '</h2></div>';
            i.innerHTML = Be +=
              '<div class="vjs-share-block"><i title="Facebook" data-id="facebook" class="vjs-share-icon vjs-facebook-square" role="button" aria-live="polite" tabindex="0"><span class="vjs-icon-placeholder" data-id="facebook" aria-hidden="true"></span></i><i title="Twitter" data-id="twitter" class="vjs-share-icon nv vjs-twitter-square" role="button" aria-live="polite" tabindex="0"><span class="vjs-icon-placeholder" data-id="twitter" aria-hidden="true"></span></i><i title="Pinterest" data-id="pinterest" class="vjs-share-icon nv vjs-pinterest-square" role="button" aria-live="polite" tabindex="0"><span class="vjs-icon-placeholder" data-id="pinterest" aria-hidden="true"></span></i><i title="LinkedIn" data-id="linkedin" class="vjs-share-icon nv vjs-linkedin-square" role="button" aria-live="polite" tabindex="0"><span class="vjs-icon-placeholder" data-id="linkedin" aria-hidden="true"></span></i></div>';
            n.appendChild(i);
            sharingOverlayElement.appendChild(He);
            sharingOverlayElement.appendChild(a);
            sharingOverlayElement.appendChild(n);
            var qe = options.url || document.location.href;
            var Ne = false;
            playerEl.appendChild(sharingOverlayElement);
            var Be = playerEl.querySelector('.vjs-share-button');
            Be.onclick = Be.ontouchend = function (e) {
              e.preventDefault();
              e.stopPropagation();
              player.share();
            };
            var De = function (e) {
              e.stopPropagation();
              var i;
              var t = options.title || options.metatitle || document.title;
              var s = encodeURIComponent(qe);
              var t = encodeURIComponent(t);
              var n = e.target.getAttribute('data-id');
              if (!n) {
                n = e.target.parentNode.getAttribute('data-id');
              }
              if (!n) {
                n = e.target.parentNode.parentNode.getAttribute('data-id');
              }
              switch (n) {
                case 'facebook':
                  i = '//www.facebook.com/sharer/sharer.php?u=' + s + '&t=' + t;
                  break;
                case 'twitter':
                  i = '//twitter.com/intent/tweet?url=' + s + '&text=' + t;
                  break;
                case 'pinterest':
                  if (player.poster()) {
                    i =
                      '//pinterest.com/pin/create/button/?media=' +
                      encodeURIComponent(player.poster()) +
                      '&url=' +
                      s +
                      '&description=' +
                      t;
                  }
                  break;
                case 'linkedin':
                  i = '//www.linkedin.com/shareArticle?mini=true&url=' + s + '&ttitle=' + t;
              }
              if (i !== '') {
                window.open(
                  i,
                  'Share',
                  'height=450,width=550,modal=yes,alwaysRaised=yes,personalbar=0,toolbar=0,scrollbars=0,resizable=0',
                );
              }
            };
            for (var Pe = playerEl.querySelectorAll('.vjs-share-icon'), C = 0; C < Pe.length; C++) {
              Pe[C].onclick = Pe[C].onkeydown = function (e) {
                if (e.type === 'keydown') {
                  if (e.which === 13) {
                    De(e);
                  }
                } else {
                  De(e);
                }
              };
            }
            if (sharingOverlayElement.querySelector('.embed-code')) {
              sharingOverlayElement.querySelector('.embed-code').onclick = function (e) {
                e.stopImmediatePropagation();
                this.select();
              };
            }

            sharingOverlayElement.querySelector('.perma').onclick = function (e) {
              e.stopImmediatePropagation();
              this.select();
            };
            sharingOverlayElement.onclick = function () {
              videojs.dom.addClass(sharingOverlayElement, hiddenClassname);
              if (Ne !== true) {
                player.play();
              }
            };
          }
          if (options.zoomMenu) {
            var Ae;
            var ze;
            var Re;
            var w = player.$('.vjs-tech');
            playerEl.querySelector('.vjs-poster').style.pointerEvents = 'none';
            if (options.zoomInfo) {
              var We = createElement('div', 'vjs-zoom-parent vjs-hidden');
              var i = createElement('div', 'vjs-reset-zoom');
              i.innerHTML = '100%';
              We.appendChild(i);
              He = createElement(
                'div',
                'vjs-reset-center',
                '<span data-id="center" aria-hidden="true" class="vjs-icon-placeholder"></span>',
              );
              a = createElement(
                'div',
                'vjs-reset-cancel',
                '<span data-id="cancel" aria-hidden="true" class="vjs-icon-placeholder"></span>',
              );
              n = createElement(
                'div',
                'vjs-reset-info',
                '<span data-id="help" aria-hidden="true" class="vjs-icon-placeholder"></span>',
              );
              We.appendChild(He);
              We.appendChild(a);
              We.appendChild(n);
              var Be = I('Drag zoomed area using your mouse.');
              var i = I('Use ZOOM slider or mouse wheel to ZOOM in video.');
              var Oe = I('ZOOM HELP');
              var Fe = createElement('div', 'vjs-zoom-help vjs-hidden');
              if (options.zoomWheel) {
                Fe.innerHTML =
                  '<div class="zoom-close">x</div><div>' +
                  Oe +
                  '</div>' +
                  i +
                  '<div>' +
                  Be +
                  '</div>';
              } else {
                Fe.innerHTML =
                  '<div class="zoom-close">x</div><div>' + Oe + '</div>' + Be + '</div>';
              }
              We.appendChild(n);
              n.onclick = function (e) {
                $e(e);
              };
              function $e(e) {
                e.preventDefault();
                if (videojs.dom.hasClass(Fe, hiddenClassname)) {
                  videojs.dom.removeClass(Fe, hiddenClassname);
                }
              }
              Fe.querySelector('.zoom-close').onclick = function () {
                videojs.dom.addClass(Fe, hiddenClassname);
              };
              playerEl.appendChild(Fe);
              a.onmouseup = function () {
                st();
              };
              He.onmouseup = function (e) {
                tt(e);
              };
              playerEl.appendChild(We);
            }
            i = playerEl.querySelector('.vjs-zoom-reset');
            if (i) {
              i.onmouseup = function () {
                st();
              };
            }
            if (!playerEl.hasAttribute('tabIndex')) {
              playerEl.tabIndex = '-1';
            }
            if (options.zoomWheel && videojs.browser.TOUCH_ENABLED !== true) {
              w.addEventListener('mousewheel', Ue, {
                passive: false,
              });
              w.addEventListener('DOMMouseScroll', Ue, {
                passive: false,
              });
              function Ue(e) {
                e.preventDefault();
                e.stopImmediatePropagation();
                e = Math.max(-1, Math.min(1, e.wheelDelta || -e.detail));
                if ((d = (d * 100 + e * 20) / 100) < 1) {
                  d = 1;
                }
                if (d > 5) {
                  d = 5;
                }
                if (d === 1) {
                  st();
                  if (options.zoomInfo) {
                    videojs.dom.addClass(We, hiddenClassname);
                  }
                } else {
                  if (options.zoomInfo) {
                    videojs.dom.removeClass(We, hiddenClassname);
                  }
                  ot(w, d);
                }
                e = playerEl.querySelector('.vjs-zoom-menu');
                if (videojs.dom.hasClass(e, hiddenClassname) !== true) {
                  e = ((d - 1) / 4) * 100;
                  playerEl.querySelector('.vjs-zoom-level').style.height = e + '%';
                }
                Ii(d * 100);
                return false;
              }
            }
            function Ke(e) {
              if (d > 1) {
                e.preventDefault();
                e.stopPropagation();
                Ae = true;
                Re = [w.offsetLeft - e.clientX, w.offsetTop - e.clientY];
                document.addEventListener('mouseup', Ye, true);
                document.addEventListener('mousemove', Xe, true);
              }
            }
            function Xe(e) {
              e.preventDefault();
              if (Ae) {
                var e =
                  (ze = {
                    x: e.clientX,
                    y: e.clientY,
                  }).x + Re[0];
                var i = ze.y + Re[1];
                var t = (playerEl.offsetWidth / 2) * (d - 1);
                var s = (playerEl.offsetHeight / 2) * (d - 1);
                if (t < e) {
                  e = t;
                }
                if (e < t * -1) {
                  e = t * -1;
                }
                if (s < i) {
                  i = s;
                }
                if (i < s * -1) {
                  i = s * -1;
                }
                w.style.left = e + 'px';
                w.style.top = i + 'px';
              }
            }
            function Ye() {
              Ae = false;
              document.removeEventListener('mouseup', Ye, true);
              document.removeEventListener('mousemove', Xe, true);
            }
            w.onmousedown = function (e) {
              Ke(e);
            };
            var Qe = playerEl.querySelector('.vjs-zoom-slider');
            function Ve(e, i) {
              w.style.pointerEvents = 'auto';
              var t = playerEl.querySelector('.vjs-zoom-parent');
              var s = e.pageY;
              var n = i.offsetHeight;
              const a = i.getBoundingClientRect();
              let o = a.top + window.scrollY;
              i = s - o;
              if (n < i) {
                i = n;
              }
              if (i < 0) {
                i = 0;
              }
              s = parseInt(100 - (i / n) * 100, 10);
              if (s < 0) {
                s = 0;
              }
              if (s > 100) {
                s = 100;
              }
              try {
                playerEl.querySelector('.vjs-zoom-level').style.height = s + '%';
              } catch (e) {}
              try {
                playerEl.querySelector('.zoom-thumb').style.height = s + '%';
              } catch (e) {}
              i = 1 + (s * 4) / 100;
              d = i;
              Qe.setAttribute('aria-valuetext', parseInt(i * 100) + '%');
              ot(player.$('.vjs-tech'), i);
              Ii(i * 100);
              if (i > 1) {
                videojs.options.blockKeys = true;
                videojs.dom.removeClass(t, hiddenClassname);
              } else {
                st();
                videojs.options.blockKeys = false;
                videojs.dom.addClass(t, hiddenClassname);
              }
            }
            Qe.onclick = function (e) {
              e.stopImmediatePropagation();
            };
            Qe.onmousedown = function (e) {
              Ze(e);
            };
            function Ze(e) {
              e.stopImmediatePropagation();
              var i = Qe;
              Ve(e, i);
              document.addEventListener('mousemove', t, false);
              document.addEventListener('mouseup', s, false);
              function t(e) {
                Ve(e, i);
              }
              function s() {
                document.removeEventListener('mouseup', s);
                document.removeEventListener('mousemove', t);
              }
            }
          }
          var Ge = playerEl.querySelector('.vjs-extend-speed');
          if (Ge) {
            var Je = playerEl.querySelector('.vjs-menu-speed');
            var ei = Je.querySelector('.vjs-menu-content');
            ei.onkeydown = function (e) {
              let i = e.which;
              if (i === 40 || i === 38 || i === 27 || i === 37 || i === 13 || i === 9) {
                e.preventDefault();
                e.stopImmediatePropagation();
                if (i === 40) {
                  hi(ei);
                }
                if (i === 38) {
                  mi(ei);
                }
                if (i === 27 || i === 37) {
                  Je.querySelector('.vjs-settings-back').click();
                }
                setTimeout(function () {
                  Ge.focus();
                }, 100);
                if (i === 13) {
                  e = pi(ei);
                  ei.children[e].click();
                  setTimeout(function () {
                    playerEl.querySelector('.vjs-cog-menu-button').focus();
                  }, 100);
                }
                if (i === 9) {
                  playerEl.querySelector('.vjs-cog-menu-button').click();
                  playerEl.querySelector('.vjs-cog-menu-button').focus();
                }
              }
            };
          }
          var ii = playerEl.querySelector('.vjs-zoom-menu');
          if (ii) {
            let e = ii.querySelector('.vjs-zoom-slider');
            let i = r.querySelector('.vjs-zoom-reset');
            ii.onkeydown = function (s) {
              if (s.which === 27 || s.which === 37) {
                s.preventDefault();
                s.stopImmediatePropagation();
                ii.querySelector('.vjs-settings-back').click();
                setTimeout(function () {
                  playerEl.querySelector('.vjs-extend-zoom').focus();
                }, 100);
              }
              if (s.which === 40 || (s.which === 38 && i !== document.activeElement)) {
                let e = playerEl.querySelector('.vjs-zoom-level');
                let i = playerEl.querySelector('.vjs-zoom-slider');
                let t = e.style.height;
                if (!t) {
                  t = 0;
                } else {
                  t = parseInt(t);
                }
                if (s.which === 38) {
                  if ((t += 5) > 99) {
                    t = 100;
                  }
                } else if (s.which === 40) {
                  if ((t -= 5) < 0) {
                    t = 0;
                  }
                }
                e.style.height = t + '%';
                var n = 1 + (t * 4) / 100;
                d = n;
                i.setAttribute('aria-valuetext', n * 100 + '%');
                ot(player.$('.vjs-tech'), n);
                Ii(n * 100);
                var a = playerEl.querySelector('.vjs-zoom-parent');
                if (n > 1) {
                  videojs.options.blockKeys = true;
                  videojs.dom.removeClass(a, hiddenClassname);
                } else {
                  st();
                  videojs.options.blockKeys = false;
                  videojs.dom.addClass(a, hiddenClassname);
                }
              }
              if (s.which === 38 && e !== document.activeElement) {
                e.focus();
              }
              if (s.which == 9) {
                if (i == document.activeElement) {
                  playerEl.querySelector('.vjs-cog-menu-button').click();
                  playerEl.querySelector('.vjs-cog-menu-button').focus();
                } else {
                  i.focus();
                }
              }
              s.preventDefault();
              s.stopImmediatePropagation();
            };
            i.onkeydown = function (e) {
              if (e.which === 13) {
                e.preventDefault();
                st();
                i.click();
                setTimeout(function () {
                  playerEl.querySelector('.vjs-cog-menu-button').focus();
                }, 100);
              }
            };
          }
          Si();
          Mi();
          Bi();
          player.trigger('menusReady');
        }
        var ti;
        var si;
        var ni = false;
        player.on('timeupdate', function () {
          if (
            videojs.dom.hasClass(playerEl, 'vjs-ad-playing') ||
            videojs.dom.hasClass(playerEl, 'vjs-dai')
          ) {
            return false;
          }
          var e = player.duration();
          if (e === 0 || e === Infinity);
          else {
            var i = player.video_id();
            var t = player.currentTime();
            if (
              options.limit &&
              (options.limiturl || options.url !== '') &&
              parseInt(player.duration(), 10) > 0
            ) {
              if (options.startTime) {
                if (Number(options.startTime) > 0 && Number(options.limit) > 0) {
                  options.limit = Number(options.startTime) + Number(options.limit);
                }
              }
              if (t > options.limit) {
                player.pause();
                if (ni !== true) {
                  if (!options.limiturl) {
                    options.limiturl = options.url;
                  }
                  ni = true;
                  var s = createElement('div', 'vjs-limit-overlay');
                  var n = createElement('a', 'vjs-limit');
                  n.href = options.limiturl;
                  n.target = options.target;
                  n.style.textDecoration = 'none';
                  s.appendChild(n);
                  if (options.limitimage) {
                    createElement('img').src = options.limitimage;
                    n.innerHTML = '<img src="' + options.limitimage + '" />';
                  } else {
                    var a = createElement('span');
                    a.innerHTML = options.limitmessage + '<span>' + options.limiturl + '</span>';
                    n.appendChild(a);
                  }
                  playerEl.appendChild(s);
                }
              }
            }
            if (i === null);
            else if (options.resume && i) {
              t = parseFloat(t);
              n = String('vjs_resume-' + i);
              if (t > 5 && t < e - 20) {
                at(n, t);
              }
            }
          }
        });
        player.on('volumechange', function () {
          if (player.volume() > 0) {
            at('volume', player.volume());
          }
        });
        player.on('seeked', function () {
          if (
            videojs.dom.hasClass(playerEl, 'vjs-ad-playing') ||
            videojs.dom.hasClass(playerEl, 'vjs-dai')
          ) {
            return false;
          }
        });
        player.on('ended', function () {
          if (
            videojs.dom.hasClass(playerEl, 'vjs-ad-playing') ||
            videojs.dom.hasClass(playerEl, 'vjs-dai') ||
            videojs.dom.hasClass(playerEl, 'vjs-up-next')
          );
          else {
            var e = true;
            if (options.playlist) {
              e = false;
              if (
                player.playlist.currentIndex() === player.playlist.lastIndex() &&
                player.playlist.repeat() !== true
              ) {
                e = true;
              }
            }
            if (e) {
              e = player.video_id();
              if (options.resume && e !== null && e !== undefined) {
                try {
                  localStorage.removeItem(String('vjs_resume-' + e));
                } catch (e) {}
              }
              if (options.customEnd) {
                var i = createElement('div', 'vjs-cend');
                var e = createElement('div', 'cend-inner');
                i.appendChild(e);
                e.innerHTML = options.customEnd;
                videojs.dom.addClass(ne.el_, hiddenClassname);
                player.el().appendChild(i);
                player.one('play', function () {
                  player.el().removeChild(i);
                });
              } else if (options.endAction !== '') {
                if (options.settingsButton && options.endAction === 'share' && options.shareMenu) {
                  player.share();
                } else if (
                  options.settingsButton &&
                  options.endAction === 'related' &&
                  options.relatedMenu &&
                  relatedMenuElement
                ) {
                  player.related();
                }
              } else {
                videojs.dom.removeClass(ne.el_, hiddenClassname);
              }
            }
          }
        });
        player.on('playing', function () {
          if (
            videojs.dom.hasClass(playerEl, 'vjs-ad-playing') ||
            videojs.dom.hasClass(playerEl, 'vjs-dai')
          ) {
            return false;
          }
          if (relatedMenuElement) {
            setVisibility(relatedMenuElement, false);
          }
          if (sharingOverlayElement) {
            setVisibility(sharingOverlayElement, false);
          }
          videojs.dom.removeClass(loadingSpinnerEl, hiddenClassname);
          videojs.dom.removeClass(loadingSpinnerEl, 'vjs-abs-hidden');
          if (
            (player.remoteTextTracks ? player.remoteTextTracks() : []).length < 1 &&
            videojs.browser.IS_IOS
          ) {
            videojs.dom.addClass(playerEl.querySelector('.vjs-subs-caps-button'), hiddenClassname);
          }
        });
        player.on('userinactive', function () {
          player.textTrackSettings.updateDisplay();
        });
        player.on('useractive', function () {
          player.textTrackSettings.updateDisplay();
        });
        ti = function () {
          clearTimeout(si);
          si = setTimeout(function () {
            player.userActive(false);
          }, 100);
        };
        player.on('mouseleave', function () {
          ti();
        });
        if ((playerTechEl = player.$('.vjs-tech')).autoplay) {
          function ai() {
            if (player.paused()) {
              player.muted(true);
              if (options.automuteButton) {
                it();
              }
            }
          }
          setTimeout(ai, 300);
        }
        player.on('play', function () {
          var e = playerEl.querySelector('.vjs-picture-in-picture-control');
          if (options.pipButton !== true && e) {
            e.parentNode.removeChild(e);
          }
          if (options.singlePlay) {
            var i = document.querySelectorAll('video');
            if (i.length > 1) {
              for (var t = 0; t < i.length; t++) {
                var s = player.$('.vjs-tech');
                if (i[t] !== s) {
                  i[t].pause();
                }
              }
            }
          }
          options.currentSlide = '';
          Qi();
          loadingSpinnerEl.style.opacity = 1;
          if (ee);
          else {
            ki();
            ee = true;
          }
        });
        if (options.mirrorButton) {
          player.controlBar.mirrorButton = player.controlBar.addChild('button', {
            el: videojs.dom.createEl(
              'button',
              {
                text: 'Mirror view',
                className: 'vjs-mirror-button vjs-control vjs-button',
              },
              {
                role: 'button',
                'aria-live': 'polite',
                'aria-disabled': 'false',
              },
            ),
          });
          player.controlBar.mirrorButton.el_.innerHTML = B('mirror1', I('Mirror View'));
          player.controlBar.el_.insertBefore(player.controlBar.mirrorButton.el_, t.el_);
          function oi(e) {
            var i = 'rotateY(0)';
            if (videojs.dom.hasClass(e.target, 'vjs-mirrored')) {
              videojs.dom.removeClass(e.target, 'vjs-mirrored');
              H(e.target, 'mirror1');
            } else {
              videojs.dom.addClass(e.target, 'vjs-mirrored');
              i = 'rotateY(180deg)';
              H(e.target, 'mirror2');
            }
            e = player.$('.vjs-tech');
            e.style.transform = i;
            e.style.webkitTransform = i;
          }
          player.controlBar.mirrorButton.el_.onclick =
            player.controlBar.mirrorButton.el_.ontouchend = function (e) {
              e.preventDefault();
              e.stopPropagation();
              oi(e);
            };
        }
        if (!videojs.nuevo && !options.mapp) {
          playerEl.parentNode.removeChild(playerEl);
        }
        if (videojs.svgIcons) {
          function li() {
            playerEl.querySelectorAll('.vjs-icon-placeholder').forEach(function (i) {
              if (i.getAttribute('data-id')) {
                let e = i.getAttribute('data-id').trim();
                const t = 'http://www.w3.org/2000/svg';
                const s = document.createElementNS(t, 'svg');
                s.setAttributeNS(null, 'viewBox', '0 0 32 32');
                const n = document.createElementNS(t, 'use');
                s.appendChild(n);
                n.setAttributeNS(null, 'href', '#vjs-icon-' + e);
                i.appendChild(s);
                videojs.dom.addClass(i, 'vjs-svg-icon');
              }
            });
          }
          let t = {
            'vjs-big-play-button': 'big-play',
            'vjs-picture-in-picture-control': 'pip',
            'vjs-fullscreen-control': 'fullscreen',
            'vjs-play-control': 'play',
            'vjs-chapters-button': 'chapters',
            'vjs-descriptions-button': 'descriptions',
            'vjs-subs-caps-button': 'captions',
            'vjs-audio-button': 'audio',
          };
          for (var ri in t) {
            if (t.hasOwnProperty(ri)) {
              let i = playerEl.querySelector('.' + ri);
              if (i) {
                let e = i.querySelector('.vjs-icon-placeholder');
                if (e) {
                  e.setAttribute('data-id', t[ri]);
                }
              }
            }
          }
          di(true);
          li();
          player.on('pause', function () {
            Date.now();
            H(controlBarEl.querySelector('.vjs-play-control'), 'play');
            H(playerEl.querySelector('.vjs-b-p-b'), 'play');
          });
          player.on('play', function () {
            H(controlBarEl.querySelector('.vjs-play-control'), 'pause');
            H(playerEl.querySelector('.vjs-b-p-b'), 'pause');
          });
          player.on('ended', function () {
            H(controlBarEl.querySelector('.vjs-play-control'), 'replay');
          });
          player.$('.vjs-tech').addEventListener('enterpictureinpicture', () => {
            H(controlBarEl.querySelector('.vjs-picture-in-picture-control'), 'pip-full');
          });
          player.$('.vjs-tech').addEventListener('leavepictureinpicture', () => {
            H(controlBarEl.querySelector('.vjs-picture-in-picture-control'), 'pip');
          });
          player.on('volumechange', function () {
            di();
          });
          player.on('fullscreenchange', function (e) {
            let i = controlBarEl.querySelector('.vjs-fullscreen-control');
            if (player.isFullscreen()) {
              H(i, 'fullscreen-back');
            } else {
              H(i, 'fullscreen');
            }
          });
          function di(e) {
            let i = player.volume();
            let t = controlBarEl.querySelector('.vjs-mute-control');
            var s = 'mute';
            if (player.muted()) {
              s = 'mute';
            } else {
              if (i > 0) {
                s = 'volume1';
              }
              if (i > 0.25) {
                s = 'volume2';
              }
              if (i > 0.75) {
                s = 'volume3';
              }
            }
            if (e) {
              t.querySelector('.vjs-icon-placeholder').setAttribute('data-id', s);
            } else {
              H(t, s);
            }
          }
        }
        playerEl.style.visibility = 'visible';
        player.trigger('nuevoReady');
      }
    }
    function H(e, i) {
      if (e) {
        e = e.querySelector('.vjs-svg-icon use');
        if (e) {
          e.setAttribute('href', '#vjs-icon-' + i);
        }
      }
    }
    function vi(e) {
      return e.touches[0].pageX || null;
    }
    function fi(e) {
      if (e.pointerType !== 'mouse');
      else {
        isDesktop = true;
        playerEl.classList.remove(K);
        playerEl.classList.remove(U);
        ui(false);
      }
    }
    function ci() {
      playerEl.classList.remove('vjs-has-mouse');
      ui(true);
    }
    function ui(e) {
      var i = playerEl.querySelector('.vjs-rewind-control');
      var t = playerEl.querySelector('.vjs-forward-control');
      var s = playerEl.querySelector('.vjs-extend-zoom');
      var n = playerEl.querySelector('.vjs-related');
      var a = playerEl.querySelector('.vjs-grid');
      var o = null;
      var l = null;
      if (a) {
        o = a.querySelector('.vjs-arrow-prev');
        l = a.querySelector('.vjs-arrow-next');
      }
      if (e) {
        playerEl.classList.remove('vjs-has-mouse');
        if (i) {
          if (options.touchRewindForward && options.touchControls) {
            videojs.dom.addClass(i, hiddenClassname);
          } else {
            i.setAttribute('style', 'display:block!important');
          }
        }
        if (t) {
          if (options.touchRewindForward && options.touchControls) {
            videojs.dom.addClass(t, hiddenClassname);
          } else {
            t.setAttribute('style', 'display:block!important');
          }
        }
        if (s) {
          videojs.dom.addClass(s, hiddenClassname);
        }
        if (n) {
          videojs.dom.addClass(n, 'vjs-scroll');
        }
        if (o) {
          videojs.dom.addClass(o, hiddenClassname);
        }
        if (l) {
          videojs.dom.addClass(l, hiddenClassname);
        }
      } else {
        playerEl.classList.add('vjs-has-mouse');
        if (i) {
          videojs.dom.removeClass(i, hiddenClassname);
        }
        if (t) {
          videojs.dom.removeClass(t, hiddenClassname);
        }
        if (s) {
          videojs.dom.removeClass(s, hiddenClassname);
        }
        if (n) {
          videojs.dom.removeClass(n, hiddenClassname);
          videojs.dom.removeClass(n, 'vjs-scroll');
        }
        if (o) {
          videojs.dom.removeClass(o, hiddenClassname);
        }
        if (l) {
          videojs.dom.removeClass(l, hiddenClassname);
        }
      }
    }
    function pi(e) {
      var i = document.activeElement;
      for (var t = 0; t < e.children.length; t++) {
        if (e.children[t] === i) {
          return t;
        }
      }
      return 0;
    }
    function hi(e) {
      let i = pi(e);
      if (i < e.children.length) {
        for (var t = i + 1; t < e.children.length; t++) {
          if (e.children[t]) {
            if (e.children[t].offsetParent) {
              try {
                e.children[t].focus();
              } catch (e) {}
              break;
            }
          }
        }
      }
    }
    function mi(e) {
      let i = pi(e);
      if (i > 0) {
        for (var t = i - 1; t < e.children.length; t--) {
          if (e.children[t]) {
            if (e.children[t].offsetParent) {
              try {
                e.children[t].focus();
              } catch (e) {}
              break;
            }
          }
        }
      }
    }
    function B(e, i) {
      return (
        '<span class="vjs-icon-placeholder" aria-hidden="true" data-id="' +
        e +
        '"></span><span class="vjs-control-text" aria-live="polite">' +
        i +
        '</span>'
      );
    }
    function ji() {
      if (playerEl.className.includes(U)) {
        gi();
      } else if (document.pictureInPictureElement);
      else {
        videojs.dom.removeClass(playerEl, K);
        videojs.dom.addClass(playerEl, U);
        videojs.dom.removeClass(playerEl, 'vjs-user-inactive');
        videojs.dom.addClass(playerEl, 'vjs-user-active');
        N('');
        bi();
      }
    }
    function gi() {
      clearTimeout(player.touchtimer);
      videojs.dom.removeClass(playerEl, U);
      videojs.dom.addClass(playerEl, K);
      videojs.dom.removeClass(playerEl, 'vjs-user-active');
      videojs.dom.addClass(playerEl, 'vjs-user-inactive');
    }
    function bi() {
      clearTimeout(player.touchtimer);
      player.userActive(false);
      player.touchtimer = setTimeout(gi, se);
    }
    function yi(e) {
      player.options_.inactivityTimeout = se;
      ji();
    }
    function setVisibility(element, isVisible) {
      if (!element) {
        return;
      }

      if (isVisible) {
        videojs.dom.removeClass(element, hiddenClassname);
        element.ariaHidden = 'false';
      } else {
        videojs.dom.addClass(element, hiddenClassname);
        element.ariaHidden = 'true';
      }
    }
    function _i(e) {
      var i = 'vjs-rewind-control';
      if (e) {
        i = 'vjs-rewind-control vjs-rewind-first';
      }
      i = player.controlBar.addChild('button', {
        el: videojs.dom.createEl(
          'button',
          {
            className: i + ' vjs-control vjs-button vjs-rewind-' + options.rewindforward,
          },
          {
            title: I('Rewind'),
            type: 'button',
            'aria-disabled': 'false',
          },
        ),
      });
      i.el_.innerHTML = B('rewind' + options.rewindforward, I('Rewind'));
      if (e) {
        if (options.skin === 'party') {
          controlBarEl.insertBefore(
            i.el_,
            player.controlBar.getChild('playToggle').el_.nextSibling,
          );
        } else {
          controlBarEl.insertBefore(i.el_, player.controlBar.getChild('playToggle').el_);
        }
      } else {
        player.controlBar.el_.insertBefore(
          i.el_,
          player.controlBar.getChild('playToggle').el_.nextSibling,
        );
      }
      i.el_.onclick = i.el_.ontouchend = function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        player.rewind();
      };
    }
    function N(e) {
      if (player.isDisposed() || isDisposed) {
        return;
      }

      if (!e) {
        e = playerEl.querySelector('.vjs-play-control');
      }
      if (videojs.dom.hasClass(e, 'vjs-cast')) {
        return false;
      }
      e = e.className;
      if (typeof e == 'string' || e instanceof String) {
        if (e.indexOf('vjs-settings-item') > -1);
        else {
          if (e.indexOf('vjs-quality-button') < 0) {
            var i = S.querySelector('.vjs-menu');
            if (videojs.dom.hasClass(i, 'vjs-lock-showing')) {
              videojs.dom.removeClass(i, 'vjs-lock-showing');
            }
          }
          if (e.indexOf('vjs-chapters-button') < 0) {
            player.controlBar.getChild('chaptersButton').unpressButton();
          }
          if (e.indexOf('vjs-descriptions-button') < 0) {
            player.controlBar.getChild('descriptionsButton').unpressButton();
          }
          if (e.indexOf('vjs-subs-caps-button') < 0) {
            player.controlBar.getChild('subsCapsButton').unpressButton();
          }
          if (e.indexOf('vjs-audio-button') < 0) {
            player.controlBar.getChild('audioTrackButton').unpressButton();
          }
          if (e.indexOf('vjs-cog-button') < 0 && e.indexOf('vjs-extend-speed') < 0) {
            Ci();
            videojs.dom.removeClass(M, 'vjs-cog-active');
          }
        }
      }
    }
    function Ci() {
      var e = b;
      for (var i = playerEl.querySelectorAll('.vjs-submenu'), t = 0; t < i.length; t++) {
        videojs.dom.addClass(i[t], hiddenClassname);
        if (i[t] === e) {
          videojs.dom.removeClass(e, hiddenClassname);
        }
      }
      videojs.dom.removeClass(c, 'vjs-lock-showing');
      videojs.dom.addClass(c, hiddenClassname);
      M.ariaExpanded = 'false';
    }
    function wi(e) {
      if (e.title) {
        e.onmouseover = function () {
          e.tmptitle = e.title;
          e.title = '';
        };
        e.onmouseleave = function () {
          e.title = e.tmptitle;
        };
      }
    }
    function ki() {
      var e = player.el_.offsetWidth;
      ['vjs-1600', 'vjs-1080', 'vjs-920', 'vjs-600', 'vjs-640', 'vjs-480', 'vjs-360'].forEach(
        (e) => {
          videojs.dom.removeClass(playerEl, e);
        },
      );
      if (e < 360) {
        videojs.dom.addClass(playerEl, 'vjs-360');
        videojs.dom.addClass(playerEl, 'vjs-480');
      } else if (e < 480) {
        videojs.dom.addClass(playerEl, 'vjs-480');
      } else if (e < 640) {
        videojs.dom.addClass(playerEl, 'vjs-640');
        if (e < 600) {
          videojs.dom.addClass(playerEl, 'vjs-600');
        }
      } else if (e < 920) {
        videojs.dom.addClass(playerEl, 'vjs-920');
      } else if (e < 1080) {
        videojs.dom.addClass(playerEl, 'vjs-920');
        videojs.dom.addClass(playerEl, 'vjs-1080');
      } else {
        videojs.dom.addClass(playerEl, 'vjs-920');
        videojs.dom.addClass(playerEl, 'vjs-1080');
        videojs.dom.addClass(playerEl, 'vjs-1600');
      }
    }
    function xi() {
      ki();
      Hi();
      Bi();
      N('');
    }
    function Ti(e) {
      player.options_.innactivityTimeout = te;
      e.stopImmediatePropagation();
      N(e.target);
    }
    function Ei(e) {
      if (!playerEl.contains(e.target)) {
        gi();
      }
    }
    function Li() {
      var e = 0;
      var i = playerEl.querySelectorAll('.item-quality');
      if (Array.isArray(i)) {
        i.forEach((e) => {
          e.onclick = null;
        });
      }
      for (var t = playerEl.querySelectorAll('.item-quality'), e = 0; e < t.length; e++) {
        t[e].parentNode.removeChild(t[e]);
      }
      i = playerEl.querySelector('.vjs-extend-quality');
      if (i) {
        videojs.dom.addClass(i, hiddenClassname);
      }
      S.querySelector('.vjs-menu .vjs-menu-content').innerHTML = '';
      videojs.dom.addClass(S, hiddenClassname);
    }
    function Ii(e) {
      try {
        playerEl.querySelector('.vjs-reset-zoom').innerHTML = parseInt(e, 10) + '%';
        playerEl.querySelector('.zoom-label').innerHTML = parseInt(e, 10) + '%';
      } catch (e) {}
    }
    function Mi() {
      var s = playerEl.querySelector('.vjs-menu-speed');
      var n = playerEl.querySelector('.vjs-zoom-menu');
      var a = playerEl.querySelector('.vjs-menu-quality');
      var i = playerEl.querySelector('.vjs-settings-list');
      function t(e, i, t) {
        videojs.dom.addClass(b, hiddenClassname);
        videojs.dom.removeClass(i, hiddenClassname);
        setTimeout(function () {
          if (s) {
            if (s === i) {
              s.querySelector('.vjs-menu-content').children[0].focus();
            }
          }
          if (n) {
            if (n !== i) {
              videojs.dom.addClass(n, hiddenClassname);
            } else {
              n.querySelector('.vjs-zoom-slider').focus();
            }
          }
          if (a) {
            if (a !== i) {
              videojs.dom.addClass(a, hiddenClassname);
            }
          }
          g.style.width = v[t].width + 'px';
          g.style.height = v[t].height + 'px';
        }, 10);
      }
      function o(e) {
        if (n) {
          videojs.dom.addClass(n, hiddenClassname);
        }
        if (s) {
          videojs.dom.addClass(s, hiddenClassname);
        }
        if (a) {
          videojs.dom.addClass(a, hiddenClassname);
        }
        videojs.dom.removeClass(b, hiddenClassname);
        g.style.width = v.cogMenu.width + 'px';
        g.style.height = v.cogMenu.height + 'px';
        Ii(d * 100);
        setTimeout(function () {
          i.children[0].focus();
        }, 100);
      }
      if (s) {
        var e = playerEl.querySelector('.vjs-extend-speed');
        e.onclick = e.ontouchend = function (e) {
          e.preventDefault();
          e.stopImmediatePropagation();
          t(e, s, 'speedMenu');
        };
        var e = s.querySelector('.vjs-settings-back');
        e.onclick = e.ontouchend = function (e) {
          e.preventDefault();
          e.stopImmediatePropagation();
          o(e);
        };
      }
      if (n) {
        e = playerEl.querySelector('.vjs-extend-zoom');
        e.onclick = e.ontouchend = function (e) {
          e.stopPropagation();
          t(e, n, 'zoomMenu');
        };
        n.querySelector('.vjs-settings-back').onclick = function (e) {
          e.preventDefault();
          e.stopPropagation();
          o(e);
        };
      }
      function l(e) {
        if (playerEl.querySelector('.vjs-tech-chromecast'));
        else {
          Bi();
          if (n) {
            videojs.dom.addClass(n, hiddenClassname);
          }
          if (s) {
            videojs.dom.addClass(s, hiddenClassname);
          }
          if (videojs.dom.hasClass(c, 'vjs-lock-showing') !== true) {
            e.target.ariaExpanded = 'true';
            N(e.target);
            videojs.dom.addClass(c, 'vjs-lock-showing');
            M.querySelector('.vjs-control-text').style.opacity = 0;
            videojs.dom.addClass(e.target, 'vjs-cog-active');
            videojs.dom.removeClass(c, hiddenClassname);
            videojs.dom.removeClass(b, hiddenClassname);
            g.style.width = v.cogMenu.width + 'px';
            g.style.height = v.cogMenu.height + 'px';
            Ii(d * 100);
            i.children[0].focus();
            r();
          } else {
            Ci();
            videojs.dom.removeClass(e.target, 'vjs-cog-active');
          }
        }
      }
      function r() {
        for (var e = playerEl.querySelectorAll('.vjs-settings-list li'), i = 0; i < e.length; i++) {
          e[i].addEventListener('keydown', function (e) {
            var i = e.which;
            if (i === 40 || i === 38 || i === 13 || i === 39 || i === 27 || i === 9) {
              e.preventDefault();
              e.stopImmediatePropagation();
              if (i === 40) {
                hi(Ce);
              }
              if (i === 38) {
                mi(Ce);
              }
              if (i === 13) {
                e.target.click();
              }
              if (i === 39 && videojs.dom.hasClass(e.target, 'vjs-menu-forward')) {
                e.target.click();
              }
              if (i === 27 || i === 9) {
                M.click();
                M.focus();
              }
            }
          });
        }
      }
      M.onclick = M.ontouchend = function (e) {
        e.preventDefault();
        e.stopPropagation();
        l(e);
      };
      M.onmouseover = function () {
        if (videojs.dom.hasClass(c, 'vjs-lock-showing') !== true) {
          M.querySelector('.vjs-control-text').removeAttribute('style');
        }
      };
    }
    function Si() {
      var o = S;
      var e = o.querySelector('.vjs-menu');
      var l = e.querySelector('.vjs-menu-content');
      if (Z > 1) {
        function i(e) {
          if (videojs.dom.hasClass(e.target, 'vjs-quality-button')) {
            if (videojs.dom.hasClass(e.target, 'vjs-cast')) {
              return false;
            }
            var i = e.target.querySelector('.vjs-control-text');
            var t = o.querySelector('.vjs-menu');
            if (videojs.dom.hasClass(t, 'vjs-lock-showing')) {
              e.target.ariaExpanded = 'false';
              videojs.dom.removeClass(t, 'vjs-lock-showing');
              if (i) {
                i.removeAttribute('style');
              }
            } else {
              N(e.target);
              var s = t.getBoundingClientRect();
              var n = playerEl.getBoundingClientRect();
              var n = playerEl.offsetHeight - (n.bottom - s.bottom) - 30;
              if (!videojs.dom.hasClass(playerEl, 'vjs-casting')) {
                l.style.maxHeight = n + 'px';
              }
              videojs.dom.addClass(t, 'vjs-lock-showing');
              e.target.ariaExpanded = 'true';
              if (i) {
                i.style.opacity = '0';
              }
              var a = t.querySelector('ul');
              setTimeout(function () {
                a.children[0].focus();
              }, 100);
            }
          }
        }
        o.onclick = o.ontouchend = function (e) {
          e.stopPropagation();
          i(e);
        };
        o.onkeydown = function (e) {
          if (e.which === 13) {
            i(e);
          }
        };
        o.onmouseover = function (e) {
          var i = e.target.querySelector('.vjs-menu');
          if (i) {
            if (videojs.dom.hasClass(i, 'vjs-lock-showing') !== true) {
              i = e.target.querySelector('.vjs-control-text');
              if (i) {
                i.removeAttribute('style');
              }
            }
          }
        };
      }
      var s = playerEl.querySelector('.vjs-menu-quality');
      function t(e, i, t) {
        videojs.dom.addClass(b, hiddenClassname);
        videojs.dom.removeClass(i, hiddenClassname);
        if (r) {
          if (r !== i) {
            videojs.dom.addClass(r, hiddenClassname);
          }
        }
        if (s) {
          if (s !== i) {
            videojs.dom.addClass(s, hiddenClassname);
          }
        }
        g.style.width = v[t].width + 'px';
        g.style.height = v[t].height + 'px';
        s.querySelector('.vjs-menu-content').children[0].focus();
      }
      function n(e) {
        if (r) {
          videojs.dom.addClass(r, hiddenClassname);
        }
        if (ye) {
          videojs.dom.addClass(ye, hiddenClassname);
        }
        if (s) {
          videojs.dom.addClass(s, hiddenClassname);
        }
        videojs.dom.removeClass(b, hiddenClassname);
        g.style.width = v.cogMenu.width + 'px';
        g.style.height = v.cogMenu.height + 'px';
        Ii(d * 100);
      }
      if (s && options.qualityMenu) {
        videojs.dom.removeClass(playerEl.querySelector('.vjs-cog-menu-button'), hiddenClassname);
        s.onclick = function (e) {
          e.stopPropagation();
        };
        e = playerEl.querySelector('.vjs-extend-quality');
        e.onclick = e.ontouchend = function (e) {
          e.preventDefault();
          e.stopPropagation();
          t(e, s, 'qualityMenu');
        };
        e = s.querySelector('.vjs-settings-back');
        e.onclick = e.ontouchend = function (e) {
          e.preventDefault();
          e.stopPropagation();
          n(e);
        };
      }
    }
    function Hi() {
      if (options.menus !== true);
      else {
        var e = options.related.length;
        var i = 0.8;
        var t = 800;
        if (playerEl.querySelector('.rel-block')) {
          videojs.dom.removeClass(playerEl.querySelector('.rel-block'), 'rel-anim');
          var s = playerEl.offsetWidth;
          var n = s * i;
          if (t < n) {
            n = t;
          }
          var a = parseInt(playerEl.querySelector('.vjs-related').style.maxWidth, 10);
          if (isNaN(a)) {
            a = 0;
          }
          if (parseInt(a, 10) < 100) {
            a = t;
          }
          if (a < n) {
            n = a;
          }
          var o = playerEl.querySelector('.vjs-related');
          if (t < a) {
            o.style.maxWidth = t + 'px';
          } else {
            o.style.maxWidth = '800px';
          }
          o.style.width = i * 100 + '%';
          var a = 3;
          var l = 2;
          if (e < 5 && e !== 3) {
            a = 2;
          }
          if (e < 4) {
            l = 1;
          }
          if (n < 480) {
            a = 2;
            l = 1;
          }
          var r = (n / a) * 0.5625;
          var d = n / a;
          var t = Math.ceil(e / 6);
          if (t < j) {
            j = t;
          }
          V = t;
          if (a === 2 && l === 2) {
            V = Math.ceil(e / 4);
          }
          if (a === 2 && l === 1) {
            V = Math.ceil(e / 2);
          }
          i = r * l;
          o.style.height = i + 'px';
          t = s / 2 - n / 2;
          o.style.top = playerEl.offsetHeight * 0.55 - i / 2 + 'px';
          o.style.left = s / 2 - n / 2 + 'px';
          a = playerEl.querySelector('.vjs-arrow-prev');
          e = playerEl.querySelector('.vjs-arrow-next');
          if (J() && a && e) {
            i = parseInt(playerEl.querySelector('.vjs-prev').offsetWidth + 5, 10);
            a.style.left = t - i + 'px';
            e.style.left = n + t + 'px';
            videojs.dom.removeClass(e, 'vjs-disabled');
            videojs.dom.removeClass(a, 'vjs-disabled');
            if (j === V) {
              videojs.dom.addClass(e, 'vjs-disabled');
            }
            if (j === 1) {
              videojs.dom.addClass(a, 'vjs-disabled');
            }
          }
          if (j > 1) {
            s = o.offsetWidth;
            i = (j - 1) * s;
            playerEl.querySelector('.rel-block').style.left = '-' + i + 'px';
          }
          var v = 0;
          var f = 0;
          for (var c = playerEl.querySelectorAll('.rel-parent'), u = 0; u < c.length; u++) {
            var p = c[u];
            p.style.left = v + 'px';
            if (f === 1 && l > 1) {
              p.style.top = r + 'px';
              v += d;
            } else {
              p.style.top = 0;
            }
            if (l === 1) {
              v += d;
            }
            p.style.width = d + 'px';
            p.style.height = r + 'px';
            if (l > 1) {
              if (++f == 2) {
                f = 0;
              }
            } else {
              f = 0;
            }
          }
          videojs.dom.addClass(playerEl.querySelector('.rel-block'), 'rel-anim');
        }
      }
    }
    function Bi() {
      if (options.menus !== true);
      else {
        var e = playerEl.querySelector('.vjs-menu-speed');
        var i = playerEl.querySelector('.vjs-zoom-menu');
        var t = playerEl.querySelector('.vjs-menu-quality');
        g.style.width = 'auto';
        g.style.height = 'auto';
        if (e) {
          videojs.dom.addClass(e, hiddenClassname);
        }
        if (i) {
          videojs.dom.addClass(i, hiddenClassname);
        }
        if (t) {
          videojs.dom.addClass(t, hiddenClassname);
        }
        videojs.dom.removeClass(c, hiddenClassname);
        v.cogMenu = {
          width: c.clientWidth,
          height: c.clientHeight,
        };
        if (e) {
          videojs.dom.addClass(b, hiddenClassname);
          if (i) {
            videojs.dom.addClass(i, hiddenClassname);
          }
          if (t) {
            videojs.dom.addClass(t, hiddenClassname);
          }
          videojs.dom.removeClass(e, hiddenClassname);
          videojs.dom.addClass(e, 'vjs-invisible');
          v.speedMenu = {
            width: e.clientWidth,
            height: e.clientHeight,
          };
          videojs.dom.removeClass(e, 'vjs-invisible');
          videojs.dom.addClass(e, hiddenClassname);
        }
        if (t && options.qualityMenu) {
          videojs.dom.addClass(b, hiddenClassname);
          if (i) {
            videojs.dom.addClass(i, hiddenClassname);
          }
          if (e) {
            videojs.dom.addClass(e, hiddenClassname);
          }
          videojs.dom.removeClass(t, hiddenClassname);
          videojs.dom.addClass(t, 'vjs-invisible');
          v.qualityMenu = {
            width: t.offsetWidth,
            // CHANGES: start
            // calculated offsetHeight
            height: t.offsetHeight + 16,
            // CHANGES: end
          };
          videojs.dom.removeClass(t, 'vjs-invisible');
          videojs.dom.addClass(t, hiddenClassname);
          videojs.dom.removeClass(playerEl.querySelector('.vjs-cog-menu-button'), hiddenClassname);
        }
        if (i) {
          videojs.dom.addClass(b, hiddenClassname);
          if (e) {
            videojs.dom.addClass(e, hiddenClassname);
          }
          if (t) {
            videojs.dom.addClass(t, hiddenClassname);
          }
          videojs.dom.removeClass(i, hiddenClassname);
          videojs.dom.addClass(i, 'vjs-invisible');
          i.style.width = 'auto';
          v.zoomMenu = {
            width: i.clientWidth,
            height: i.clientHeight + 1,
          };
          videojs.dom.removeClass(i, 'vjs-invisible');
          videojs.dom.addClass(i, hiddenClassname);
        }
        var s = playerEl.querySelectorAll('.vjs-submenu');
        if (s) {
          for (var n = 0; n < s.length; n++) {
            videojs.dom.addClass(s[n], hiddenClassname);
          }
        }
        videojs.dom.removeClass(b, hiddenClassname);
        videojs.dom.removeClass(c, 'vjs-invisible');
        videojs.dom.removeClass(b, hiddenClassname);
        if (
          options.speedMenu ||
          options.zoomMenu ||
          options.relatedMenu ||
          options.shareMenu ||
          options.qualityMenu
        ) {
          videojs.dom.removeClass(playerEl.querySelector('.vjs-cog-menu-button'), hiddenClassname);
        }
        e = g.getBoundingClientRect();
        t = playerEl.getBoundingClientRect();
        i = playerEl.offsetHeight - (t.bottom - e.bottom);
        g.style.maxHeight = i - playerEl.getBoundingClientRect().height / 6 + 'px';
      }
    }
    function qi(e, i) {
      var e = parseInt(e, 10);
      var t = '<i class="vjs-hd-icon vjs-hd-home vjs-hidden"></i>';
      var s = '';
      if (options.hdicon) {
        s = 'HD';
        if (e > 1079 && options.minhd < 1080) {
          s = 'FHD';
        }
        if (e > 2159) {
          s = '4K';
        }
        if (e > options.minhd - 1) {
          t = '<i class="vjs-hd-icon vjs-hd-home">' + s + '</i>';
        }
      }
      if (options.qualityMenu) {
        playerEl.querySelector('.quality-label').innerHTML = i + t;
        s = playerEl.querySelector('.vjs-hd');
        if (e > options.minhd - 1) {
          videojs.dom.removeClass(s, hiddenClassname);
        } else {
          videojs.dom.addClass(s, hiddenClassname);
        }
      } else {
        S.querySelector('.quality-span').innerHTML = i + t;
      }
    }
    function Ni(e, i) {
      if (e.res && i.res) {
        return +i.res - +e.res;
      } else {
        return 0;
      }
    }
    function Di(e) {
      if (options.hdicon) {
        if (options.qualityMenu) {
          var i = playerEl.querySelector('.vjs-hd');
          if (e > options.minhd - 1) {
            var t = 'HD';
            if (e > 1079 && options.minhd < 1080) {
              t = 'FHD';
            }
            if (e > 2159) {
              t = '4K';
            }
            i.innerHTML = t;
            videojs.dom.removeClass(i, hiddenClassname);
          } else {
            videojs.dom.addClass(i, hiddenClassname);
          }
        }
      }
    }
    function Pi(e) {
      var r = null;
      var o = null;
      try {
        r = player.dash.mediaPlayer || null;
      } catch (e) {}
      var i = player.options().html5.hlsjsConfig || null;
      var d = true;
      if (i) {
        if (i.smoothQualityChange === false) {
          d = false;
        }
      }
      Li();
      Bi();
      var t = [];
      var s = false;
      for (var n = 0; n < e.length; n++) {
        var s = false;
        for (var a = 0; a < t.length; a++) {
          if (e[n].height === t[a].height && e[n].bitrate === t[a].bitrate) {
            s = true;
          }
        }
        if (s !== true) {
          t.push(e[n]);
        }
      }
      if (t.length < 2);
      else {
        options.is_auto = true;
        var v = 0;
        var f = 0;
        var keyToSortBy = 'bitrate';
        t.forEach((e) => {
          if (e.height > 0) {
            v++;
          }
          if (e.bitrate > 0) {
            f++;
          }
        });
        if (f < v) {
          keyToSort = 'height';
        }
        t = (t = sortByKey(t, keyToSortBy)).reverse();
        Z = t.length + 1;
        var c = 'vjs-menu-item item-quality';
        var i = 'vjs-menu-item item-quality vjs-checked';
        var u = '';
        t.forEach((e) => {
          var i = '';
          if (e.height) {
            var t = parseInt(e.height, 10);
            if (options.hdicon && t > options.minhd - 1) {
              var s = 'HD';
              if (t > 1079 && options.minhd < 1080) {
                s = 'FullHD';
              }
              if (t > 2159) {
                s = '4K';
              }
              i = '<i class="vjs-hd-icon">' + s + '</i>';
              Di(t);
            }
          }
          var s = parseInt(e.bitrate, 10) || 0;
          var t = parseInt(e.height, 10) || 0;
          var n = 0;
          if (s > 0) {
            n = Math.round(s / 1000);
          }
          if (t > 0 || s > 0) {
            var a = c;
            var o = n + ' kbps';
            var l = '';
            var r = '';
            if (v === f) {
              if (options.resOnly) {
                l = t + 'p' + i + '</li>';
              } else {
                l = t + 'p <i>(' + o + ')</i>' + i + ' </li>';
              }
              r = I('set quality to ') + t.toString() + 'p';
            } else if (f < v) {
              l = t + 'p' + i + '</li>';
              r = I('Set quality to') + t.toString() + 'p';
            } else {
              l = n + ' kbps</li>';
              r = I('Set quality to') + n.toString() + ' kbps';
            }
            o =
              '<li aria-label="' +
              r +
              '" data-id="' +
              e.index +
              '" class="' +
              a +
              '" data-bitrate="' +
              s +
              '" data-height="' +
              t +
              '" tabindex="0" role="menuitemradio" aria-live="off" aria-disabled="false">';
            u += o + l;
          }
        });
        u +=
          '<li id="auto" class="vjs-menu-item item-quality auto-res vjs-checked" data-height="Autores"' +
          i +
          ' tabindex="0" aria-label="' +
          I('Set auto quality') +
          '" role="menuitem" aria-live="off" aria-disabled="false">' +
          I('Auto') +
          '<i class="autores"></i></li>';
        i = playerEl.querySelector('.quality-span');
        if (options.qualityMenu) {
          i = playerEl.querySelector('.quality-label');
        }
        i.innerHTML = I('Auto');
        if (options.qualityMenu) {
          Zi();
          (l = playerEl.querySelector('.vjs-menu-quality .vjs-menu-content')).innerHTML =
            l.innerHTML + u;
          videojs.dom.removeClass(playerEl.querySelector('.vjs-extend-quality'), hiddenClassname);
          videojs.dom.removeClass(M, hiddenClassname);
        } else {
          var l = S.querySelector('.vjs-menu');
          var i = l.querySelector('.vjs-menu-title');
          if (i) {
            l.removeChild(i);
          }
          i = createElement('div', 'vjs-menu-title', I('Quality'));
          l.prepend(i);
          S.querySelector('.vjs-menu .vjs-menu-content').innerHTML = u;
          videojs.dom.removeClass(S, hiddenClassname);
        }
        var p = playerEl.querySelectorAll('.item-quality');
        Si();
        Bi();
        l.addEventListener('keydown', Ai);
        player.on('qualityChange', function (e, i) {
          if (r) {
            o = {
              height: i.height,
              bitrate: i.bitrate,
            };
          }
          i = playerEl.querySelector('.auto-res');
          if (i) {
            if (i.className.indexOf('vjs-checked') > -1) {
              b();
            }
          }
        });
        var h = function (e) {
          p.forEach((e) => {
            videojs.dom.removeClass(e, 'vjs-checked');
          });
          videojs.dom.removeClass(playerEl.querySelector('.auto-res'), 'vjs-checked');
          videojs.dom.addClass(e.target, 'vjs-checked');
          var i = e.target.getAttribute('id');
          zi();
          if (i === 'auto') {
            b(true);
          } else {
            y(e.target);
          }
        };
        for (var m = 0; m < p.length; m++) {
          var j = p[m];
          var g = !(j.onclick = function (e) {
            e.preventDefault();
            e.stopPropagation();
            h(e);
          });
          j.addEventListener(
            'touchstart',
            function () {
              g = false;
            },
            {
              passive: true,
            },
          );
          j.addEventListener(
            'touchmove',
            function () {
              g = true;
            },
            {
              passive: true,
            },
          );
          j.ontouchend = function (e) {
            if (g);
            else {
              e.stopPropagation();
              h(e);
            }
          };
        }
        if (options.startLevel !== 'undefined') {
          _(parseInt(options.startLevel, 10));
        } else {
          b(false);
        }
      }
      function b(e) {
        if (videojs.dom.hasClass(playerEl, 'vjs-has-started')) {
          videojs.dom.addClass(loadingSpinnerEl, hiddenClassname);
        }
        if (r) {
          r.updateSettings({
            streaming: {
              abr: {
                autoSwitchBitrate: {
                  video: true,
                },
              },
            },
          });
          var i = playerEl.querySelector('.auto-res');
          if (i) {
            i.className = 'vjs-menu-item item-quality auto-res vjs-checked';
          }
          playerEl.querySelector('.quality-span').innerHTML = I('Auto');
        }
        if (e && !r) {
          if (player.hlsjs) {
            if (d) {
              player.hlsjs.nextLevel = -1;
            } else {
              player.hlsjs.currentLevel = -1;
            }
          } else {
            for (var t = 0; t < player.qualities.length; t++) {
              player.setQuality(t, true);
            }
          }
        }
        if ((i = playerEl.querySelector('.auto-res'))) {
          i.className = 'vjs-menu-item item-quality auto-res vjs-checked';
        }
        e = null;
        if (r) {
          e = o;
        } else {
          e = player.qualities[player.qualityIndex];
        }
        var s = '';
        var n = '';
        var a = 0;
        if (e) {
          if (e.height) {
            a = parseInt(e.height, 10);
          }
          if (a > 0) {
            n = e.height + 'p';
          } else {
            n = Math.round(e.bitrate / 1000) + 'kbps';
          }
          if (i) {
            i.innerHTML = I('Auto') + '<i class="autores">' + n + '</i>';
          }
          if (options.hdicon) {
            if (a >= options.minhd - 1) {
              s = 'HD';
            }
            if (a > 1079 && options.minhd < 1080) {
              s = 'FHD';
            }
            if (a > 2159) {
              s = '4K';
            }
            Di(a);
          }
        }
        if (s === '') {
          ae = 'vjs-hd-icon vjs-hd-home vjs-hidden';
        } else {
          ae = 'vjs-hd-icon vjs-hd-home';
        }
        e = playerEl.querySelector('.quality-span');
        if (options.qualityMenu) {
          e = playerEl.querySelector('.quality-label');
        }
        e.innerHTML = I('Auto') + '<i class="' + ae + '">' + s + '</i>';
      }
      function y(e) {
        playerEl.querySelector('.auto-res').innerHTML = I('Auto') + '<i class="autores"></i>';
        var i = parseInt(e.getAttribute('data-height'), 10);
        var t = i;
        var s = parseInt(e.getAttribute('data-bitrate'), 10);
        var n = player.qualities;
        playerEl.querySelectorAll('.item-quality').forEach((e) => {
          videojs.dom.removeClass(e, 'vjs-checked');
        });
        videojs.dom.addClass(e, 'vjs-checked');
        e = player.video_id();
        if (t === 0) {
          t = s;
        }
        player.trigger('resolutionchange', {
          id: e,
          res: t,
        });
        for (var a = 0; a < n.length; a++) {
          if (!player.hlsjs) {
            player.setQuality(a, false);
          }
          if (n[a].height === t || n[a].bitrate === s) {
            if (player.hlsjs) {
              if (d) {
                player.hlsjs.nextLevel = a;
              } else {
                player.hlsjs.currentLevel = a;
              }
            } else if (r) {
              r.updateSettings({
                streaming: {
                  abr: {
                    autoSwitchBitrate: {
                      video: false,
                    },
                  },
                },
              });
              r.setQualityFor('video', n[a].id);
            } else {
              player.setQuality(a, true);
            }
          }
        }
        var e = '';
        var o = '';
        var l = playerEl.querySelector('.quality-span');
        if (options.qualityMenu) {
          l = playerEl.querySelector('.quality-label');
        }
        if (i > 0) {
          e = 'HD';
          if (i > 1079 && options.minhd < 1080) {
            e = 'FullHD';
          }
          if (i > 2159) {
            e = '4K';
          }
          if (i > options.minhd - 1) {
            o = '<i class="vjs-hd-icon vjs-hd-home">' + e + '</i>';
          } else {
            o = '<i class="vjs-hd-icon vjs-hd-home vjs-hidden">' + e + '</i>';
          }
          l.innerHTML = i + 'p' + o;
        } else if (s > 0) {
          l.innerHTML = Math.round(s / 1000) + 'kbps';
        }
        Di(i);
      }
      function _(s) {
        var n = player.qualities;
        p.forEach((e) => {
          var i = parseInt(e.getAttribute('data-height'), 10);
          var t = parseInt(e.getAttribute('data-bitrate'), 10);
          if (i === n[s].height || t === n[s].bitrate) {
            e.click();
          }
        });
      }
    }
    function Ai(e) {
      let i = e.target.parentNode;
      var t = e.which;
      e.preventDefault();
      e.stopPropagation();
      if (t === 40) {
        hi(i);
      }
      if (t === 38) {
        mi(i);
      }
      if (t === 13) {
        i.children[pi(i)].click();
        S.focus();
      }
      if (t === 27 || t === 37) {
        if (options.qualityMenu) {
          e = playerEl.querySelector('.vjs-menu-quality');
          e.querySelector('.vjs-settings-back').click();
          playerEl.querySelector('.vjs-extend-quality').focus();
        } else {
          S.click();
        }
        S.focus();
      }
      if (t === 9) {
        if (options.qualityMenu) {
          M.click();
          M.focus();
        } else {
          S.click();
          S.focus();
        }
      }
    }
    function zi() {
      if (options.qualityMenu) {
        videojs.dom.addClass(playerEl.querySelector('.vjs-menu-quality'), hiddenClassname);
        videojs.dom.removeClass(playerEl.querySelector('.vjs-settings-home'), hiddenClassname);
        videojs.dom.removeClass(c, 'vjs-lock-showing');
        videojs.dom.addClass(c, hiddenClassname);
      } else if (S) {
        var e = S.querySelector('.vjs-menu');
        var i = S.querySelector('.vjs-control-text');
        if (e) {
          videojs.dom.removeClass(e, 'vjs-lock-showing');
          if (i) {
            i.removeAttribute('style');
          }
        }
      }
    }
    function Ri() {
      if (
        videojs.dom.hasClass(playerEl, 'vjs-ad-playing') ||
        videojs.dom.hasClass(playerEl, 'vjs-dai') ||
        videojs.dom.hasClass(playerEl, 'vjs-up-next')
      );
      else if (player.duration() === Infinity);
      else if (videojs.browser.IOS_VERSION === '8' && player.duration() === 0);
      else {
        var e = player.video_id();
        if (e === null);
        else {
          var i = 0;
          if (options.resume && e) {
            e = String('vjs_resume-' + e);
            if (localStorage && localStorage.key) {
              i = Number(localStorage.getItem(e));
            }
          }
          if (i > 0 && i < player.duration() - 20) {
            setTimeout(function () {
              player.currentTime(i);
            }, 200);
          }
        }
      }
    }
    function Wi() {
      var e = playerEl.querySelector('.vjs-audio-info');
      if (e) {
        playerEl.removeChild(e);
      }
      if (options.audioInfo && (options.audioInfo.artist || options.audioInfo.title)) {
        e = playerEl.querySelector('.vjs-info');
        if (e) {
          e.parentNode.removeChild(e);
        }
        var e = createElement('span', 'vjs-audio-info');
        var i = '';
        if (options.audioInfo.cover) {
          i += '<span class="vjs-cover"><img src="' + options.audioInfo.cover + '"/></span>';
        }
        i += '<span class="vjs-audio-item vjs-text">';
        if (options.audioInfo.artist) {
          i += '<span class="audio-artist">' + options.audioInfo.artist + '</span>';
        }
        if (options.audioInfo.title) {
          i += '<span class="vjs-audio-item vjs-song">' + options.audioInfo.title + '</span>';
        }
        if (options.audioInfo.genre || options.audioInfo.album || options.audioInfo.year) {
          i += '<span class="vjs-audio-item audio-id">';
          if (options.audioInfo.genre) {
            i += '<span>Genre: ' + options.audioInfo.genre + '</span>';
          }
          if (options.audioInfo.album) {
            i += '<span>Album: ' + options.audioInfo.album + '</span>';
          }
          if (options.audioInfo.year) {
            i += '<span>Year: ' + options.audioInfo.year + '</span>';
          }
          i += '</span>';
        }
        e.innerHTML = i += '</span>';
        playerEl.appendChild(e);
        e.onclick = function () {
          if (options.audioInfo.url) {
            window.open(options.audioInfo.url, options.target);
          }
        };
      }
    }
    function Oi() {
      var e = playerEl.querySelector('.vjs-info');
      if (e) {
        playerEl.removeChild(e);
      }
      if (options.videoInfo && (options.infoText || options.infoTitle || options.title !== '')) {
        var e = createElement('span', 'vjs-info');
        var i = '';
        playerEl.appendChild(e);
        var t = playerEl.querySelector('.vjs-cast-big');
        var s = '';
        if (options.infoTitle) {
          s = options.infoTitle;
        } else if (options.title !== '') {
          s = options.title;
        }
        var n = '';
        if (options.infoIcon !== '') {
          n = '<span class="vjs-icon"><img src="' + options.infoIcon + '"/></span>';
        }
        i += n;
        if (t) {
          i += '<span class="vjs-text" style="padding-left:50px">';
        } else {
          i += '<span class="vjs-text">';
        }
        i += '<span class="vjs-ttl">' + s + '</span>';
        if (options.infoDescription || options.description) {
          n = options.infoDescription || null;
          if (!n && options.description) {
            n = options.description;
          }
          i += '<span class="vjs-dsc">' + n + '</span>';
        }
        e.innerHTML = i += '</span>';
        e.onclick = function () {
          if (options.infoUrl) {
            window.open(options.infoUrl, options.target);
          }
        };
      }
    }
    function Fi() {
      var e = [];
      var i = false;
      if (player.textTracks().length > 0) {
        for (var t = player.textTracks(), s = 0; s < t.length; s++) {
          var n = {};
          var a = t[s];
          if (a.kind === 'captions' || a.kind === 'subtitles') {
            n.kind = a.kind;
            n.src = a.src;
            n.language = a.language;
            n.label = a.label;
            if (a.default) {
              n.mode = 'showing';
            }
            e.push(n);
          }
          if (a.kind === 'metadata' && i !== true) {
            if (a.src !== undefined) {
              i = true;
              player.trigger('medialoaded', {
                xml: a.src,
              });
            }
          }
        }
      }
      if (e.length > 0) {
        player.captions = e;
      }
    }
    function $i() {
      var e = player.textTracks();
      for (var i = e.length || 0; i--; ) {
        e[i].mode = 'hidden';
      }
      for (var t = player.remoteTextTracks(), s = 0; s < t.length; s++) {
        if (t[s].kind === 'captions' || t[s].kind === 'subtitles') {
          t[s].mode = 'hidden';
        }
      }
      for (var n = t.length || 0; n--; ) {
        player.removeRemoteTextTrack(t[n]);
      }
    }
    function Ui(e) {
      var i = playerEl.querySelector('.vjs-thumbnail-holder');
      var t = playerEl.querySelector('.vjs-progress-slide .vjs-thumb');
      if (i) {
        if (options.skin === 'shaka') {
          var s = Number(i.style.height.replace(/px$/, ''));
          var s = i.computedStyleMap().get('bottom').value + s + 5;
          e.style.bottom = s + 'px';
        } else {
          videojs.dom.addClass(i, 'vjs-vtt');
        }
        videojs.dom.addClass(e, 'vjs-chapter-mix');
      } else if (t) {
        if (options.skin === 'shaka') {
          s = Number(t.style.height.replace(/px$/, ''));
          e.style.bottom = s + 32 + 'px';
        } else {
          videojs.dom.addClass(t, 'vjs-sld');
        }
        videojs.dom.addClass(e, 'vjs-chapter-mix');
      }
      return 0;
    }
    function Ki() {
      var e = player.remoteTextTracks();
      var i = playerEl.querySelector('.vjs-chapter');
      var t = false;
      if (i) {
        i.parentNode.removeChild(i);
      }
      progressHolderEl.removeEventListener('mousemove', s, false);
      progressHolderEl.removeEventListener('mouseout', a, false);
      progressHolderEl.removeEventListener('touchstart', n);
      function s(e) {
        Vi(true);
        if (!playerEl.querySelector('.vjs-chapter'));
        else {
          var i;
          var t = playerEl.querySelector('.vjs-thumbnail-holder');
          if (t && options.skin === 'slategrey') {
            videojs.dom.addClass(t, 'vjs-thumb-mix');
          }
          if (e.type === 'mousemove') {
            i = e.pageX;
          } else if (e.type === 'touchmove') {
            i = vi(e);
          }
          var s = i - progressHolderEl.getBoundingClientRect().left;
          if (s < 0);
          else {
            var n = (s / progressHolderEl.offsetWidth) * f;
            Ui(c);
            c.style.left = s + 'px';
            for (var a = d.length - 1; a >= 0; a--) {
              var o = d[a];
              if (n >= o.startTime) {
                if (c.innerHTML !== o.text) {
                  c.innerHTML = o.text;
                  c.style.maxWidth = progressHolderEl.offsetWidth * 0.9 + 'px';
                }
                var o = c.offsetWidth / 2;
                var l = progressHolderEl.offsetWidth - c.offsetWidth / 2;
                if (s < o) {
                  c.style.left = o + 'px';
                }
                if (l < s) {
                  c.style.left = l + 'px';
                }
                c.style.opacity = '1';
                c.style.visibility = 'visible';
                break;
              }
            }
          }
        }
      }
      function n(e) {
        s(e);
        document.addEventListener('touchmove', s);
        document.addEventListener('touchend', a);
      }
      function a() {
        Vi(false);
        if (videojs.holderdown);
        else {
          document.removeEventListener('touchmove', s);
          document.removeEventListener('touchend', a);
          c.style.opacity = '0';
          c.style.visibility = 'visible';
        }
      }
      if (options.chapterMarkers) {
        var o = playerEl.getElementsByClassName('vjs-marker');
        if (o) {
          while (o.length > 0) {
            o[0].parentNode.removeChild(o[0]);
          }
        }
        if (options.chapters);
        else {
          for (var l = 0; l < e.length; l++) {
            if (e[l].kind === 'chapters') {
              e[l].mode = 'showing';
            }
            if (e[l].cues) {
              if (e[l].kind === 'chapters' && e[l].cues.length > 0 && t !== true) {
                var t = true;
                var r = e[l];
              }
            }
          }
          if (t) {
            var d = r.cues;
            if (d.length > 0) {
              options.chapters = true;
            }
            r.mode = 'hidden';
            if (d) {
              i = playerEl.querySelector('.vjs-progress-control');
              i = i.querySelector('.vjs-mouse-display');
              if (i) {
                videojs.dom.addClass(i, 'vjs-abs-hidden');
              }
              var v = [];
              var f = player.duration();
              var c = createElement('div');
              c.className = 'vjs-chapter';
              progressHolderEl.appendChild(c);
              progressHolderEl.addEventListener('touchstart', n, {
                passive: true,
              });
              progressHolderEl.addEventListener('mousemove', s, false);
              progressHolderEl.addEventListener('mouseout', a, false);
              for (l = 0; l < d.length; l++) {
                if (f > 0) {
                  if (d[l].startTime > 0) {
                    v[l] = d[l].startTime;
                    var u = createElement('div', 'vjs-marker');
                    var p = createElement('div', 'vjs-marker-inn');
                    u.appendChild(p);
                    u.style.left = (v[l] / f) * 100 + '%';
                    progressHolderEl.appendChild(u);
                  }
                }
              }
            }
          }
        }
      }
    }
    function Xi(e, i) {
      var t = createElement('li', 'vjs-item');
      t.setAttribute('data-id', i);
      t.tabIndex = '0';
      i = createElement('div', 'vjs-tmb');
      if (e.thumb) {
        i.style.backgroundImage = 'url(' + e.thumb + ')';
      }
      t.appendChild(i);
      i = createElement('p');
      if (e.title) {
        i.innerHTML = e.title;
      } else {
        var s = '';
        if (e.audioInfo) {
          if (e.audioInfo.title) {
            s = e.audioInfo.title;
            if (e.audioInfo.artist) {
              s = e.audioInfo.artist + ' - ' + s;
            }
          }
        }
        if (s !== '') {
          i.innerHTML = s;
        } else {
          var n = e.sources[0].src;
          var a = n.substring(n.lastIndexOf('/') + 1);
          if (e.sources.length > 0) {
            for (var o = 0; o < e.sources.length; o++) {
              if (e.sources[o].default) {
                a = (n = e.sources[o].src).substring(n.lastIndexOf('/') + 1);
              }
            }
          }
          a = a.replace(/(\.[^/.]+)+$/, '');
          i.innerHTML = a;
        }
      }
      t.appendChild(i);
      if (options.playlistID !== 'undefined') {
        var l = document.getElementById(options.playlistID);
      }
      if (l) {
        s = createElement('p', 'vjs-desc');
        if (e.description) {
          s.innerHTML = e.description;
        }
        t.appendChild(s);
      }
      if (e.duration) {
        i = createElement('span');
        i.innerHTML = e.duration;
        t.appendChild(i);
      }
      t.onclick = function (e) {
        e.preventDefault();
        e.stopPropagation();
        r(e);
      };
      function r(e) {
        if (player.adPlaying);
        else {
          e = e.target.getAttribute('data-id');
          player.playlist.currentItem(parseInt(e, 10));
          if (player.paused()) {
            player.play();
          }
        }
      }
      return t;
    }
    function Yi() {
      if ((n = player.playlist.list()).length < 2);
      else {
        player.on('error', function () {
          if (player.playlist.currentIndex() === player.playlist.lastIndex()) {
            if (options.playlistRepeat !== true) {
              loadingSpinnerEl.style.display = 'none';
            } else {
              player.playlist.next(true);
            }
          } else {
            player.playlist.next(true);
          }
        });
        if (options.playlist !== true) {
          options.playlist = true;
          if (options.playlistNavigation) {
            var e = createElement(
              'div',
              'vjs-playlist-nav vjs-nav-prev',
              '<div class="vjs-prev vjs-disabled">&lang;</div>',
            );
            e.setAttribute('tabindex', '0');
            var i = createElement(
              'div',
              'vjs-playlist-nav vjs-nav-next',
              '<div class="vjs-next">&rang;</div>',
            );
            i.setAttribute('tabindex', '0');
            playerEl.insertBefore(e, controlBarEl);
            playerEl.insertBefore(i, controlBarEl);
            i.role = e.role = 'button';
            i.ariaLabel = 'Next video';
            e.ariaLabel = 'Previous video';
            i.onclick = i.onkeydown = function (e) {
              if (e.target.className.indexOf('disabled') < 0) {
                if ((e.type == 'keydown' && e.which == 13) || e.type == 'click') {
                  player.playlist.next();
                }
              }
            };
            e.onclick = e.onkeydown = function (e) {
              if (e.target.className.indexOf('disabled') < 0) {
                if ((e.type == 'keydown' && e.which == 13) || e.type == 'click') {
                  player.playlist.previous();
                }
              }
            };
          }
          if (options.playlistUI) {
            var a = null;
            var o = null;
            if (options.playlistID !== 'undefined') {
              a = document.getElementById(options.playlistID);
              player.playlistParent = a;
            } else {
              player.playlistParent = playerEl;
            }
            if (a) {
              o = createElement('div', 'vjs-vplaylist vjs-vplaylist-horizontal vjs-vplaylist-show');
            } else {
              o = createElement('div', 'vjs-vplaylist vjs-vplaylist-show');
            }
            if (!a) {
              var t = videojs.dom.createEl('button', {
                className: 'vjs-playlist-button',
              });
              t.ariaLabel = 'Open playlist';
              t.tabIndex = '0';
              t.ariaDisabled = 'false';
              i = '<span class="vjs-icon-placeholder ';
              if (videojs.svgIcons) {
                i += 'vjs-svg-icon';
              }
              i += '" aria-hidden="true">';
              if (videojs.svgIcons) {
                i += '<svg viewBox="0 0 512 512"><use href="#vjs-icon-playlist"></use></svg>';
              }
              t.innerHTML = i += '</span>';
              playerEl.insertBefore(t, controlBarEl);
              t.onclick = function () {
                videojs.dom.removeClass(o, 'vjs-hidden');
                videojs.dom.addClass(o, 'vjs-vplaylist-show');
                var e = player.playlist.currentIndex();
                let i = o.querySelector('.vjs-vlist ul');
                i.childNodes[e].focus();
                options.playlistIndex = e;
                if (options.playlistFirst) {
                  videojs.dom.addClass(o, 'vjs-vplaylist-first');
                }
              };
            }
            var e = createElement(
              'div',
              'vjs-head',
              '<span class="p-label">' + I('PLAYLIST') + '</span>',
            );
            var s = createElement('button', 'vjs-back', '<i></i>');
            s.ariaLabel = I('Hide playlist');
            s.setAttribute('tabindex', '-1');
            if (a) {
              s.innerHTML = '<i class="vdown"></i>';
              s.ariaLabel = I('Collapse playlist');
              s.setAttribute('tabindex', '0');
            }
            e.appendChild(s);
            o.appendChild(e);
            s.onclick = function () {
              if (a) {
                if (l.offsetHeight > 0) {
                  s.innerHTML = '<i class="vup"></i>';
                  s.ariaLabel = I('Expand playlist');
                  l.style.height = 0;
                } else {
                  s.innerHTML = '<i class="vdown"></i>';
                  s.ariaLabel = I('Collapse playlist');
                  l.removeAttribute('style');
                }
              } else {
                videojs.dom.removeClass(o, 'vjs-vplaylist-show');
                setTimeout(function () {
                  videojs.dom.addClass(o, 'vjs-hidden');
                }, 300);
                t.focus();
              }
            };
            var l = createElement('div', 'vjs-vlist');
            if (a) {
              videojs.dom.addClass(l, 'vjs-list-max');
              if (options.playlistMaxH) {
                i = parseInt(options.playlistMaxH, 10);
                if (i > 0) {
                  l.style.height = i + 'px';
                }
              }
            }
            o.appendChild(l);
            var n = player.playlist.list();
            var r = createElement('ul');
            l.appendChild(r);
            for (var d = 0; d < n.length; d++) {
              var v = Xi(n[d], d);
              r.appendChild(v);
            }
            if (!a) {
              if (options.playlistShow !== true) {
                o.className = 'vjs-vplaylist';
              }
            }
            if (o) {
              o.onkeydown = function (e) {
                if (!options.playlistIndex) {
                  options.playlistIndex = player.playlist.currentIndex();
                }
                let i = e.which;
                if (i === 27) {
                  s.click();
                }
                if (i === 13) {
                  if (e.target !== s) {
                    e.target.click();
                  }
                }
                if (i === 9) {
                  r.childNodes.length;
                  if (!a) {
                    r.lastChild.focus();
                  }
                }
                if (i == 40 || i == 38) {
                  if (i === 40) {
                    options.playlistIndex++;
                    if (options.playlistIndex > r.childNodes.length) {
                      options.playlistIndex = r.childNodes.length;
                    }
                  }
                  if (i === 38) {
                    options.playlistIndex--;
                    if (options.playlistIndex < 0) {
                      options.playlistIndex = 0;
                    }
                  }
                  r.childNodes[options.playlistIndex].focus();
                }
              };
            }
            setTimeout(function () {
              if (!a) {
                playerEl.insertBefore(o, controlBarEl);
              } else {
                a.appendChild(o);
              }
            }, 100);
          }
        }
        player.on('playlist_newitem', function () {
          if (options.playlistUI) {
            l.querySelectorAll('.vjs-item').forEach((e) => {
              videojs.dom.removeClass(e, 'vjs-active-item');
            });
          }
          player.on('play', function () {
            if (options.playlistUI) {
              var e = player.playlist.currentIndex();
              for (var i = l.querySelectorAll('.vjs-item'), t = 0; t < i.length; t++) {
                videojs.dom.removeClass(i[t], 'vjs-active-item');
                if (t === e) {
                  videojs.dom.addClass(i[t], 'vjs-active-item');
                }
              }
              if (options.playlistAutoHide && !a) {
                videojs.dom.removeClass(o, 'vjs-vplaylist-show');
              }
            }
            if (options.playlistNavigation) {
              var s = playerEl.querySelector('.vjs-nav-prev');
              var n = playerEl.querySelector('.vjs-nav-next');
              var s = s.querySelector('.vjs-prev');
              var n = n.querySelector('.vjs-next');
              if (player.playlist.currentIndex() === 0) {
                videojs.dom.addClass(s, 'vjs-disabled');
              } else {
                videojs.dom.removeClass(s, 'vjs-disabled');
              }
              if (player.playlist.currentIndex() === player.playlist.lastIndex()) {
                videojs.dom.addClass(n, 'vjs-disabled');
              } else {
                videojs.dom.removeClass(n, 'vjs-disabled');
              }
            }
          });
        });
      }
    }
    function Qi() {
      player.sprite = false;
      var e = playerEl.querySelector('.vjs-progress-slide');
      if (e) {
        e.parentNode.removeChild(e);
      }
      progressHolderEl.removeEventListener('mousemove', r);
      progressHolderEl.removeEventListener('mousedown', v);
      progressHolderEl.removeEventListener('mouseleave', c);
      progressHolderEl.removeEventListener('touchstart', s);
      e = playerEl.querySelector('.vjs-thumb-poster');
      if (e) {
        playerEl.removeChild(e);
      }
      if (options.slideImage !== '' && options.currentSlide === options.slideImage);
      else if (player.isAudio() !== true && options.slideImage) {
        if (options.slideImage === '');
        else {
          options.currentSlide = options.slideImage;
          e = playerEl.querySelector('.vjs-mouse-display');
          if (player.shadowSlide) {
            var n = createElement('div', 'vjs-thumb-poster');
            var a = createElement('canvas');
            n.appendChild(a);
            playerEl.insertBefore(n, posterEl);
          }
          var i = playerEl.querySelector('.vjs-play-progress .vjs-time-tooltip');
          if (i) {
            videojs.dom.addClass(i, 'vjs-abs-hidden');
          }
          if (e) {
            videojs.dom.addClass(e, 'vjs-abs-hidden');
          }
          player.sprite = true;
          X = createElement('div', 'vjs-progress-slide');
          u = createElement('div', 'vjs-thumb');
          Y = createElement('div', 'vjs-thumb-dr');
          p = createElement('img');
          if (options.slideType === 'horizontal') {
            p.style.width = 'auto';
            p.style.height = options.slideHeight + 'px';
          } else {
            p.style.height = 'auto';
            p.style.width = options.slideWidth + 'px';
          }
          u.appendChild(p);
          u.appendChild(Y);
          X.appendChild(u);
          u.style.left = '-' + parseInt(options.slideWidth / 2, 10) + 'px';
          progressHolderEl.appendChild(X);
          X.style.left = '-1000px';
          var o = 0;
          var l = 0;
          progressHolderEl.addEventListener('mousemove', r);
          progressHolderEl.addEventListener('mousedown', v);
          progressHolderEl.addEventListener('mouseleave', c);
          progressHolderEl.addEventListener('touchstart', s, {
            passive: true,
          });
          i = new Image();
          p.src = options.slideImage;
          i.src = options.slideImage;
          i.onload = function (e) {
            var i = e.target.width;
            var e = e.target.height;
            Q = i / options.slideWidth;
            if (options.slideType !== 'horizontal') {
              Q = e / options.slideHeight;
            }
            videojs.dom.removeClass(X, hiddenClassname);
          };
        }
      }
      function t() {
        progressHolderEl.removeEventListener('touchmove', r);
        progressHolderEl.removeEventListener('touchend', t);
        f();
      }
      function s(e) {
        Vi(true);
        videojs.holderdown = false;
        progressHolderEl.addEventListener('touchmove', function (e) {
          r(e);
        });
        progressHolderEl.addEventListener('touchend', t);
      }
      function r(e) {
        Vi(true);
        if (playerEl.querySelector('.vjs-tech-chromecast'));
        else {
          var i = progressHolderEl.getBoundingClientRect();
          var t = progressHolderEl.offsetWidth;
          var s = null;
          if (e.pageX) {
            s = e.pageX;
          } else if (e.changedTouches) {
            s = vi(e);
          }
          e = s - i.left;
          s = e;
          i = e;
          if (s === 0 && progressHolderEl.offsetWidth > 0 && videojs.holderdown) {
            i = s = progressHolderEl.offsetWidth;
          }
          e = Number(s) / Number(t);
          s = e * player.duration();
          Y.innerHTML = videojs.formatTime(s);
          t = parseInt(e * Q, 10);
          u.style.width = options.slideWidth + 'px';
          u.style.height = options.slideHeight + 'px';
          s = 0;
          if (options.slideType === 'horizontal') {
            s = t * options.slideWidth;
            p.style.left = '-' + s + 'px';
            o = s;
            l = 0;
          } else {
            s = t * options.slideHeight;
            p.style.top = '-' + s + 'px';
            o = 0;
            l = s;
          }
          e = options.slideWidth / 2;
          t = progressHolderEl.offsetWidth - options.slideWidth / 2;
          if (t < i) {
            i = t;
          }
          if (i < e) {
            i = e;
          }
          X.style.left = parseInt(i, 10) + 'px';
          if (videojs.holderdown && player.shadowSlide) {
            s = a.getContext('2d');
            a.width = playerEl.offsetWidth;
            a.height = playerEl.offsetHeight;
            n.style.width = playerEl.offsetWidth + 'px';
            n.style.height = playerEl.offsetHeight + 'px';
            s.drawImage(p, o, l, options.slideWidth, options.slideHeight, 0, 0, a.width, a.height);
          }
          videojs.dom.addClass(u, 'vjs-thumb-show');
        }
      }
      function d() {
        videojs.holderdown = false;
        document.removeEventListener('mousemove', r);
        f();
      }
      function v() {
        Vi(true);
        document.addEventListener('mousemove', r);
        document.addEventListener('mouseup', d);
        if (player.shadowSlide) {
          var e = a.getContext('2d');
          a.width = playerEl.offsetWidth;
          a.height = playerEl.offsetHeight;
          n.style.width = playerEl.offsetWidth + 'px';
          n.style.height = playerEl.offsetHeight + 'px';
          e.drawImage(p, o, l, options.slideWidth, options.slideHeight, 0, 0, a.width, a.height);
        }
      }
      function f() {
        Vi(false);
        if (X) {
          videojs.dom.removeClass(u, 'vjs-thumb-show');
          if (player.shadowSlide) {
            a.width = a.height = 0;
            n.removeAttribute('style');
          }
        }
      }
      function c() {
        f();
      }
    }
    function Vi(e) {
      var i = player.controlBar.progressControl.el();
      if (e) {
        i.setAttribute('style', 'z-index:22');
      } else {
        i.removeAttribute('style');
      }
    }
    function Zi() {
      var e = playerEl.querySelector('.vjs-extend-quality');
      var i = I('Quality');
      if (!e) {
        e = createElement(
          'li',
          'vjs-settings-item vjs-extend-quality vjs-menu-forward vjs-hidden',
          i + '<span class="quality-label"></span>',
        );
        e.tabIndex = '0';
        e.ariaLabel = 'Open quality menu';
        e.role = 'menuitem';
        playerEl.querySelector('.vjs-settings-list').appendChild(e);
        e = createElement(
          'div',
          'vjs-submenu vjs-menu-quality vjs-hidden',
          '<div class="vjs-settings-back">' +
            i +
            '</div><ul class="vjs-menu-content vjs-sub-menu"></ul>',
        );
        playerEl.querySelector('.vjs-settings-div').appendChild(e);
      }
    }
    function Gi(e) {
      var i = progressHolderEl.getBoundingClientRect();
      var t = null;
      if (e.type === 'touchstart') {
        t = vi(e);
        window.document.addEventListener('touchmove', Ji, false);
        window.document.addEventListener('touchend', et, false);
        videojs.dom.addClass(playerEl, 'vjs-scrubbing');
      } else if (e.type === 'mousedown') {
        t = e.pageX;
        window.document.addEventListener('mousemove', Ji, false);
        window.document.addEventListener('mouseup', et, false);
      }
      e = (t - i.left) / progressHolderEl.offsetWidth;
      be.style.width = (e * 100).toFixed(2) + '%';
      videojs.holderdown = true;
      player.trigger('progressdown');
    }
    function Ji(e) {
      var i = progressHolderEl.getBoundingClientRect();
      videojs.dom.addClass(playerEl, 'vjs-scrubbing');
      var t = null;
      if (e.type === 'touchmove') {
        t = vi(e);
      } else if (e.type === 'mousemove') {
        t = e.pageX;
      }
      e = (t - i.left) / progressHolderEl.offsetWidth;
      if (e < 0) {
        e = 0;
      }
      if (e > 1) {
        e = 1;
      }
      be.style.width = (e * 100).toFixed(2) + '%';
    }
    function et(e) {
      e.preventDefault();
      e.stopPropagation();
      videojs.holderdown = false;
      videojs.dom.removeClass(playerEl, 'vjs-scrubbing');
      player.userActive(true);
      window.document.removeEventListener('mousemove', Ji);
      window.document.removeEventListener('mouseup', et);
      window.document.removeEventListener('touchmove', Ji);
      window.document.removeEventListener('touchend', et);
      player.trigger('progressup');
    }
    function it() {
      var e = createElement('button', 'vjs-auto-mute');
      e.title = I('Unmute');
      e.type = 'button';
      e.ariaLive = 'polite';
      e.innerHTML =
        '<span class="vjs-svg-icon" aria-hidden="true"><svg viewBox="0 0 32 32"><use href="#vjs-icon-mute"></use></svg>';
      player.el_.insertBefore(e, bigPlayButtonEl);
      setTimeout(function () {
        player.play();
      }, 300);
      e.onclick = function () {
        player.muted(false);
        player.el_.removeChild(e);
      };
      player.on('ended', function () {
        if (e) {
          player.el_.removeChild(e);
        }
      });
    }
    function tt(e) {
      e.preventDefault();
      e = player.$('.vjs-tech');
      e.style.left = e.offsetWidth / 2 - playerEl.offsetWidth / 2 + 'px';
      e.style.top = e.offsetHeight / 2 - playerEl.offsetHeight / 2 + 'px';
    }
    function st() {
      nt();
      d = 1;
      playerEl.querySelector('.vjs-zoom-level').style.height = '0';
      var e = player.$('.vjs-tech');
      ot(e, 1);
      e.style.top = 0;
      e.style.left = 0;
      Ii(100);
      e = playerEl.querySelector('.vjs-zoom-parent');
      videojs.dom.addClass(e, 'vjs-hidden');
      return (videojs.options.blockKeys = false);
    }
    function nt() {
      var e = ((d - 1) / 4) * 100;
      var i = playerEl.querySelector('.zoom-thumb');
      if (i) {
        i.style.height = e + '%';
      }
    }
    function at(e, i) {
      if (!localStorage);
      else {
        localStorage[e] = i;
      }
    }
    function ot(e, i) {
      nt();
      e.style.scale = i;
      videojs.dom.removeClass(posterEl, hiddenClassname);
    }
  }
}
function nuevo(e) {
  this.ready(function () {
    initPlugin(this, e);
  });
}
if (typeof window != 'undefined') {
  videojs.registerPlugin('nuevo', nuevo);
}
export default nuevo;
