import clsx from 'clsx';

export const Spinner = ({ className = '' }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9974 3.5C11.2127 3.5 8.54205 4.60624 6.57298 6.57536C4.6039 8.54449 3.49768 11.2152 3.49768 14H0.497681C0.497681 10.4196 1.91995 6.98582 4.45163 4.45407C6.9833 1.92233 10.417 0.5 13.9974 0.5C17.5777 0.5 21.0114 1.92233 23.5431 4.45407C26.0748 6.98582 27.497 10.4196 27.497 14H24.497C24.497 11.2152 23.3908 8.54449 21.4217 6.57536C19.4527 4.60624 16.782 3.5 13.9974 3.5Z"
        fill="url(#paint0_linear_2006_1606)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.49768 14C3.49768 16.7848 4.6039 19.4555 6.57298 21.4246C8.54205 23.3938 11.2127 24.5 13.9974 24.5C16.782 24.5 19.4527 23.3938 21.4217 21.4246C23.3908 19.4555 24.497 16.7848 24.497 14H27.497C27.497 17.5804 26.0748 21.0142 23.5431 23.5459C21.0114 26.0777 17.5777 27.5 13.9974 27.5C10.417 27.5 6.9833 26.0777 4.45163 23.5459C1.91995 21.0142 0.497681 17.5804 0.497681 14H3.49768Z"
        fill="url(#paint1_linear_2006_1606)"
      />
      <path
        d="M1.99772 14C1.99685 13.9167 1.99685 13.8333 1.99772 13.75"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2006_1606"
          x1="1.99768"
          y1="2"
          x2="25.997"
          y2="2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.35" />
          <stop offset="1" stopColor="white" stopOpacity="0.5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2006_1606"
          x1="1.99768"
          y1="14"
          x2="25.997"
          y2="14"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.5" />
        </linearGradient>
      </defs>
    </svg>
  );
};
