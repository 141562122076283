import { checkIsInIframe } from './check-is-in-iframe';

export const getParentOrigin = () => {
  if (checkIsInIframe()) {
    const parentOrigin = document.referrer || document.location.ancestorOrigins[0];

    return parentOrigin ? new URL(parentOrigin).hostname : '';
  }

  return new URL(document.location.href).hostname;
};
