import { useState, useEffect, useRef } from 'react';
import { clsx } from 'clsx';
import DOMPurify from 'dompurify';

const configDOMPurify = {
  ALLOWED_TAGS: ['i', 'b'],
  KEEP_CONTENT: true,
};

interface MinimalDropdownOption {
  value: string;
  text: string;
}

interface DropdownProps<DropdownOption> {
  options: DropdownOption[];
  placeholder: string;
  onSelect: (option: DropdownOption) => void;
  disabled?: boolean;
  className?: string;
}

export const Dropdown = <DropdownOption extends MinimalDropdownOption>({
  options,
  placeholder,
  onSelect,
  disabled = false,
  className = '',
}: DropdownProps<DropdownOption>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<DropdownOption | null>(null);
  const [dropdownPosition, setDropdownPosition] = useState<'left' | 'right'>('left');
  const [maxHeight, setMaxHeight] = useState<number>(150);
  const [maxWidth, setMaxWidth] = useState<number>(150);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen && buttonRef.current && dropdownRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const viewportHeight =
        document.querySelector('.video-js')?.getBoundingClientRect()?.height || 150;
      const viewportWidth =
        document.querySelector('.video-js')?.getBoundingClientRect()?.height || 150;

      const padding = 16;
      const spaceBelow = viewportHeight - buttonRect.bottom;
      const spaceAbove = buttonRect.top;

      if (buttonRect.left + dropdownRect.width > window.innerWidth) {
        setDropdownPosition('right');
      } else {
        setDropdownPosition('left');
      }

      const calculatedMaxHeight = Math.max(spaceBelow, spaceAbove) - padding;
      setMaxWidth(viewportWidth - padding);
      setMaxHeight(calculatedMaxHeight);
    }
  }, [isOpen]);

  const toggleDropdown = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleOptionSelect = (option: DropdownOption) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
  };

  return (
    <div className={`relative w-full ${className}`} ref={dropdownRef}>
      <div className="w-full">
        <button
          type="button"
          className={clsx(
            'inline-flex justify-between w-full rounded-lg items-center border-0 shadow-sm px-3 py-2.5 bg-[#19191AF2] truncate text-base text-gray-300 hover:text-white focus:outline-none focus:ring-1 focus:ring-[#c5c5c5] focus:border-0',
            { 'opacity-50 cursor-not-allowed': disabled, 'bg-[#47474EF2]': isOpen },
          )}
          onClick={toggleDropdown}
          disabled={disabled}
          ref={buttonRef}
        >
          <div
            className="w-full text-left overflow-hidden truncate"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                selectedOption ? selectedOption.text : placeholder,
                configDOMPurify,
              ),
            }}
          ></div>
          <svg
            className={clsx('h-5 w-5 transition', { 'rotate-180': isOpen })}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div
          className={clsx(
            'absolute mt-1 px-1 py-2 overflow-y-auto min-w-[150px] rounded-lg shadow-lg bg-[#1B1B1FF2] ring-1 ring-black ring-opacity-5 focus:outline-none z-10 ',
            {
              'right-0': dropdownPosition === 'right',
              'left-0': dropdownPosition !== 'right',
            },
          )}
          ref={dropdownRef}
          style={{ maxHeight: `${maxHeight}px`, maxWidth: `${maxWidth}px` }}
        >
          <div role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {options.map((option) => (
              <button
                key={option.value}
                className={clsx(
                  'block w-full text-left px-3 py-2 text-[var(--secondary)] rounded hover:bg-[#42424680] hover:text-white',
                  { 'bg-[#42424680]': selectedOption?.value === option.value },
                )}
                role="menuitem"
                onClick={() => handleOptionSelect(option)}
              >
                <span
                  className="break-words block text-sm lg:text-base sm:truncate"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(option.text, configDOMPurify),
                  }}
                ></span>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
