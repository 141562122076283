import { useCallback, useEffect, useState } from 'react';
import { clsx } from 'clsx';
import Player from 'video.js/dist/types/player';
import { useLoadScript } from '@src/hooks/use-load-script';
import { getUrlParamsObject } from '@src/hooks/use-content-request/build-request-url';

type Props = {
  player: Player | null;
};

const PAUSE_BANNER_TYPE = 5;

const urlParams = getUrlParamsObject();

export const PauseBanner = ({ player }: Props) => {
  const [isVisible, setVisible] = useState(false);

  const handlePause = useCallback(() => {
    if (!player) {
      return;
    }

    const isAdPlaying = player.el().className.indexOf('vjs-ad-playing') > -1;
    if (isAdPlaying) {
      return;
    }

    const isSeeking = player.seeking();
    if (isSeeking) {
      return;
    }

    setVisible(true);
  }, [player]);

  const handlePlay = useCallback(() => {
    setVisible(false);
  }, []);

  useEffect(() => {
    if (!player) {
      return;
    }

    player.on('pause', handlePause);
    player.on('play', handlePlay);

    return () => {
      player.off('pause', handlePause);
      player.off('play', handlePlay);
    };
  }, [handlePause, handlePlay, player]);

  useLoadScript('https://cdn77.aj1907.online/63c0d7d8.js');

  return (
    <div className={clsx('absolute top-1/2 -translate-y-1/2', { hidden: !isVisible })}>
      <ins
        className="604c7625"
        data-key="d97d566093a59c2a62e1b7fc714905e9"
        // @ts-expect-error TODO: refactor later for typesafety
        data-cp-host={`${urlParams.clientId}|${PAUSE_BANNER_TYPE}|${urlParams.domain}`}
      />
    </div>
  );
};
