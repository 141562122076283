import { getApi, checkIsInIframe } from '@src/utils';

// TODO: remove before release
// const DEFAULT_MOVIE_PARAMS = {
//   clientId: 'CWfKXLc1ajId',
//   contentType: 'movie',
//   contentId: '1356',
// };
// const DEFAULT_SERIES_PARAMS = {
//   clientId: 'CWfKXLc1ajId',
//   contentType: 'tv-series',
//   contentId: '275',
// };

const buildBaseUrlParams = () => {
  const url = new URL(window.location.href);
  const [, clientId, contentType, contentId] = url.pathname.split('/');

  // In dev mode you can set DEFAULT_PARAMS here
  // return DEFAULT_MOVIE_PARAMS
  return {
    clientId,
    contentType,
    contentId,
  };
};

const processShortUrl = (params: Record<string, string>) => {
  const urlParams = new URLSearchParams(window.location.search);
  const kpId = urlParams.get('kp_id');
  const imdbId = urlParams.get('imdb_id');

  if (kpId) {
    delete params.contentId;
    params.contentType = 'short';
    params.kpId = kpId;
    return;
  }

  if (imdbId) {
    delete params.contentId;
    params.contentType = 'short';
    params.imdbId = imdbId;
  }
};

const addDomain = (params: Record<string, string>) => {
  const urlParams = new URLSearchParams(window.location.search);

  const domain = urlParams.get('domain');
  if (domain) {
    params.domain = domain;
    return;
  }

  if (checkIsInIframe() && document.referrer) {
    params.domain = document.referrer || document.location.ancestorOrigins[0];
  }
};

const addBlockParam = (params: Record<string, string>) => {
  const urlParams = new URLSearchParams(window.location.search);
  const block = urlParams.get('block');

  if (block) {
    params.block = block;
  }
};
export const getUrlParamsObject = () => {
  const urlParamsObject = buildBaseUrlParams();
  processShortUrl(urlParamsObject);
  addDomain(urlParamsObject);
  addBlockParam(urlParamsObject);

  return urlParamsObject;
};

export const buildRequestUrl = () => {
  const api = getApi();
  const urlParamsObject = getUrlParamsObject();
  const urlParams = new URLSearchParams(urlParamsObject);

  return `${api}/content?${urlParams.toString()}`;
};
