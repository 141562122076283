import videojs from 'video.js';

import { DEFAULT_OPTIONS } from './default-options';
import { checkIsTimeToDisplayAd } from '@src/utils';
import vastTools from './vast-tools';

const factory = (player, adsList, options) => {
  videojs.errorDisplay = false;

  try {
    options = videojs.obj.merge(DEFAULT_OPTIONS, options || {});
  } catch (e) {
    options = videojs.mergeOptions(DEFAULT_OPTIONS, options || {});
  }
  let a =
    '.vjs-touch-inactive .nv-nonlinear{bottom:10px!important}.vjs-user-inactive:not(.vjs-paused) .nv-nonlinear{bottom:10px!important}.nv-recall:hover{opacity:1}.nv-triangle{width:0px;height:0px;border-style:solid;border-width:0 6px 8px 6px;border-color:transparent transparent #444 transparent;transform: rotate(0deg);position:absolute;top:2px;left:6px;}.nv-recall{opacity:.7;position:absolute;width:26px;height:14px;box-shadow:1px 1px 1px rgba(0,0,0,.7);background:rgba(255,255,255,.7);border:solid 1px rgba(255,255,255,.7);border-bottom: 0;bottom:0;left:50%;margin-left:-13px;cursor:pointer;}.nonlinear-close{position:absolute;right:3px;top:3px;z-index:5;width:20px;height:20px;cursor:pointer;background-color:#e5e5e5;border-style:outset;border-width:1px;line-height:20px;color:#222;font-size:24px}.nv-nonlinear{bottom:60px;position:absolute;background:transparent;-webkit-transform:translateX(-50%);transform:translateX(-50%);left:50%;cursor:pointer;text-align:center;border:0;opacity:0;-webkit-transition:bottom .5s ease,opacity .5s ease;transition:bottom .5s ease,opacity .5s ease;z-index:21474836}.nv-visible{opacity:1;pointer-events:auto}.vjs-ad-playing .nv-nonlinear{opacity:0;pointer-events:none}.nv-nonlinear-close{}.nv-blocker{position:absolute;top:0;left:0;bottom:0;right:0;cursor:pointer;z-index:2}.nv-icon-bottom{bottom:80px}.nv-icon{animation: show 600ms ease forwards;opacity:0;transform: rotateX(-90deg);z-index:21474}@keyframes show {100%{opacity:1;transform:none}}.nv-skip{position:absolute;right:0;bottom:70px;background: rgba(0,0,0,.66);color:#fff;font-family:Arial;font-size:16px;border: solid 1px #666;padding:0 10px;line-height:35px}.nv-skip-active{cursor:pointer;pointer-events:auto;}.nv-skip-active:hover{color:#fff;border:solid 1px #999}';
  let o = k('style');
  o.innerHTML = a;
  player.el_.insertBefore(o, player.controlBar.el());
  let l = document.createElement('div');
  l.className = 'vjs-skin';
  player.el_.appendChild(l);
  let s = getComputedStyle(l, ':before').getPropertyValue('content');
  s = s.replace(/^["'](.+(?=["']$))["']$/, '$1');
  player.one('playing', function () {
    player.bigPlayButton.el_.style.display = 'none';
  });
  var adsToPlay = [];
  var u = {};
  var d = {
    preroll: false,
    vmap: [],
    adStarted: false,
    currentAdId: 0,
    firstPlay: false,
    adPlaying: false,
    muted: false,
    trackingEvents: [],
    skin: s,
    bottomIcons: [],
    snapshot: null,
    linear: [],
    nonlinear: [],
    companions: [],
    blocker: null,
    skip: -1,
    skipButton: null,
    currentPod: 0,
    labelStyle: null,
    divLabel: null,
  };
  function k(e, t) {
    let i = document.createElement(e);
    if (t) {
      i.className = t;
    }
    return i;
  }
  function T(e) {
    let t = '';
    if (e.universalAdIds) {
      if (e.universalAdIds.length > 1) {
        for (let i = 0; i < e.universalAdIds.length; i++) {
          if (e.universalAdIds[i].idRegistry) {
            t += e.universalAdIds[i].idRegistry + ' ' + e.universalAdIds[i].value + ',';
          }
        }
        t = t.slice(0, -1);
      } else if (e.universalAdIds.length > 0) {
        t = e.universalAdIds[0].idRegistry + ' ' + e.universalAdIds[0].value;
      }
    }
    if (t === '') {
      t = -1;
    } else {
      t = encodeURIComponent(t);
    }
    return t;
  }
  function b(t, i) {
    let r = -1;
    let n = -1;
    let a = null;
    if (i === 'linear' || i == 'icon') {
      a = d.linear[d.currentPod - 1];
    }
    if (i === 'nonlinear') {
      a = d.nonlinear;
    }
    if (i === 'companion') {
      a = d.companion;
    }
    if (a !== null) {
      n = T(a);
      if (a.adServingId) {
        r = a.adServingId;
      }
    }
    function o(e) {
      if (typeof e == 'number') {
        function t(e, t) {
          return ('000' + e).slice(t * -1);
        }
        var i = parseFloat(e).toFixed(3);
        var r = Math.floor(i / 60 / 60);
        var n = Math.floor(i / 60) % 60;
        var a = Math.floor(i - n * 60);
        var o = i.slice(-3);
        return t(r, 2) + ':' + t(n, 2) + ':' + t(a, 2) + '.' + t(o, 3);
      } else {
        return -1;
      }
    }
    const l = new Date();
    const s = l.getTime();
    const c = l.getTimezoneOffset() / 60;
    const u = l.getTimezoneOffset() * 60000;
    let p = encodeURIComponent(
      new Date(s - u).toISOString().slice(0, -1) + (c > 0 ? '-' : '+') + `0${c}`.slice(-2),
    );
    let f = Math.random().toString().slice(2, 10);
    let h = encodeURIComponent(navigator.userAgent);
    let m = encodeURIComponent(window.location.hostname);
    let v = encodeURIComponent(o(player.currentTime()));
    let g = '-1';
    if (player.muted());
    if (player.isFullscreen()) {
      g += ',fullscreen';
    }
    let y = parseInt(player.el().offsetWidth) + ',' + parseInt(player.el().offsetHeight);
    let k = encodeURIComponent(window.location.href);
    return (t = (t = (t = (t = (t = (t = (t = (t = (t = (t = (t = (t = (t = t.replace(
      '[CACHEBUSTING]',
      f,
    )).replace('[TIMESTAMP]', p)).replace('[DEVICEUA]', h)).replace('[DOMAIN]', m)).replace(
      '[PLAYERSTATE]',
      g,
    )).replace('[PLAYERSIZE]', y)).replace('[CONTENTPLAYHEAD]', v)).replace(
      '[ADPLAYHEAD]',
      v,
    )).replace('[MEDIAPLAYHEAD]', v)).replace('[PAGEURL]', k)).replace('[ADSERVINGID]', r)).replace(
      '[ADTYPE]',
      i,
    )).replace('[UNIVERSALADID]', n));
  }
  const A = function (e) {
    let t =
      '<VAST version="4.0" xmlns:xs="http://www.w3.org/2001/XMLSchema" xmlns="http://www.iab.com/VAST">';
    t += '<Ad><InLine><AdSystem version="4.0">IAB TechLab</AdSystem>';
    if (e.trackingURL && e.adId) {
      t +=
        '<Impression id="Impression-ID">' +
        e.trackingURL +
        '?action=impression&amp;id=' +
        e.adId +
        '</Impression>';
    }
    if (e.trackingURL && e.adId) {
      t += '<Error>' + e.trackingURL + '?action=error&amp;id=' + e.adId + '</Error>';
    }
    t += '<Creatives>';
    if (e.adId) {
      t += '<Creative adId="' + e.adId + '">';
    } else {
      t += '<Creative>';
    }
    if (e.skip) {
      var i = parseInt(e.skip);
      if (i > 0 && i < 60) {
        t += '<Linear skipoffset="00:00:' + i + '">';
      } else if (i >= 0) {
        t += '<Linear skipoffset="00:00:0' + i + '">';
      } else {
        t += '<Linear>';
      }
    } else {
      t += '<Linear>';
    }
    if (e.trackingURL && e.adId) {
      t += '<TrackingEvents>';
      t +=
        '<Tracking event="start">' +
        e.trackingURL +
        '?action=start&amp;id=' +
        e.adId +
        '</Tracking>';
      t +=
        '<Tracking event="firstQuartile">' +
        e.trackingURL +
        '?action=firstQuartile&amp;id=' +
        e.adId +
        '</Tracking>';
      t +=
        '<Tracking event="midpoint">' +
        e.trackingURL +
        '?action=midpoint&amp;id=' +
        e.adId +
        '</Tracking>';
      t +=
        '<Tracking event="thirdQuartile">' +
        e.trackingURL +
        '?action=thirdQuartile&amp;id=' +
        e.adId +
        '</Tracking>';
      t +=
        '<Tracking event="complete">' +
        e.trackingURL +
        '?action=complete&amp;id=' +
        e.adId +
        '</Tracking>';
      t +=
        '<Tracking event="pause">' +
        e.trackingURL +
        '?action=pause&amp;id=' +
        e.adId +
        '</Tracking>';
      t +=
        '<Tracking event="resume">' +
        e.trackingURL +
        '?action=resume&amp;id=' +
        e.adId +
        '</Tracking>';
      t +=
        '<Tracking event="skip">' + e.trackingURL + '?action=skip&amp;id=' + e.adId + '</Tracking>';
      t +=
        '<Tracking event="mute">' + e.trackingURL + '?action=mute&amp;id=' + e.adId + '</Tracking>';
      t +=
        '<Tracking event="unmute">' +
        e.trackingURL +
        '?action=unmute&amp;id=' +
        e.adId +
        '</Tracking>';
      t +=
        '<Tracking event="fullscreen">' +
        e.trackingURL +
        '?action=unmute&amp;id=' +
        e.adId +
        '</Tracking>';
      t +=
        '<Tracking event="exitFullscreen">' +
        e.trackingURL +
        '?action=unmute&amp;id=' +
        e.adId +
        '</Tracking>';
      t +=
        '<Tracking event="clickthrough">' +
        e.trackingURL +
        '?action=clickthrough&amp;id=' +
        e.adId +
        '</Tracking>';
      t += '</TrackingEvents>';
    }
    if (e.duration) {
      t += '<Duration>' + e.duration + '</Duration>';
    } else {
      t += '<Duration>00:00:00</Duration>';
    }
    t += '<MediaFiles>';
    if (Array.isArray(e.media)) {
      for (var r = 0; r < e.media.length; r++) {
        t += '<MediaFile type="' + e.media[r].type + '" scalable="1" maintainAspectRatio="1">';
        t += '<![CDATA[' + e.media[r].src + ']]>';
        t += '</MediaFile>';
      }
    } else {
      t += '<MediaFile type="' + e.media.type + '" scalable="1" maintainAspectRatio="1">';
      t += '<![CDATA[' + e.media.src + ']]>';
      t += '</MediaFile>';
    }
    t += '</MediaFiles>';
    t += '<VideoClicks>';
    t += '<ClickThrough id="blog">';
    t += '<![CDATA[' + e.clickthrough + ']]>';
    t += '</ClickThrough>';
    t += '</VideoClicks>';
    if (e.icon) {
      t += '<Icons>';
      t += '<Icon program="AdChoices" height="0" width="0" xPosition="10" yPosition="bottom">';
      t += '<StaticResource>';
      t += '<![CDATA[' + e.icon + ']]>';
      t += '</StaticResource>';
      t +=
        '<IconClicks><IconClickThrough><![CDATA[' +
        e.clickthrough +
        ' ]]></IconClickThrough></IconClicks>';
      t += '</Icon>';
      t += '</Icons>';
    }
    t += '</Linear>';
    return (t += '</Creative></Creatives></InLine></Ad></VAST>');
  };
  player.on('ended', function () {
    if (d.fin) {
      return;
    }
    if (player.el().className.indexOf('vjs-ad-playing') < 0) {
      B();
      for (let e = 0; e < adsToPlay.length; e++) {
        if (adsToPlay[e].timeOffset === 'end' && adsToPlay[e].used !== true) {
          // This prevents hotkeys from scrolling the video
          videojs.options.blockKeys = true;

          d.adPlaying = true;
          adsToPlay[e].used = true;
          setTimeout(function () {
            playAd(e);
          }, 120);
        }
      }
    }
  });
  const R = function () {
    if (adsList.ads) {
      var e = [];
      if (!Array.isArray(adsList.ads)) {
        e.push(adsList.ads);
      } else {
        e = adsList.ads;
      }
      for (var idx = 0; idx < e.length; idx++) {
        let t = options.withCredentials;
        let n = options.timeout;
        let a = options.resolveAll;
        let o = options.wrapperLimit;
        let l = options.allowMultipleAds;
        let s = options.clickthroughMethod;
        let u = options.closeNonlinearButton;
        let d = options.closeNonlinearTheme;
        let p = options.nonlinearRecall;
        let f = 0;
        let h = null;
        let m = options.autoClose;
        let v = options.outstreamClose;
        let g = options.brandText;
        let y = options.brandUrl;
        let k = options.outstreamCloseTheme;
        let adConfig = e[idx];
        let b = '';

        if (adConfig.timeOffset) {
          f = adConfig.timeOffset;
        }
        if (adConfig.config) {
          if (adConfig.config.withCredentials) {
            t = adConfig.config.withCredentials;
          }
          if (adConfig.config.clickthroughMethod) {
            s = adConfig.config.clickthroughMethod;
          }
          if (adConfig.config.timeout) {
            n = adConfig.config.timeout;
          }
          if (adConfig.config.resolveAll) {
            a = adConfig.config.resolveAll;
          }
          if (adConfig.config.wrapperLimit) {
            o = adConfig.config.wrapperLimit;
          }
          if (adConfig.config.allowMultipleAds) {
            l = adConfig.config.allowMultipleAds;
          }
          if (adConfig.config.closeNonlinearButton !== undefined) {
            u = adConfig.config.closeNonlinearButton;
          }
          if (adConfig.config.autoClose) {
            m = adConfig.config.autoClose;
          }
          if (adConfig.config.brandText !== undefined) {
            g = options.brandText = adConfig.config.brandText;
          }
          if (adConfig.config.brandUrl !== undefined) {
            y = options.brandUrl = adConfig.config.brandUrl;
          }
          if (adConfig.config.outstreamClose) {
            v = adConfig.config.outstreamClose;
          }
          if (adConfig.config.outstreamCloseTheme !== undefined) {
            k = adConfig.config.outstreamCloseTheme;
          }
          if (adConfig.config.closeNonlinearTheme) {
            d = adConfig.config.closeNonlinearTheme;
          }
          if (adConfig.config.nonlinearRecall) {
            p = adConfig.config.nonlinearRecall;
          }
        }
        if (adConfig.tagUrl) {
          if (adConfig.withCredentials) {
            t = adConfig.withCredentials;
          }
          if (adConfig.tagUrl) {
            b = adConfig.tagUrl;
          }
          if (adConfig.fallbackURL) {
            h = adConfig.fallbackURL;
          }
          adsToPlay.push({
            outstream: false,
            autoClose: m,
            brandText: g,
            brandUrl: y,
            outstreamCloseTheme: k,
            outstreamClose: v,
            used: false,
            tagUrl: b,
            fallbackURL: h,
            nonlinearRecall: p,
            closeButton: u,
            closeTheme: d,
            timeOffset: f,
            withCredentials: t,
            timeout: n,
            resolveAll: a,
            wrapperLimit: o,
            allowMultipleAds: l,
            clickthroughMethod: s,
          });
        } else if (adConfig.media && adConfig.clickthrough) {
          let e = A(adConfig);
          let t = new DOMParser().parseFromString(e, 'text/xml');
          adsToPlay.push({
            used: false,
            tagXML: t,
            autoClose: m,
            timeOffset: f,
            brandText: g,
            brandUrl: y,
            outstreamCloseTheme: k,
            outstreamClose: v,
            nonlinearRecall: p,
            closeButton: u,
            closeTheme: d,
            clickthroughMethod: s,
          });
        }
      }
    } else {
      let e = options.withCredentials;
      let r = options.timeout;
      let n = options.resolveAll;
      let a = options.wrapperLimit;
      let o = options.allowMultipleAds;
      let l = options.clickthroughMethod;
      let s = options.closeNonlinearButton;
      let u = options.closeNonlinearTheme;
      let d = options.nonlinearRecall;
      let p = 0;
      let f = null;
      let h = options.autoClose;
      let m = options.outstreamClose;
      let v = options.brandText;
      let g = options.brandUrl;
      let y = options.outstreamCloseTheme;
      let k = null;
      let T = new Array();
      if (adsList.tagUrl) {
        T = adsList.tagUrl;
        if (adsList.fallbackURL) {
          f = adsList.fallbackURL;
        }
        if (adsList.config) {
          if (adsList.config.withCredentials) {
            e = adsList.config.withCredentials;
          }
          if (adsList.config.clickthroughMethod) {
            l = adsList.config.clickthroughMethod;
          }
          if (adsList.config.timeout) {
            r = adsList.config.timeout;
          }
          if (adsList.config.resolveAll) {
            n = adsList.config.resolveAll;
          }
          if (adsList.config.wrapperLimit) {
            a = adsList.config.wrapperLimit;
          }
          if (adsList.config.allowMultipleAds) {
            o = adsList.config.allowMultipleAds;
          }
          if (adsList.config.closeNonlinearButton !== undefined) {
            s = adsList.config.closeNonlinearButton;
          }
          if (adsList.config.autoClose) {
            h = adsList.config.autoClose;
          }
          if (adsList.config.brandText !== undefined) {
            v = options.brandText = adsList.config.brandText;
          }
          if (adsList.config.brandUrl !== undefined) {
            g = options.brandUrl = adsList.config.brandUrl;
          }
          if (adsList.config.outstreamClose) {
            m = adsList.config.outstreamClose;
          }
          if (adsList.config.outstreamCloseTheme !== undefined) {
            y = adsList.config.outstreamCloseTheme;
          }
          if (adsList.config.closeNonlinearTheme) {
            u = adsList.config.closeNonlinearTheme;
          }
          if (adsList.config.nonlinearRecall) {
            d = adsList.config.nonlinearRecall;
          }
        }
        if (adsList.withCredentials) {
          e = adsList.withCredentials;
        }
        if (adsList.autoplay) {
          options.autoplay = adsList.autoplay;
        }
        k = {
          tagUrl: T,
          used: false,
          autoClose: h,
          brandText: v,
          brandUrl: g,
          outstreamCloseTheme: y,
          outstreamClose: m,
          withCredentials: e,
          timeOffset: 0,
          fallbackURL: f,
          nonlinearRecall: d,
          closeButton: s,
          closeTheme: u,
          timeout: r,
          resolveAll: n,
          wrapperLimit: a,
          allowMultipleAds: o,
          clickthroughMethod: l,
        };
      } else if (adsList.tagXML) {
        if (adsList.config) {
          if (adsList.config.clickthroughMethod) {
            l = adsList.config.clickthroughMethod;
          }
          if (adsList.closeButton) {
            s = adsList.closeButton;
          }
          if (adsList.closeTheme) {
            u = adsList.closeTheme;
          }
          if (adsList.nonlinearRecall) {
            d = adsList.nonlinearRecall;
          }
          if (adsList.config.autoClose) {
            h = adsList.config.autoClose;
          }
          if (adsList.config.outstreamClose) {
            m = adsList.config.outstreamClose;
          }
          if (adsList.config.brandText !== undefined) {
            v = options.brandText = adsList.config.brandText;
          }
          if (adsList.config.brandUrl !== undefined) {
            g = options.brandUrl = adsList.config.brandUrl;
          }
          if (adsList.config.outstreamCloseTheme !== undefined) {
            y = adsList.config.outstreamCloseTheme;
          }
        }
        (T = new Array()).push(adsList.tagXML);
        k = {
          tagXML: adsList.tagXML,
          used: false,
          timeOffset: 0,
          autoClose: h,
          brandText: v,
          brandUrl: g,
          outstreamCloseTheme: y,
          outstreamClose: m,
          closeButton: s,
          nonlinearRecall: d,
          closeTheme: u,
          clickthroughMethod: l,
        };
      } else if (adsList.media && adsList.clickthrough) {
        if (adsList.config) {
          if (adsList.config.clickthroughMethod) {
            l = adsList.config.clickthroughMethod;
          }
          if (adsList.config.autoClose) {
            h = adsList.config.autoClose;
          }
          if (adsList.config.outstreamClose) {
            m = adsList.config.outstreamClose;
          }
          if (adsList.config.brandText !== undefined) {
            v = options.brandText = adsList.config.brandText;
          }
          if (adsList.config.brandUrl !== undefined) {
            g = options.brandUrl = adsList.config.brandUrl;
          }
          if (adsList.config.outstreamCloseTheme !== undefined) {
            y = adsList.config.outstreamCloseTheme;
          }
        }
        let e = A(adsList);
        let r = new DOMParser().parseFromString(e, 'text/xml');
        adsToPlay.push({
          used: false,
          tagXML: r,
          autoClose: h,
          timeOffset: p,
          brandText: v,
          brandUrl: g,
          outstreamCloseTheme: y,
          outstreamClose: m,
          nonlinearRecall: d,
          closeButton: s,
          closeTheme: u,
          clickthroughMethod: l,
        });
      }
      if (k) {
        adsToPlay.push(k);
      }
    }
  };
  function w(e) {
    let t = null;
    function r() {
      try {
        const e = new window.XMLHttpRequest();
        if ('withCredentials' in e) {
          return e;
        } else {
          return null;
        }
      } catch (e) {
        return null;
      }
    }
    let n = e.tagUrl;
    let a = options.timeout;
    if (e.timeout) {
      a = e.timeout;
    }
    let o = options.withCredentials;
    if (e.withCredentials) {
      o = e.withCredentials;
    }
    if (window.location.protocol === 'https:' && n.indexOf('http://') === 0) {
      console.log('XHR URLHandler: Cannot go from HTTPS to HTTP.');
    }
    try {
      const t = r();
      t.open('GET', n);
      t.timeout = a;
      t.withCredentials = o;
      if (t.overrideMimeType) {
        t.overrideMimeType('text/xml');
      }
      t.onload = () => s(t);
      t.onerror = () => l(t, false);
      t.onabort = () => l(t, false);
      t.ontimeout = () => l(t, true);
      t.send();
    } catch (t) {
      E(e);
    }
    function l(t, r) {
      if (!r) {
        t.status;
      }
      if (r) {
        console.log('XHR URLHandler: Request timed out after ' + options.timeout + ' +ms.');
      }
      E(e);
    }
    function s(i) {
      if (i.status === 200) {
        try {
          if ((t = i.responseXML).documentElement.localName !== 'VMAP') {
            E(e);
          } else {
            c(t);
          }
        } catch (t) {
          E(e);
        }
      } else {
        l(i, false);
      }
    }
    function c(t) {
      var r = [];
      for (var n in t.documentElement.childNodes) {
        var a = t.documentElement.childNodes[n];
        if (a.localName == 'AdBreak') {
          var o = L(a);
          r.push(o);
        }
      }
      function l(e) {
        let [t = 0, i = 0, r = 0] = e.split(':').reverse();
        return +r * 3600 + +i * 60 + +t;
      }
      let s = new Array();
      if (!(r.length > 0));
      else {
        for (var c = 0; c < r.length; c++) {
          if (r[c].adSource && !r[c].adSource.uri && r[c].timeOffset) {
            var u = r[c].timeOffset;
            if (typeof u == 'string' && u.toLowerCase() !== 'start' && u.toLowerCase() !== 'end') {
              u = l(u);
            }
            if (typeof u == 'string') {
              if ((u = u.toLowerCase()) === 'start') {
                u = 0;
              }
            }
            var d = {
              tagUrl: r[c].adSource,
              id: r[c].adId,
              timeOffset: u,
            };
            if (e.withCredentials) {
              d.widthCredentials = e.withCredentials;
            }
            if (e.timeout) {
              d.timeout = e.timeout;
            }
            if (e.resolveAll) {
              d.resolveAll = e.resolveAll;
            }
            if (e.wrapperLimit) {
              d.wrapperLimit = e.wrapperLimit;
            }
            if (e.allowMultipleAds) {
              d.allowMultipleAds = e.allowMultipleAds;
            }
            if (e.clickthroughMethod) {
              d.clickthroughMethod = e.clickthroughMethod;
            }
            if (e.closeButton) {
              d.closeButton = e.closeButton;
            }
            if (e.closeTheme) {
              d.closeTheme = e.closeTheme;
            }
            if (e.nonlinearRecall) {
              d.nonlinearRecall = e.nonlinearRecall;
            }
            if (e.autoClose) {
              d.autoClose = e.autoClose;
            }
            if (e.outstreamClose) {
              d.outstreamClose = e.outstreamClose;
            }
            if (e.brandText) {
              d.brandText = options.brandText = e.brandText;
            }
            if (e.brandUrl) {
              d.brandUrl = options.brandUrl = e.brandUrl;
            }
            if (e.outstreamCloseTheme) {
              d.outstreamCloseTheme = e.outstreamCloseTheme;
            }
            s.push(d);
          }
        }
        E(s);
      }
    }
  }
  function L(e) {
    var t = new Object();
    t.timeOffset = e.getAttribute('timeOffset');
    t.breakType = e.getAttribute('breakType');
    t.breakId = e.getAttribute('breakId');
    t.adSource = null;
    for (var i in e.childNodes) {
      var r = e.childNodes[i];
      switch (r.localName) {
        case 'AdSource':
          var n = a(r);
          t.adSource = n.adTagURI;
          t.adId = n.id;
      }
    }
    function a(e) {
      var t = new Object();
      t.id = e.getAttribute('id');
      t.allowMultipleAds = e.getAttribute('allowMultipleAds');
      t.followRedirects = e.getAttribute('followRedirects');
      t.vastAdData = null;
      t.adTagURI = null;
      t.customData = null;
      for (var i in e.childNodes) {
        var r = e.childNodes[i];
        switch (r.localName) {
          case 'AdTagURI':
            t.adTagURI = {
              templateType: r.getAttribute('templateType'),
              uri: (r.textContent || r.text || '').trim(),
            };
            t.adTagURI = (r.textContent || r.text || '').trim();
            break;
          case 'VASTAdData':
            t.vastAdData = r.firstChild;
            while (t.vastAdData && this.vastAdData.nodeType !== 1) {
              this.vastAdData = this.vastAdData.nextSibling;
            }
            break;
          case 'CustomAdData':
            t.customData = r;
        }
      }
      return t;
    }
    return t;
  }
  const C = function (t) {
    d.outstream = true;
    var r =
      '.outstream-controls,.outstream-seek-bar {bottom: 0;width: 98%;left:1%;position:absolute}';
    r +=
      '.outstream-label{font-size:12px;color:#fff;width:auto;text-shadow:1px 1px 1px #000;line-height:38px;float:left;padding:0 5px}';
    r +=
      '.outstream-controls {background:linear-gradient(transparent,rgba(48,48,48,.55));width:100%;left:0;height: 45px;padding:0;opacity:1;z-index: 97}';
    r +=
      '.outstream-controls .out-svg-icon{display:flex;background-repeat:no-repeat;background-position:center;fill:#fff;height:24px;width:24px;filter: drop-shadow(1px 1px 1px #000);margin: 0 auto;}';
    r +=
      '.outstream-countdown{line-height:38px;font-size:12px;color:#fff;font-family:Arial,sans-serif;padding:0 10px;float:left;text-shadow: 1px 1px 1px #000;color: #fff;}';
    r +=
      '.outstream-seek-bar {bottom:8px;height:2px;margin: 0;background-color:transparent;max-width:100%}';
    r +=
      '.outstream-seek-bar:before{background-color: rgba(255, 255, 255, .3);content:"";height: 2px;left:1%;position:absolute;top: 0;width: 98%;border:0;cursor: pointer}';
    r +=
      '.outstream-progress{height:2px;left:1%;position:absolute;top: 0;width:0;background: #FC0}';
    r +=
      '.outstream-fullscreen,.outstream-muted,.outstream-play-pause {margin-top:5px;background:transparent;position:relative;padding:0;color:#fff;display:block;cursor:pointer;width:40px}';
    r += '.outstream-play-pause{float:left;left:2px}.outstream-fullscreen{float: right;right: 5px}';
    r += '.outstream-muted {float: right}';
    r +=
      '.video-js .vjs-auto-muted{transform-origin:left top;scale:1;top:50%;left:50%;position:absolute;height:65px;width:65px;color:#fff;line-height:40px;cursor:pointer;z-index:6;transform:translate(-50%, -50%);border:solid 3px rgba(255,255,255,1);background:rgba(0,0,0,.25);-webkit-border-radius:8px;border-radius:50%;scale:1.5}';
    r += '.video-js .vjs-auto-muted .vjs-svg-icon{height:50px;width:50px;display:flex;fill:#fff}';
    r += '.vjs-320 .outstream-countdown {display:none}';
    r +=
      '.vjs-480 .outstream-fullscreen,.vjs-480 .outstream-muted,.vjs-480 .outstream-play-pause{width:35px}';
    r +=
      '.endcap-outstream {position:absolute;top:0;left:0;bottom:0;right:0;background-color:#000;z-index:1009}';
    r +=
      '.outstream-endcap{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);display:table;text-align:center;color:#fff;font-family:Arial;font-weight:normal;text-decoration:none;font-style:normal}';
    r += '.outstream-endcap .ln1{font-size:40px;padding-bottom:7px}.vjs-480 .ln1{font-size:30px}';
    r +=
      '.outstream-endcap .brand{color:#777;font-size:12px;text-decoration:none;padding-top:10px;display:table;margin:0 auto}';
    r += '.outstream-endcap .brand:hover {color:lightblue}';
    r += '.outstream-close:hover{border-color:#aaa}';
    r +=
      '.outstream-close {cursor:pointer;border:solid 1px #777;border-right:0;border-top:0;position:absolute;right:0;top:0;color:#ccc;font-size:24px;background:#222;height:30px;width:30px;line-height:30px;text-align:center;}.outstream-close-out{border:solid 1px #777;border-bottom:0;top:-30px}.oustream-close-light{background:#f0f0f0;color:#444;border-color:#ccc}';
    r += '.outstream-close-light{background:#f0f0f0;color:#444;border-color:#ccc}';
    const n = player.$('.vjs-tech');
    var a = k('style');
    a.innerHTML = r;
    player.el_.insertBefore(a, n.nextSibling);
    var o =
      '<svg xmlns="http://www.w3.org/2000/svg"> <defs> <symbol id="out-icon-fullscreen" viewBox="0 0 512 512"> <path d="M137 304H88v120h120v-49h-71v-71zm-48-96h48v-71h71V88H89v120zm286 167h-71v49h120V304h-49v71zM304 89v48h71v71h49V89H304z"></path> </symbol> <symbol id="out-icon-fullscreen-back" viewBox="0 0 512 512"> <path d="M89 352h71v71h48V305H89v48zm71-192H89v48h119V89h-48v71zm144 264h48v-72h71v-48H305v120zm48-264V89h-48v119h120v-48h-72z"></path> </symbol> <symbol id="out-icon-mute" viewBox="0 0 512 512"> <path d="M341 254q0-25-13-46t-34-31v42l47 47v-12zm48 0q0 13-3 25t-8 25l29 28q9-17 14-37t6-41q0-31-11-58-10-27-27-49t-42-38q-25-15-53-22v39q20 7 38 19 17 12 30 29t20 37q7 20 7 43zM110 83l-25 24 90 90H85v114h76l95 94V278l81 80q-10 8-21 13t-22 10v39q19-5 37-13t33-21l38 38 25-24L110 83zm146 19-39 39 39 40v-79z"></path> </symbol> <symbol id="out-icon-pause" viewBox="0 0 512 512"> <path d="M133 400h82V112h-82v288zm164-288v288h82V112h-82z"></path> </symbol> <symbol id="out-icon-play" viewBox="0 0 512 512"> <path d="M137 105v303l238-152z"></path> </symbol> <symbol id="out-icon-volume" viewBox="0 0 512 512"> <path d="M78 193v119h79l99 99V94l-99 99H78zm267 59q0-26-13-47t-36-33v160q22-11 36-32t13-48zM296 79v40q21 7 39 20 19 12 32 30t20 38q8 22 8 45t-8 45q-7 21-20 39t-32 30q-18 12-39 19v41q29-7 55-23 25-16 43-39t30-51 10-61-10-60-30-52-43-39q-26-16-55-22z"></path> </symbol></defs> </svg>';
    const l = new window.DOMParser().parseFromString(o, 'image/svg+xml');
    if (l.querySelector('parsererror')) {
      console.log('SVG Symbols parsing Error');
    } else {
      const t = l.documentElement;
      t.style.display = 'none';
      player.el_.insertBefore(t, n.nextSibling);
    }
    function s(e, t, i, r) {
      var n = document.createElement(e);
      if (t !== undefined) {
        if (t !== '') {
          n.className = t;
        }
      }
      if (i !== undefined) {
        Object.entries(i).forEach((e) => {
          const [t, i] = e;
          n.setAttribute(t, i);
        });
      }
      if (r !== undefined) {
        if (r !== '') {
          n.innerHTML = r;
        }
      }
      return n;
    }
    var c = {
      type: 'button',
      'aria-disabled': 'false',
    };
    var u = '<span class="out-svg-icon"><svg viewBox="0 0 32 32"><use href=""></use></svg></span>';
    var p = s('div', 'outstream-controls vjs-hidden');
    var f = s('button', 'outstream-play-pause', c, u);
    var h = s('button', 'outstream-muted', c, u);
    var m = s('button', 'outstream-fullscreen', c, u);
    var v = s('div', 'outstream-seek-bar');
    var g = s('div', 'outstream-progress');
    var y = s('div', 'outstream-countdown', {}, '0:00 / 0:00');
    var T = s('div', 'outstream-label', {}, w());
    v.appendChild(g);
    p.appendChild(f);
    p.appendChild(T);
    p.appendChild(y);
    p.appendChild(m);
    p.appendChild(h);
    p.appendChild(v);
    player.el_.appendChild(p);
    if (t.outstreamClose) {
      let i = k('div');
      let r = 'outstream-close';
      if (t.outstreamCloseTheme == 'light') {
        r += ' outstream-close-light';
      }
      i.className = r;
      i.innerHTML = '&times;';
      player.el_.appendChild(i);
      i.onclick = i.ontouchend = function (t) {
        t.stopImmediatePropagation();
        player.el().parentNode.removeChild(player.el());
      };
    }
    var b = n.getAttribute('autoplay');
    player.el_.style.visibility = 'visible';
    var A = player.el_.querySelector('.vjs-big-play-button');
    if (player.autoplay() || options.autoplay || b) {
      player.muted(true);
      setTimeout(function () {
        player.play();
      }, 100);
    }
    let R = false;
    player.on('playing', function () {
      if (!R) {
        R = true;
        x();
      }
      if (A) {
        A.removeAttribute('style');
      }
    });
    f.querySelector('svg').innerHTML = '<use href="#out-icon-play"></use>';
    h.querySelector('svg').innerHTML = '<use href="#out-icon-volume"></use>';
    m.querySelector('svg').innerHTML = '<use href="#out-icon-fullscreen"></use>';
    player.controlBar.el().classList.add('vjs-hidden');
    player.one('playing', function () {
      p.className = 'outstream-controls';
    });
    f.onclick = function (t) {
      t.preventDefault();
      if (player.paused()) {
        player.play();
      } else {
        player.pause();
      }
    };
    m.onclick = function (t) {
      t.preventDefault();
      if (player.isFullscreen()) {
        player.exitFullscreen();
      } else {
        player.requestFullscreen();
      }
    };
    h.onclick = function (t) {
      t.preventDefault();
      if (player.muted()) {
        player.muted(false);
      } else {
        player.muted(true);
      }
    };
    player.on('pause', function () {
      f.querySelector('svg').innerHTML = '<use href="#out-icon-play"></use>';
    });
    player.on('play', function () {
      f.querySelector('svg').innerHTML = '<use href="#out-icon-pause"></use>';
    });
    player.on('timeupdate', function () {
      let t = player.currentTime() / player.duration();
      g.style.width = t * 100 + '%';
      y.innerHTML =
        videojs.time.formatTime(player.currentTime()) +
        ' / ' +
        videojs.time.formatTime(player.duration());
    });
    player.on('fullscreenchange', function (t) {
      if (player.isFullscreen()) {
        m.querySelector('svg').innerHTML = '<use href="#out-icon-fullscreen-back"></use>';
      } else {
        m.querySelector('svg').innerHTML = '<use href="#out-icon-fullscreen"></use>';
      }
    });
    player.on('volumechange', function (t) {
      if (player.muted()) {
        h.querySelector('svg').innerHTML = '<use href="#out-icon-mute"></use>';
      } else {
        h.querySelector('svg').innerHTML = '<use href="#out-icon-volume"></use>';
      }
    });
    function w() {
      if (player.el_.offsetWidth > 480) {
        return player.localize('Advertisement');
      } else {
        return player.localize('Ad');
      }
    }
    player.on('playerresize', function () {
      T.innerHTML = w();
      var t = player.el().offsetWidth;
      player.el().classList.remove('vjs-480');
      player.el().classList.remove('vjs-320');
      if (t < 320) {
        player.el().classList.add('vjs-320');
      } else if (t < 480) {
        player.el().classList.add('vjs-480');
      }
    });
    document.addEventListener('scroll', x, {
      passive: true,
    });
  };
  function x() {
    if (d.outstream) {
      if (player.el()) {
        if (player.currentSrc()) {
          var t = player.el_.getBoundingClientRect();
          let i = window.innerHeight || document.documentElement.clientHeight;
          if (t.bottom < 70 || t.top > i - 20) {
            if (!player.paused()) {
              player.pause();
              player.autopause = true;
            }
          } else if (player.autopause) {
            player.autopause = false;
            player.play();
          }
        }
      }
    }
  }
  const E = function (i) {
    if (i.length > 0) {
      let e = null;
      if (adsList.config) {
        e = adsList.config;
      }
      (adsList = new Object()).ads = i;
      if (e) {
        for (let i = 0; i < adsList.ads.length; i++) {
          adsList.ads[i].config = e;
        }
      }
    } else {
      adsList = new Object();
      let e = [];
      e.push(i);
      adsList.ads = e;
    }
    R();
    if (adsToPlay.length > 0) {
      if (!player.src()) {
        for (var r = 0; r < adsToPlay.length; r++) {
          adsToPlay[r].timeOffset = 0;
        }
        C(adsToPlay[0]);
      }
      if (adsToPlay[0].timeOffset === 0) {
        adsToPlay[0].used = true;
        d.preroll = true;
        setTimeout(function () {
          playAd(0);
        }, 100);
      }
    }
  };
  if (adsList.tagUrl) {
    w(adsList);
  } else {
    R();
    if (adsToPlay.length > 0) {
      if (!player.src()) {
        for (var I = 0; I < adsToPlay.length; I++) {
          adsToPlay[I].timeOffset = 0;
        }
        C(adsToPlay[0]);
      }
      if (adsToPlay[0].timeOffset === 0) {
        adsToPlay[0].used = true;
        d.preroll = true;
        setTimeout(function () {
          playAd(0);
        }, 100);
      }
    }
  }
  const U = function (t) {
    let i = player.el().querySelector('.vjs-replay-button');
    let r = player.el().querySelector('.vjs-sharing-overlay');
    let n = player.el().querySelector('.vjs-grid');
    let a = player.el().querySelector('.vjs-cend');
    if (i) {
      o(i, t);
    }
    if (r) {
      o(r, t);
    }
    if (n) {
      o(n, t);
    }
    if (a) {
      o(a, t);
    }
    function o(e, t) {
      if (t) {
        e.style.visibility = 'hidden';
        e.style.pointerEvents = 'none';
      } else {
        e.removeAttribute('style');
      }
    }
  };
  const N = function (e) {
    var t = e.el().querySelector('.vjs-tech');
    var i = {
      ended: e.ended(),
      src: e.currentSrc(),
      currentTime: e.currentTime(),
      duration: e.duration(),
      currentSource: e.currentSource(),
      type: e.currentType(),
      playing: !e.paused(),
      suppressedTracks: r(e),
    };
    if (i.duration) {
      if (i.duration - i.currentTime < 1) {
        i.ended = true;
      }
    }
    if (i.ended) {
      i.currentTime = e.duration() + 1;
    }
    if (i.duration) {
      if (i.duration - i.currentTime < 1) {
        i.ended = true;
      }
    }
    if (t) {
      i.nativePoster = t.poster;
      i.style = t.getAttribute('style');
    }
    U(true);
    return i;
    function r(e) {
      var t = e.remoteTextTracks ? e.remoteTextTracks() : [];
      if (t && Array.isArray(t.tracks_)) {
        t = t.tracks_;
      }
      if (!Array.isArray(t)) {
        t = [];
      }
      var i = [];
      t.forEach(function (e) {
        i.push({
          track: e,
          mode: e.mode,
        });
        e.mode = 'disabled';
      });
      return i;
    }
  };
  const S = function (e, t) {
    if (e.src() !== t.src) {
      var i = e.el().querySelector('.vjs-tech');
      var r = 20;
      if ('style' in t) {
        i.setAttribute('style', t.style || '');
      }
      e.one('loadedmetadata', a);
      e.one('canplay', o);
      n();
      e.src(t.currentSource);
      U(false);
    } else {
      e.play();
    }
    function n() {
      var t = setTimeout(function () {
        e.trigger('canplay');
      }, 1000);
      e.one('canplay', function () {
        clearTimeout(t);
      });
    }
    function a() {
      t.suppressedTracks.forEach(function (e) {
        e.track.mode = e.mode;
      });
    }
    function o() {
      e.play();
      if (!l(e) && r--) {
        setTimeout(o, 50);
      } else {
        try {
          if (e.currentTime() !== t.currentTime) {
            if (t.playing) {
              e.one('seeked', function () {
                if (!t.ended) {
                  e.play();
                }
              });
            }
            e.currentTime(t.currentTime);
          } else if (t.playing) {
            e.play();
          }
        } catch (e) {
          videojs.log.warn('Failed to resume the content after an advertisement', e);
        }
      }
    }
    function l(e) {
      if (e.readyState() > 1) {
        return true;
      }
      if (e.seekable() === undefined) {
        return true;
      }
      if (e.seekable().length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };
  const playAd = function playAd(idx) {
    let i = adsToPlay[idx];
    d.currentAdId = idx;
    if (i.tagXML) {
      new vastTools.VASTParser()
        .parseVAST(i.tagXML)
        .then((e) => {
          let t = e;
          t.clickthroughMethod = i.clickthroughMethod;
          t.closeButton = i.closeButton;
          t.closeTheme = i.closeTheme;
          t.nonlinearRecall = i.nonlinearRecall;
          O(t);
        })
        .catch((e) => {
          console.log(e.toString());
          console.log('Ad cancelled');
        });
    }
    if (i.tagUrl) {
      let r = {
        withCredentials: i.withCredentials,
        timeout: i.timeout,
        resolveAll: i.resolveAll,
        wrapperLimit: i.wrapperLimit,
        allowMultipleAds: i.allowMultipleAds,
      };
      new vastTools.VASTParser()
        .getAndParseVAST(i.tagUrl, r)
        .then((e) => {
          let t = e;
          t.clickthroughMethod = i.clickthroughMethod;
          t.closeButton = i.closeButton;
          t.closeTheme = i.closeTheme;
          t.nonlinearRecall = i.nonlinearRecall;
          t.autoClose = i.autoClose;
          t.brandText = i.brandText;
          t.brandUrl = i.brandUrl;
          t.outstreamCloseTheme = i.outstreamCloseTheme;
          t.oustreamClosePosition = i.oustreamClosePosition;
          if (t) {
            O(t);
          }
        })
        .catch((r) => {
          if (i.fallbackURL) {
            i.tagUrl = i.fallbackURL;
            i.fallbackURL = null;
            playAd(idx);
          }

          player.trigger('vastEvent', {
            eventName: 'adcancelled',
          });

          console.log(r.toString());
          console.log('Ad cancelled');
        });
    }
  };
  player.on('timeupdate', function () {
    if (d.adPlaying) {
      return;
    }

    for (let e = 0; e < adsToPlay.length; e++) {
      const adToPlay = adsToPlay[e];

      if (options.skipTimer) {
        if (
          adToPlay.used !== true &&
          adToPlay.timeOffset !== 'end' &&
          checkIsTimeToDisplayAd(adToPlay.timeOffset, player)
        ) {
          d.currentAdId = e;
          d.adStarted = true;
          adToPlay.used = true;
          setTimeout(function () {
            playAd(e);
          }, 200);
          break;
        }
      } else if (
        adToPlay.used !== true &&
        adToPlay.timeOffset !== 'end' &&
        checkIsTimeToDisplayAd(adToPlay.timeOffset, player)
      ) {
        d.currentAdId = e;
        d.adStarted = true;
        adToPlay.used = true;
        setTimeout(function () {
          playAd(e);
        }, 200);
        break;
      }
    }
  });
  function O(e) {
    if ((t = e.ads).length < 1) {
      console.log('Error. Empty VAST response.');
      console.log('Ad cancelled.');
      if (adsToPlay[d.currentAdId].fallbackURL) {
        adsToPlay[d.currentAdId].tagUrl = adsToPlay[d.currentAdId].fallbackURL;
        adsToPlay[d.currentAdId].fallbackURL = null;
        playAd(d.currentAdId);
      }
    }
    d.clickthrough = e.clickthroughMethod;
    d.closeButton = e.closeButton;
    d.closeButton = e.closeButton;
    d.closeTheme = e.closeTheme;
    d.nonlinearRecall = e.nonlinearRecall;
    var t = e.ads;
    d.companions = [];
    d.linear = [];
    d.nonlinears = [];
    for (var i = 0; i < t.length; i++) {
      if (t[i].creatives) {
        for (var r = 0; r < t[i].creatives.length; r++) {
          let e = false;
          if (t[i].creatives[r].type === 'linear') {
            if (t[i].impressionURLTemplates && !e) {
              e = true;
              t[i].creatives[r].impressionURLTemplates = t[i].impressionURLTemplates;
            }
            if (t[i].adServingId) {
              t[i].creatives[r].adServingId = t[i].adServingId;
            }
            d.linear.push(t[i].creatives[r]);
          }
          if (t[i].creatives[r].type == 'companion') {
            if (t[i].impressionURLTemplates && !e) {
              e = true;
              t[i].creatives[r].impressionURLTemplates = t[i].impressionURLTemplates;
            }
            if (t[i].adServingId) {
              t[i].creatives[r].adServingId = t[i].adServingId;
            }
            d.companions.push(t[i].creatives[r]);
          }
          if (t[i].creatives[r].type === 'nonlinear') {
            if (t[i].impressionURLTemplates && !e) {
              e = true;
              t[i].creatives[r].impressionURLTemplates = t[i].impressionURLTemplates;
            }
            if (t[i].adServingId) {
              t[i].creatives[r].adServingId = t[i].adServingId;
            }
            d.nonlinears.push(t[i].creatives[r]);
          }
        }
      }
    }
    if (d.linear.length > 0) {
      q(d.linear[0]);
    }
    if (d.nonlinears.length > 0 && d.outstream !== true) {
      P(d.nonlinears[0]);
    }
    if (d.companions.length > 0 && d.outstream !== true) {
      D(d.companions[0]);
    }
  }
  function P(e) {
    if (!d.outstream) {
      d.nonlinear = e;
      if (e.variations) {
        j(e, 0);
      }
    }
  }
  const D = function (e) {
    if (!d.outstream) {
      d.companion = e;
      if (e.variations) {
        for (var t = 0; t < e.variations.length; t++) {
          H(e, t);
        }
      }
    }
  };
  function B() {
    var t = player.el().querySelector('.nv-nonlinear');
    if (t) {
      t.parentNode.removeChild(t);
    }
    var i = player.el().querySelector('.nv-recall');
    if (i) {
      i.parentNode.removeChild(i);
    }
    player.off('playerresize', V);
    d.minSuggestedDuration = 0;
    d.suggestedStart = 0;
  }
  const V = function () {
    var t = player.el().offsetWidth;
    var i = player.el().offsetHeight;
    var r = player.el().querySelector('.nv-nonlinear');
    if (r) {
      if (r.offsetWidth > t || r.offsetHeight > i) {
        r.style.opacity = 0;
        el_styke.pointerEvents = 'none';
      } else {
        r.style.bottom = ue() + 'px';
        r.style.removeProperty('opacity');
        r.style.removeProperty('pointerEvents');
      }
    }
  };
  function j(t, i) {
    B();
    d.nonlinear = t;
    d.nonlinearId = undefined;
    if (t.adId) {
      d.nonlinearId = t.adId;
    }
    if (t.variations[i].adType === 'nonLinearAd') {
      let s = t.variations[i];
      var r = '';
      var n = '';
      if (s.htmlResource) {
        r = s.htmlResource;
        n = 'html';
      } else if (s.iframeResource) {
        r = s.iframeResource;
        contentType = 'iframe';
        n = 'iframe';
      } else if (s.staticResource) {
        r = '<img style="cursor:pointer" src="' + s.staticResource + '"/>';
        n = 'static';
      }
      B();
      let c = k('div', 'nv-nonlinear');
      let u = k('div');
      if (s.width) {
        t = c.style.width = F(s.width);
      }
      if (s.height) {
        t = c.style.height = F(s.height);
      }
      if (s.expandedWidth) {
        c.style.maxWidth = F(s.expandedWidth);
      }
      if (s.expandedHeight) {
        c.style.maxHeight = F(s.expandedHeight);
      }
      if (s.nonlinearClickThroughURLTemplate && n == 'static') {
        u.onclick = u.ontouchend = function (e) {
          e.stopPropagation();
          window.open(s.nonlinearClickThroughURLTemplate, '_blank');
          if (s.nonlinearClickTrackingURLTemplates) {
            var t = s.nonlinearClickTrackingURLTemplates;
            t.forEach(function (e) {
              if (t.url) {
                X('clickthrough', t.url, 'nonlinear');
              }
            });
          }
        };
      }
      let p = k('div', 'nonlinear-close');
      if (s.minSuggestedDuration) {
        p.className = 'nonlinear-close vjs-hidden';
      }
      p.setAttribute('role', 'button');
      p.innerHTML = '&times;';
      if (d.closeTheme === 'dark') {
        p.style.backgroundColor = '#222';
        p.style.color = '#e5e5e5';
        p.style.borderColor = '#777';
        p.style.paddingLeft = '2px';
      }
      c.style.bottom = ue() + 'px';
      let f = null;
      player.on('playerresize', V);
      if (n === 'iframe') {
        let e = k('iframe');
        e.src = iframeResource;
        e.scrolling = 'no';
        e.style.width = '100%';
        e.style.height = '100%';
        e.style.border = 'none';
        e.style.overflow = 'hidden';
        e.appendChild(r);
        u.innerHTML = r;
        e.appendChild(u);
        c.appendChild(e);
      } else {
        u.innerHTML = r;
        if (d.closeButton && n == 'static') {
          u.appendChild(p);
        }
        c.appendChild(u);
        if (d.nonlinearRecall && n == 'static') {
          (f = k('div', 'nv-recall vjs-hidden')).setAttribute('role', 'button');
          f.innerHTML = '<div class="nv-triangle"></div>';
          c.appendChild(f);
        }
      }
      if (f && n == 'static') {
        f.onclick = f.ontouchend = function () {
          f.classList.add('vjs-hidden');
          u.removeAttribute('style');
        };
      }
      if (p && n == 'static') {
        p.onclick = p.ontouchend = function (e) {
          e.stopImmediatePropagation();
          if (f) {
            u.style.opacity = 0;
            u.style.pointerEvents = 'none';
            f.className = 'nv-recall';
          } else {
            c.remove();
          }
        };
      }
      player.el().appendChild(c);
      if (c.offsetWidth > player.el().offsetWidth || c.offsetHeight > player.el().offsetHeight) {
        c.style.display = 'none';
      }
      function a() {
        setTimeout(function () {
          if (t.impressionURLTemplates) {
            t.impressionURLTemplates.forEach(function (e) {
              if (e.url) {
                X('impression', e.url, 'nonlinear');
              }
            });
          }
          c.className = 'nv-nonlinear nv-visible';
          d.preroll = false;
        }, 1000);
        if (p && n == 'static') {
          if (s.minSuggestedDuration) {
            setTimeout(
              function () {
                p.className = 'nonlinear-close';
              },
              s.minSuggestedDuration * 1000 + 0.3,
            );
          }
        }
      }
      let h = false;
      function o() {
        if (!d.adPlaying && !h) {
          h = true;
          a();
          player.off('timeupdate', o);
        }
      }
      function l() {
        player.on('timeupdate', o);
      }
      if (player.paused !== true) {
        player.on('timeupdate', o);
      } else {
        player.one('playing', l);
      }
    }
  }
  function F(e) {
    let t = null;
    if (/^\d+(\.\d+)?%$/.test(e) || e == 'auto') {
      t = e;
    } else {
      t = Number(e) + 'px';
    }
    return t;
  }
  function H(t, i) {
    d.companionId = undefined;
    if (t.adId) {
      d.companionId = t.adId;
    }
    let r = false;
    function n(n) {
      if (t.variations[i].adType === 'companionAd') {
        let i = t.variations[n];
        let o = '';
        let l = '';
        if (i.htmlResources.length > 0) {
          o = i.htmlResources[0];
          l = 'html';
        } else if (i.iframeResources.length > 0) {
          o = i.iframeResources[0];
          l = 'iframe';
        } else if (i.staticResources.length > 0) {
          if (i.staticResources[0].creativeType) {
            if (
              ['image/gif', 'image/jpeg', 'image/png'].indexOf(i.staticResources[0].creativeType)
            ) {
              if (i.altText) {
                o =
                  '<img style="width:100%;height:100%;cursor:pointer" alt="' +
                  i.altText +
                  '" src="' +
                  i.staticResources[0].url +
                  '"/>';
              } else {
                o =
                  '<img style="width:100%;height:100%;cursor:pointer" src="' +
                  i.staticResources[0].url +
                  '"/>';
              }
              l = 'static';
            }
          }
        }
        if (o !== '') {
          let t = null;
          if (i.adSlotId) {
            let e = document.getElementById(i.adSlotId);
            if (e) {
              (t = e).innerHTML = '';
            }
          }
          if (!t) {
            var a = document.querySelectorAll('.vjs-companion');
            if (a) {
              for (let e = 0; e < a.length; e++) {
                if (!a[e].className.includes('vjs-companion-ex')) {
                  a[e].classList.add('vjs-companion-ex');
                  (t = a[e]).innerHTML = '';
                  break;
                }
              }
            }
          }
          if (t && t.innerHTML === '') {
            let n = k('div', 'asset');
            if (l === 'iframe') {
              let e = k('iframe');
              e.src = o;
              e.scrolling = 'no';
              e.style.width = '100%';
              e.style.height = '100%';
              e.style.border = 'none';
              e.style.overflow = 'hidden';
              n.appendChild(e);
            } else if (l === 'static' || l === 'html') {
              n.innerHTML = o;
            }
            t.appendChild(n);
            if (i.assetWidth) {
              n.style.width = F(i.assetWidth);
            }
            if (i.assetHeight) {
              n.style.height = F(i.assetHeight);
            }
            if (i.width) {
              t.style.width = F(i.width);
            }
            if (i.height) {
              t.style.height = F(i.height);
            }
            if (i.expandedWidth) {
              t.style.maxWidth = F(i.expandedWidth);
            }
            if (i.expandedHeight) {
              t.style.maxHeight = F(i.expandedHeight);
            }
            if (i.companionClickThroughURLTemplate && l == 'static') {
              t.onclick = function () {
                player.pause();
                window.open(i.companionClickThroughURLTemplate, '_blank');
                if (i.companionClickTrackingURLTemplates) {
                  i.companionClickTrackingURLTemplates.forEach(function (e) {
                    if (e.url) {
                      X('clickthrough', e.url, 'ompanion');
                    }
                  });
                  if (i.trackingEvents) {
                    if (i.trackingEvents.clickthrough) {
                      X('clickthrough', i.trackingEvents.clickthrough, 'companion');
                    }
                  }
                }
              };
            }
            if (i.TrackingEvents) {
              if (i.TrackingEvents.creativeView) {
                X('view', i.TrackingEvents.creativeView, 'companion');
              }
            }
            if (d.companion.impressionURLTemplates && r !== true) {
              r = true;
              d.companion.impressionURLTemplates.forEach(function (e) {
                if (e.url) {
                  X('impression', e.url, 'companion');
                }
              });
            }
          }
        }
      }
    }
    if (!player.paused()) {
      n(i);
    } else {
      player.one('playing', function () {
        n(i);
      });
    }
  }
  function q(e) {
    d.linearId = undefined;
    if (e.adId) {
      d.linearId = e.adId;
    }
    if (d.currentPod == 0) {
      d.currentPod = 1;
    }
    d.firstPause = false;
    d.trackingEvents = [];
    u = {};
    let t = {
      start: null,
      pause: null,
      resume: null,
      firstQuartile: null,
      midpoint: null,
      thirdQuartile: null,
      mute: null,
      unmute: null,
      fullscreen: null,
      exitFullscreen: null,
      complete: null,
      skip: null,
      clickthrough: null,
    };
    d.linearEvents = e.trackingEvents || [];
    Object.keys(t).forEach((t) => {
      if (e.trackingEvents[t]) {
        if (e.trackingEvents[t][0]) {
          d.linearEvents[t] = e.trackingEvents[t][0];
        }
      }
    });
    if (e.mediaFiles) {
      let t = Y(e.mediaFiles);
      if (t[0].mimeType) {
        e.mediatoplay = {
          src: t[0].fileURL,
          type: t[0].mimeType,
        };
      } else {
        e.mediatoplay = {
          src: t[0].fileURL,
        };
      }
    }
    if (e.mediatoplay.src) {
      W(e);
    } else {
      ie();
    }
  }
  const z = function (t) {
    if (!d.outstream) {
      d.icons = [];
      if (t.icons) {
        if (t.icons.length > 0) {
          for (var i = 0; i < t.icons.length; i++) {
            var r = t.icons[i];
            var n = '';
            if (r.staticResource) {
              n = '<img src="' + r.staticResource + '">';
            } else if (r.htmlResource) {
              n = r.htmlResource;
            } else if (r.iframeResource) {
              n = r.iframeResource;
            }
            var a = 'left';
            var o = 0;
            var l = 'top';
            var s = 0;
            var c = 0;
            if (r && r.xPosition === 'left') {
              a = 'left';
              o = 0;
            }
            if (r && r.xPosition === 'right') {
              a = 'right';
              o = 0;
            }
            if (r && Number(r.xPosition) >= 0) {
              a = 'left';
              o = r.xPosition;
            }
            if (r && r.yPosition === 'bottom') {
              l = 'bottom';
              s = 0;
            }
            if (r && r.yPosition === 'top') {
              l = 'top';
              s = 0;
              s += c;
            }
            if (r && Number(r.yPosition) >= 0) {
              l = 'top';
              s = Number(r.yPosition);
              s += c;
            }
            var u = k('div', 'nv-icon');
            u.setAttribute('name', 'adicon');
            player.el().appendChild(u);
            if (r.yPosition === 'bottom') {
              u.style.bottom = de() + 'px';
              d.bottomIcons.push(u);
            }
            u.id = 'adicon_' + r.program;
            if (r.type === 'iframe') {
              var p = k('iframe');
              p.src = r.content;
              p.scrolling = 'no';
              p.style.width = '100%';
              p.style.height = '100%';
              p.style.border = 'none';
              p.style.overflow = 'hidden';
              u.appendChild(p);
            } else {
              u.innerHTML = n;
            }
            u.style.position = 'absolute';
            if (r && r.xPosition === 'center') {
              u.style.left = '50%';
              u.style.webkitTransform = 'translateX(-50%)';
              u.style.transform = 'translateX(-50%)';
            } else {
              u.style[a] = o + 'px';
            }
            if (r && r.yPosition === 'center') {
              u.style.top = '50%';
              if (r && r.xPosition === 'center') {
                u.style.webkitTransform = 'translate(-50%,-50%)';
                u.style.transform = 'translate(-50%,-50%)';
              } else {
                u.style.transform = 'translateY(-50%)';
                u.style.webkitTransform = 'translateY(-50%)';
              }
            } else if (r.yPosition !== 'bottom') {
              u.style[l] = s + 'px';
            }
            r.origY = s;
            if (r.iconClickThroughURLTemplate) {
              u.style.cursor = 'pointer';
              u.onclick = r.ontouchend = function (e) {
                e.stopImmediatePropagation();
                window.open(r.iconClickThroughURLTemplate, '_blank');
                if (r.iconClickTrackingURLTemplates) {
                  r.iconClickTrackingURLTemplates.forEach(function (e) {
                    if (e.url) {
                      X('clickthrough', e.url, 'icon');
                    }
                  });
                }
              };
            }
            if (r.iconViewTrackingURLTemplate) {
              X('view', r.iconViewTrackingURLTemplate, 'icon');
            }
          }
        }
      }
    }
  };
  function W(t) {
    // This prevents hotkeys from scrolling the video
    videojs.options.blockKeys = true;

    d.adPlaying = true;
    var i = player.controlBar.el().querySelector('.vjs-vast-label');
    var r = player.el().querySelector('.outstream-label');
    if (r) {
      i = r;
    }
    if (d.snapshot === null) {
      d.snapshot = N(player);
    }
    t.used = true;
    player.src(t.mediatoplay);
    if (t.videoClickThroughURLTemplate && d.clickthrough !== 'link') {
      if (t.videoClickThroughURLTemplate.url) {
        re(t.videoClickThroughURLTemplate.url);
      }
    }
    if (d.clickthrough === 'link') {
      se(d.currentPod, t.videoClickThroughURLTemplate.url);
    } else if (i) {
      i.classList.remove('vjs-hidden');
      let e = le();
      if (d.linear.length > 1) {
        e += ' (' + d.currentPod + '/' + d.linear.length + ')';
      }
      i.innerHTML = e;
    }
    ae();
    const n = function () {
      d.firstPlay = true;
      player.el_.classList.add('vjs-ad-playing');
      if (t.impressionURLTemplates) {
        t.impressionURLTemplates.forEach(function (e) {
          if (e.url) {
            X('impression', e.url, 'linear');
          }
        });
      }
      X('start', d.linearEvents.start, 'linear');

      // CHANGES: start
      if (t.skipDelay) {
        d.skip = t.skipDelay;
        addSkipButton();
        ce();
      } else {
        addVideoStartSoon();
      }
      // CHANGES: end

      z(t);
      let i = player.el_.querySelectorAll('.nv-icon');
      if (i) {
        for (var r = 0; r < i.length; r++) {
          i[r];
        }
      }
    };
    player.on('timeupdate', Q);
    player.one('ended', te);
    player.one('playing', n);
    player.on('playerresize', G);
    player.on('volumechange', Z);
    player.on('play', K);
    player.on('pause', $);
    player.on('fullscreenchange', J);
    player.on('error', _);
    if (!d.preroll) {
      d.preroll = true;
      player.play();
    }
    d.preroll = false;
  }
  function _() {
    console.log('Media Error.Ad Cancelled');
    d.preroll = true;
    ee();
  }
  function X(t, i, r) {
    if (!i) {
      return;
    }
    let n = undefined;
    if (r == 'linear') {
      n = d.linearId;
    }
    if (r == 'nonlinear') {
      n = d.nonlinearId;
    }
    if (r == 'companion') {
      n = d.companionId;
    }
    if (Array.isArray(i)) {
      i.forEach(function (i) {
        new Image().src = b(i, r);
        player.trigger('vastEvent', {
          eventName: t,
          adType: r,
          adId: n,
        });
      });
    } else {
      new Image().src = b(i, r);
      player.trigger('vastEvent', {
        eventName: t,
        adType: r,
        adId: n,
      });
    }
  }
  const G = function () {
    let t = player.el().offsetWidth;
    if (d.divLabel) {
      d.divLabel.style.top = pe() + 'px';
    }
    if (d.bottomIcons.length > 0) {
      for (var i = 0; i < d.bottomIcons.length; i++) {
        let e = de();
        d.bottomIcons[i].style.bottom = e + 'px';
      }
    }
    ce();
    let r = player.controlBar.el().querySelector('.vjs-vast-label');
    if (r) {
      let i = r.innerHTML.split(player.localize('Advertisement')).join('');
      i = i.split(player.localize('Ad')).join('');
      if (t < 480) {
        r.innerHTML = player.localize('Ad') + i;
      } else {
        r.innerHTML = player.localize('Advertisement') + i;
      }
    }
  };
  const Q = function () {
    let t = parseInt(player.currentTime());
    let i = parseInt(player.duration());
    if (i > 0) {
      if (t > parseInt(i / 4) && !u.firstQuartile) {
        u.firstQuartile = true;
        X('firstQuartile', d.linearEvents.firstQuartile, 'linear');
      }
      if (t > parseInt(i / 2) && !u.midpoint) {
        u.midpoint = true;
        X('midpoint', d.linearEvents.midpoint, 'linear');
      }
      if (t > parseInt(i * 0.75) && !u.thirdQuartile) {
        u.thirdQuartile = true;
        X('thirdQuartile', d.linearEvents.thirdQuartile, 'linear');
      }
    }
    if (d.skip > 0 && d.skipButton) {
      let t = false;
      let i = parseInt(d.skip - player.currentTime());
      if (i > 0) {
        d.skipButton.innerHTML = player.localize('Skip Ad in') + ' ' + i;
      } else {
        d.skipButton.innerHTML = player.localize('Skip Ad');
        d.skipButton.classList.add('nv-skip-active');
        t = true;
      }
      d.skipButton.onclick = d.skipButton.ontouchend = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (t) {
          X('skip', d.linearEvents.skip, 'linear');
          ee();
        } else {
          return false;
        }
      };
    }
  };
  function Y(t) {
    var i = player.el().getBoundingClientRect().width;
    t.sort(function (e, t) {
      return Math.abs(i - e.width) - Math.abs(i - t.width);
    });
    return t;
  }
  const K = function () {
    if (d.firstPause) {
      X('resume', d.linearEvents.resume, 'linear');
    }
  };
  const $ = function () {
    d.firstPause = true;
    X('pause', d.linearEvents.pause, 'linear');
  };
  const Z = function () {
    if (player.muted() && d.muted == 0) {
      d.muted = true;
      X('mute', d.linearEvents.mute, 'linear');
    }
    if (player.muted() !== true && d.muted) {
      d.muted = false;
      X('unmute', d.linearEvents.unmute, 'linear');
    }
  };
  const J = function () {
    if (player.isFullscreen()) {
      X('fullscreen', d.linearEvents.fullscreen, 'linear');
    } else {
      X('exitFullscreen', d.linearEvents.exitFullscreen, 'linear');
    }
  };
  const ee = function () {
    player.off('timeupdate', Q);
    player.off('playerresize', G);
    player.off('volumechange', Z);
    player.off('play', K);
    player.off('pause', $);
    player.off('fullscreenchange', J);
    let t = player.el().querySelectorAll('.nv-icon');
    if (t) {
      for (var i = 0; i < t.length; i++) {
        t[i].remove();
      }
    }
    ae();
    oe();
    d.currentPod = 0;
    d.skip = -0;
    let r = 0;
    let n = false;
    d.autoClose = adsToPlay[d.currentAdId].autoClose;
    if (d.linear.length > 0) {
      for (i = 0; i < d.linear.length; i++) {
        if (!d.linear[i].used) {
          r = i;
          break;
        }
      }
      if (r > 0) {
        player.loadingSpinner.el_.style.display = 'none';
        d.currentPod = i + 1;
        q(d.linear[r]);
      } else {
        if (d.outstream) {
          adsToPlay = [];
          ie();
          player.trigger('adsCompleted');
          n = true;
        }
        if (n !== true) {
          for (let t = d.currentAdId; t < adsToPlay.length; t++) {
            if (
              adsToPlay[t].used === false &&
              adsToPlay[t].timeOffset == adsToPlay[d.currentAdId].timeOffset
            ) {
              n = true;
              player.loadingSpinner.el_.style.display = 'none';
              d.currentAdId = t;

              // This prevents hotkeys from scrolling the video
              videojs.options.blockKeys = true;

              d.adPlaying = true;
              adsToPlay[t].used = true;
              setTimeout(function () {
                playAd(t);
              }, 200);
              break;
            }
          }
        }
        if (n !== true) {
          let t = true;
          for (let e = d.currentAdId; e < adsToPlay.length; e++) {
            if (!adsToPlay[e].used) {
              t = false;
            }
          }
          if (t || d.outstrem) {
            player.trigger('adsCompleted');
          }
          ie();
        }
      }
    } else {
      if (d.outstream) {
        adsToPlay = [];
        ie();
        n = true;
      }
      for (let e = d.currentAdId; e < adsToPlay.length; e++) {
        if (
          adsToPlay[e].used === false &&
          adsToPlay[e].timeOffset == adsToPlay[d.currentAdId].timeOffset
        ) {
          n = true;
          d.currentAdId = e;

          // This prevents hotkeys from scrolling the video
          videojs.options.blockKeys = true;

          d.adPlaying = true;
          adsToPlay[e].used = true;
          setTimeout(function () {
            playAd(e);
          }, 200);
          break;
        }
      }
      let t = true;
      for (let e = d.currentAdId; e < adsToPlay.length; e++) {
        if (!adsToPlay[e].used) {
          t = false;
        }
      }
      if (t || d.outstrem) {
        player.trigger('adsCompelted');
      }
      if (n !== true) {
        ie();
      }
    }
  };
  const te = function () {
    X('complete', d.linearEvents.complete, 'linear');
    ee();
  };
  const ie = function () {
    // This allows to use hotkeys to rewind video
    videojs.options.blockKeys = false;
    d.adPlaying = false;
    d.adStarted = false;
    if (d.outstream) {
      if (d.autoClose) {
        player.pause();
        d.fin = true;
        player.el().parentNode.removeChild(player.el());
      } else {
        d.fin = true;
        player.pause();
        let t = k('div', 'endcap-outstream');
        let r = k('div', 'outstream-endcap');
        r.innerHTML = '<div class="ln1">' + player.localize('THANKS<br>FOR WATCHING') + '</div>';
        if (options.brandText && options.brandUrl) {
          r.innerHTML +=
            '<a class="brand" href="' + options.brandUrl + '">' + options.brandText + '</a>';
        }
        t.appendChild(r);
        player.el().appendChild(t);
      }
    }
    d.currentAdId = -1;
    player.loadingSpinner.el_.removeAttribute('style');
    player.off('ended', te);
    player.off('error', _);
    if (d.blocker) {
      d.blocker.remove();
    }
    player.el_.classList.remove('vjs-ad-playing');
    if (d.snapshot && d.fin !== true) {
      S(player, d.snapshot);
    }
    if (d.skip) {
      d.skip.remove();
    }
    d.snapshot = null;
    d.adPlaying = false;
  };
  const re = function (t) {
    // CHANGES: start
    // adds icon to skip button
    if (null == d.blocker) d.blocker = k('div', 'nv-blocker');
    d.blocker.onclick = d.blocker.ontouchend = function (i) {
      if (player.paused()) {
        player.play();
      } else if (d.firstPlay) {
        i.stopPropagation();
        window.open(t, '_blank');
        X('clickthrough', d.linearEvents.clickthrough, 'linear');
        if (d.linear[d.currentPod - 1].videoClickTrackingURLTemplates) {
          let e = d.linear[d.currentPod - 1].videoClickTrackingURLTemplates;
          if (e)
            e.forEach(function (e) {
              if (e.url) X('clickthrough', e.url, 'linear');
            });
        }
        if (!player.paused()) {
          player.pause();
        }
      }
    };
    player.el().insertBefore(d.blocker, player.controlBar.el());
  };
  const addSkipButton = function () {
    if (d.skipButton) {
      d.skipButton.remove();
    }
    d.skipButton = k('div');
    let t = 'nv-skip',
      i = player.localize('Skip Ad in') + ' ' + d.skip;
    if (0 === d.skip) {
      t = 'nv-skip nv-skip-active';
      i = player.localize('Skip Ad');
    }
    d.skipButton.className = t;
    d.skipButton.innerHTML = i;
    player.el().appendChild(d.skipButton);
  };
  const addVideoStartSoon = function () {
    if (d.skipButton) d.skipButton.remove();
    d.skipButton = k('div');
    let t = 'nv-skip',
      i = player.localize('Video Starts Soon');
    d.skipButton.className = t;
    d.skipButton.innerHTML = i;
    player.el().appendChild(d.skipButton);
  };
  // CHANGES: end
  const ae = function () {
    if (d.skipButton) {
      d.skipButton.parentNode.removeChild(d.skipButton);
      d.skipButton = null;
      d.skip = -1;
    }
  };
  const oe = function () {
    if (d.labelStyle) {
      d.labelStyle.parentNode.removeChild(d.labelStyle);
      d.labelStyle = null;
    }
    if (d.divLabel) {
      d.divLabel.parentNode.removeChild(d.divLabel);
      d.divLabel = null;
    }
  };
  function le() {
    if (player.el_.offsetWidth < 480) {
      return player.localize('Ad');
    } else {
      return player.localize('Advertisement');
    }
  }
  function se(t, i) {
    let r = player.controlBar.el().querySelector('.vjs-vast-label');
    if (r) {
      r.classList.add('vjs-hidden');
    }
    if (!d.labelStyle) {
      let t =
        '.nv-vast-div{position: absolute;font-size:12px;left:10px;top:-20px;color:#e5e5e5;text-shadow:1px 1px 1px #000;font-weight:normal}.nv-vast-div .nv-vast-timer{padding:0 10px 0 5px;}.nv-vast-div .nv-vast-pod {padding:0 5px;}.nv-vast-div .nv-label-icon{width:18px;height:16px;display:inline-block;padding:1px;margin-left:5px;opacity:.8;vertical-align:middle;margin-top:-2px}.nv-vast-div a{cursor: pointer;color: #e5e5e5;text-decoration:none}';
      d.labelStyle = k('style');
      d.labelStyle.innerHTML = t;
      player.controlBar.el().appendChild(d.labelStyle);
    }
    if (!d.divLabel) {
      d.divLabel = k('div', 'nv-vast-div');
    }
    var n = '';
    if (d.linear.length > 1) {
      var a = player.localize('Ad %%NUM%% of %%TOTAL%%');
      n =
        '<span class="nv-vast-pod">' +
        (a = (a = a.replace('%%NUM%%', d.currentPod.toString())).replace(
          '%%TOTAL%%',
          d.linear.length.toString(),
        )) +
        '</span>';
    } else {
      n += player.localize('Ad');
    }
    let o = i.replace(/^https?:\/\//, '');
    if ((o = (o = o.replace(/:\d+/, '')).replace('www.', '')).length > 20) {
      o = o.substring(0, 20) + '...';
    }
    let l =
      '<span class="nv-label-icon"><svg fill="#fff" height="100%" version="1.1" viewBox="0 0 48 48" width="100%"><path d="M0 0h48v48H0z" fill="none"></path><path d="M38 38H10V10h14V6H10c-2.21 0-4 1.79-4 4v28c0 2.21 1.79 4 4 4h28c2.21 0 4-1.79 4-4V24h-4v14zM28 6v4h7.17L15.51 29.66l2.83 2.83L38 12.83V20h4V6H28z"></path></svg></span>' +
      o;
    let s = k('span');
    s.style.cursor = 'pointer';
    s.innerHTML = l;
    d.divLabel.innerHTML = n;
    d.divLabel.appendChild(s);
    d.divLabel.style.top = pe() + 'px';
    s.onclick = s.ontouchend = function (t) {
      t.stopImmediatePropagation();
      window.open(i, '_blank');
      X('clickthrough', d.linearEvents.clickthrough, 'linear');
      if (d.linear[d.currentPod - 1].videoClickTrackingURLTemplates) {
        let e = d.linear[d.currentPod - 1].videoClickTrackingURLTemplates;
        if (e) {
          e.forEach(function (e) {
            if (e.url) {
              X('clickthrough', e.url, 'linear');
            }
          });
        }
      }
      if (!player.paused()) {
        player.pause();
      }
    };
    if (d.outstream) {
      player.el().querySelector('.outstream-controls').appendChild(d.divLabel);
    } else {
      player.controlBar.el().appendChild(d.divLabel);
    }
  }
  const ce = function () {
    if (d.skipButton) {
      let t = player.el().offsetWidth;
      let i = 70;
      switch (d.skin) {
        case 'slategrey':
        case 'shaka':
        case 'roundal':
        case 'jwlike':
          if (t > 1080) {
            i = 90;
          }
          break;
        case 'treso':
          i = 70;
          if (t > 1080) {
            i = 90;
          }
          break;
        case 'mockup':
          if (t > 1080) {
            i = 100;
          }
      }
      if (d.outstream) {
        i = 60;
      }
      d.skipButton.style.bottom = i + 'px';
    }
  };
  const ue = function () {
    let t = player.el().offsetWidth;
    let i = 60;
    switch (d.skin) {
      case 'treso':
        i = 60;
        break;
      case 'slategrey':
        i = 65;
        if (t > 1080) {
          i = 70;
        }
        break;
      case 'pinko':
      case 'party':
      case 'nuevo':
        i = 55;
        if (t > 1080);
        break;
      case 'mockup':
        i = 55;
        if (t > 1080) {
          i = 70;
        }
        break;
      case 'jwlike':
        if (t > 1080) {
          i = 70;
        }
        break;
      case 'flow':
        i = 55;
        if (t > 1080) {
          i = 60;
        }
    }
    return i;
  };
  const de = function () {
    let t = player.el().offsetWidth;
    var i = 60;
    switch (d.skin) {
      case 'party':
        if (t < 1080) {
          i = 50;
        }
        break;
      case 'treso':
        i = 70;
        break;
      case 'chrome':
        i = 50;
        if (t < 480) {
          i = 60;
        }
        break;
      case 'mockup':
        i = 70;
        if (t > 1080) {
          i = 90;
        }
        break;
      case 'flow':
        i = 50;
        if (t > 1080) {
          i = 60;
        }
        break;
      case 'jwlike':
        if (t > 1080) {
          i = 70;
        }
        break;
      case 'nuevo':
        i = 50;
        if (t > 1080) {
          i = 60;
        }
        break;
      case 'roundal':
        if (t < 480) {
          i = 50;
        }
        break;
      case 'slategrey':
      case 'shaka':
      case 'party':
        if (t > 1080) {
          i = 70;
        }
    }
    if (d.outstream) {
      i = 45;
    }
    if (d.clickthrough === 'link') {
      i += 30;
    }
    return i;
  };
  const pe = function () {
    let t = -30;
    let i = player.el().offsetWidth;
    switch (d.skin) {
      case 'treso':
        t = -50;
        break;
      case 'mockup':
        t = -60;
        if (i > 1080) {
          t = -70;
        }
        break;
      case 'jwlike':
        t = -40;
    }
    if (d.outstream) {
      t = -25;
    }
    return t;
  };
  return this;
};
function vast(adsList, options) {
  this.ready(function () {
    factory(this, adsList, options);
  });
}

const Plugin = videojs.getPlugin('plugin');

class VastPlugin extends Plugin {
  constructor(player, adsList, options) {
    super(player);

    this.player = player;
    this.adsList = adsList;
    this.options = options;

    player.ready(function () {
      factory(player, adsList, options);
    });
  }

  restart() {
    this.player.currentTime(0);
    factory(this.player, this.adsList, this.options);
  }
}

var getPlugin = videojs.getPlugin || videojs.plugin;
var registerPlugin = videojs.registerPlugin || videojs.plugin;
if (getPlugin('vast') === undefined || getPlugin('vast') === 'undefined') {
  registerPlugin('vast', VastPlugin);
}
export default vast;
