import { useCallback, useEffect, useState } from 'react';
import Player from 'video.js/dist/types/player';

export const usePlayerIsPlaying = (player: Player | null) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePause = useCallback(() => {
    if (!player) {
      return;
    }

    const isAdPlaying = player.el().className.indexOf('vjs-ad-playing') > -1;
    if (isAdPlaying) {
      return;
    }

    const isSeeking = player.seeking();
    if (isSeeking) {
      return;
    }

    setIsPlaying(false);
  }, [player]);

  const handlePlay = useCallback(() => {
    setIsPlaying(true);
  }, []);

  useEffect(() => {
    if (!player) {
      return;
    }

    player.on('pause', handlePause);
    player.on('play', handlePlay);

    return () => {
      player.off('pause', handlePause);
      player.off('play', handlePlay);
    };
  }, [handlePause, handlePlay, player]);

  return isPlaying;
};
