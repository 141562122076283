import videojs from 'video.js';

function lint(e: unknown) {
  return e;
}

const browser = videojs.browser;

if (browser.IS_ANDROID) {
  videojs.options.html5.nativeAudioTracks = false;
  videojs.options.html5.nativeTextTracks = false;
  try {
    videojs.options.vhs.overrideNative = true;
  } catch (e) {
    lint(e);
  }
}

if (typeof window != 'undefined') {
  if (browser.IS_IPAD && 'MediaSource' in window) {
    videojs.options.html5.nativeTextTracks = false;
  }
  if ('MediaSource' in window) {
    try {
      videojs.options.vhs.overrideNative = true;
    } catch (e) {
      lint(e);
    }
  }
}
