import { useEffect } from 'react';
import { checkIsScriptLoaded } from './check-is-script-loaded';

export const useLoadScript = (url: string) => {
  useEffect(() => {
    const isAlreadyLoaded = checkIsScriptLoaded(url);
    if (isAlreadyLoaded) {
      return;
    }

    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};
