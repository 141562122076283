// timeOffset = 10 | '10' (seconds) | '10%' (of duration)

export const parseTimeOffset = (timeOffset: string | number, duration: number) => {
  if (typeof timeOffset === 'number') {
    return Math.floor(timeOffset);
  }

  const isPercentTimeOffset = timeOffset[timeOffset.length - 1] === '%';
  if (isPercentTimeOffset) {
    return Math.floor(duration * parseInt(timeOffset) * 0.01);
  }

  return parseInt(timeOffset);
};
