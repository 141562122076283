export const DEFAULT_OPTIONS = {
  dvrMin: 300,
  zoomMenu: true,
  rate: 1,
  pipButton: true,
  ccButton: true,
  relatedMenu: true,
  settingsButton: true,
  fullscreenButton: true,
  automuteButton: true,
  filtersMenu: false,
  downloadButton: false,
  rateMenu: true,
  hdicon: true,
  shareMenu: true,
  zoomInfo: true,
  chapterMarkers: true,
  contextMenu: true,
  contextLink: true,
  timetooltip: false,
  captionsSettings: 'undefined',
  mousedisplay: true,
  related: {},
  logoposition: 'LT',
  logooffsetX: 10,
  logooffsetY: 10,
  logourl: '',
  url: '',
  title: '',
  description: '',
  embed: '',
  endAction: '',
  pubid: '',
  limit: 0,
  limitmessage: 'Watch full video on',
  resume: false,
  video_id: '',
  playlistID: 'undefined',
  playlistMaxH: 'undefined',
  playlistUI: true,
  playlistShow: true,
  playlistAutoHide: true,
  playlist: false,
  currentSlide: '',
  infoIcon: '',
  target: '_blank',
  buttonRewind: true,
  metatitle: '',
  metasubtitle: '',
  qualityMenu: false,
  captionsSize: 1.25,
  tooltips: true,
  singlePlay: false,
  rewindforward: 10,
  snapshot: false,
  snapshotType: 'jpg',
  snapshotWatermark: '',
  slideWidth: 160,
  slideHeight: 90,
  slideType: 'vertical',
  ghostThumb: false,
  minhd: 1080,
  liveReconnect: false,
  paused: false,
  controlbar: true,
  touchRewindForward: true,
  touchControls: true,
  iosFullscreen: 'native',
  androidLock: false,
  playsinline: true,
  chapters: false,
  log: false,
};
