export const sortByKey = (array: { [key: string]: any }[], keyToSortBy: string) => {
  return array.sort(function (elem1, elem2) {
    const value1 = elem1[keyToSortBy];
    const value2 = elem2[keyToSortBy];

    if (value1 < value2) {
      return -1;
    } else if (value2 < value1) {
      return 1;
    } else {
      return 0;
    }
  });
};
