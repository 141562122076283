import Player from 'video.js/dist/types/player';
import { parseTimeOffset } from './parse-time-offset';

const AD_TIME_SLIP_MARGIN = 5; // sec

export const checkIsTimeToDisplayAd = (timeOffset: string | number, player: Player | null) => {
  if (!player) {
    return false;
  }

  const isAdPlaying = player.el().className.indexOf('vjs-ad-playing') > -1;
  if (isAdPlaying) {
    return false;
  }

  const duration = player.duration() ?? 0;
  const currentTime = player.currentTime() ?? 0;
  const adTimeOffset = parseTimeOffset(timeOffset, duration);

  const adTimePassedLongAgo = currentTime - adTimeOffset > AD_TIME_SLIP_MARGIN;
  if (adTimePassedLongAgo) {
    return false;
  }

  if (currentTime > adTimeOffset) {
    return true;
  }

  return false;
};
