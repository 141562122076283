import { useCallback, useMemo, useState } from 'react';

import {
  EpisodeType,
  SeasonType,
  SeriesPreparedDataType,
  VoiceoverType,
} from '@src/types/prepared-data';
import { Dropdown } from '../ui/Dropdown/Dropdown';

type Props = {
  data: SeriesPreparedDataType;
  onVoiceoverChange: (
    newVoiceover: Pick<VoiceoverOption, 'playlist'>,
    shouldRestartVast?: boolean,
  ) => void;
};

type SeasonOption = {
  value: string;
  text: string;
  episodes: EpisodeType[];
};
type EpisodeOption = {
  value: string;
  text: string;
  voiceovers: VoiceoverType[];
};
export type VoiceoverOption = {
  value: string;
  text: string;
  playlist: string;
};

export const SeriesDropdowns = ({ data, onVoiceoverChange }: Props) => {
  const [season, setSeason] = useState<Pick<SeasonOption, 'episodes'>>(data.seasons[0]);
  const [episode, setEpisode] = useState<Pick<EpisodeOption, 'voiceovers'>>(
    data.seasons[0].episodes[0],
  );

  const seasonsOptions = useMemo(
    () =>
      data.seasons.map((season: SeasonType) => ({
        value: season.id,
        text: season.name,
        episodes: season.episodes,
      })),
    [data.seasons],
  );

  const episodesOptions = useMemo(
    () =>
      season.episodes.map((episode: EpisodeType) => ({
        value: episode.id,
        text: episode.name,
        voiceovers: episode.voiceovers,
      })),
    [season],
  );

  const voiceoverOptions = useMemo(
    () =>
      episode.voiceovers.map((voiceover: VoiceoverType) => ({
        value: voiceover.id,
        text: voiceover.name,
        playlist: voiceover.playlist,
      })),
    [episode],
  );

  const handleSeasonChange = useCallback(
    (newSeason: SeasonOption) => {
      setSeason(newSeason);
      onVoiceoverChange(newSeason.episodes[0].voiceovers[0], true);
    },
    [onVoiceoverChange],
  );

  const handleEpisodeChange = useCallback(
    (newEpisode: EpisodeOption) => {
      setEpisode(newEpisode);
      onVoiceoverChange(newEpisode.voiceovers[0], true);
    },
    [onVoiceoverChange],
  );

  return (
    <>
      <Dropdown<SeasonOption>
        options={seasonsOptions}
        placeholder={seasonsOptions[0].text}
        onSelect={handleSeasonChange}
        disabled={seasonsOptions.length === 0}
        className="flex-grow flex-shrink basis-auto min-w-[90px] max-w-max pointer-events-auto"
      />
      <Dropdown<EpisodeOption>
        options={episodesOptions}
        placeholder={episodesOptions[0].text}
        onSelect={handleEpisodeChange}
        disabled={episodesOptions.length === 0}
        className="flex-grow flex-shrink basis-auto min-w-[90px] max-w-max pointer-events-auto"
      />
      <Dropdown<VoiceoverOption>
        options={voiceoverOptions}
        placeholder={voiceoverOptions[0].text}
        onSelect={onVoiceoverChange}
        disabled={voiceoverOptions.length === 0}
        className="flex-grow flex-shrink basis-auto min-w-[90px] max-w-max pointer-events-auto"
      />
    </>
  );
};
